import gql from "graphql-tag";

export default {
  createCoupon: gql`
    mutation CreateCoupon($input: createCouponInput!) {
      createCoupon(input: $input) {
        coupon {
          id
        }
      }
    }
  `,

  updateCoupon: gql`
    mutation UpdateCoupon($input: updateCouponInput!) {
      updateCoupon(input: $input) {
        coupon {
          id
        }
      }
    }
  `,

  applyCoupon: gql`
    mutation ApplyCoupon($input: applyCouponOrderInput!) {
      applyCouponOrder(input: $input){
        order {
          id
        }
      }
    }
  `,

  removeCoupon: gql`
    mutation RemoveCoupon($id: ID!) {
      removeCouponOrder(input: {id: $id}) {
        order {
          id
        }
      }
    }
  `,
}