<template>
  <v-row
    class="fill-height ma-0"
    align="center"
    justify="center"
  >
    <v-skeleton-loader
      tile
      type="image"
      class="mx-auto"
      width="100%"
      height="100%"
    />
  </v-row>
</template>

<script>
export default {
  name: "ImageLoading",
};
</script>