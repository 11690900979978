<template>
  <div>
    <data-table-header
      :title="$t('admin.customerDashboard')"
      :subtitle="$t('admin.totalCustomers')+': '+totalRecords"
      :search-placeholder="$t('customerDashboard.search')"
      :search-label="$t('userDashboard.searchEmailName')"
      :date-label="$t('common.dateFilter')"
      :has-search="true"
      @startDate="($event) => (startDate = $event)"
      @endDate="($event) => (endDate = $event)"
      @searchString="($event) => (searchString = $event)"
    />
    <users-data-table
      :start-date="startDate"
      :end-date="endDate"
      :filter-last-order-date="true"
      :search-string="searchString"
      :header-overrides="headerOverrides"
      :has-action="true"
      :has-orders="true"
      :show-select="true"
      :expandable="false"
      :navigate-to-customer="true"
      @updateTotalRecords="(event) => totalRecords = event"
    />
  </div>
</template>

<script>
import DataTableHeader from "@/components/tables/DataTableHeader.vue";
import UsersDataTable from "@/components/tables/UsersDataTable.vue";

export default {
  name: "CustomerDashboard",
  components: {
    DataTableHeader,
    UsersDataTable,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
      totalRecords: 0,
    };
  },
  computed: {
    headerOverrides() {
      return [
        {
          text: this.$t("userDashboard.userId"),
          value: "userId",
          class: "column-header",
        },
        {
          text: this.$t("userDashboard.name"),
          value: "name",
          class: "column-header",
        },
        {
          text: this.$t("profile.phone"),
          value: "phoneNumber",
          class: "column-header",
        },
        {
          text: this.$t("userDashboard.email"),
          value: "email",
          class: "column-header",
        },
        {
          text: this.$t("userDashboard.lastOrder"),
          value: "lastOrderDate",
          class: "column-header",
        },
        {
          text: this.$t("userDashboard.orders"),
          value: "completedOrderCount",
          class: "column-header",
        },
        {
          text: this.$t("userDashboard.region"),
          value: "signupCountry",
          class: "column-header"
        }
      ];
    },
  },
};
</script>
