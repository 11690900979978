<template>
  <ekitabu-select
    id="school-select"
    v-model="currentValue"
    :items="schools ?? []"
    item-text="name"
    item-value="_id"
    :label="$t('checkout.delivery.school')"
    :placeholder="$t('checkout.delivery.selectSchool')"
    class="mt-3"
    rules="required"
    required
    :disabled="disabled"
  />
</template>

<script>
import EkitabuSelect from "@/components/EkitabuSelect";
import gql from "graphql-tag";

export default {
  name: "SchoolSelect",
  components: {
    EkitabuSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  watch: {
    value(newValue) {
      this.currentValue = newValue;
    },
    currentValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  apollo: {
    schools: {
      query: gql`
        query {
          schools {
            id
            _id
            name
          }
        }
      `,
    },
  },
}
</script>