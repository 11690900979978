<template>
  <div>
    <div :class="containerClasses">
      <p>{{ $t("checkout.freeOrderText") }}</p>
    </div>
    <ApolloMutation
      class="d-flex flex-grow-1"
      :mutation="(gql) => placeFreeOrderMutation"
      :variables="{
        orderId: orderId,
      }"
      :refetch-queries="
        () => [
          {
            query: getBookshelfQuery,
          },
        ]
      "
      @done="onDone"
      @error="() => { loading = false; }"
    >
      <template #default="{ mutate }">
        <PrimaryButton
          id="submit"
          v-bind="$attrs"
          class="text-uppercase"
          block
          :disabled="loading"
          :loading="loading"
          @click="() => {
            loading = true;
            mutate()
          }"
        >
          {{ $t("checkout.placeFreeOrder") }}
          <v-icon
            class="ml-2 has-glow"
            x-small
          >
            $triangleRight
          </v-icon>
        </PrimaryButton>
      </template>
    </ApolloMutation>
  </div>
</template>

<script>
import OrderQueries from "@/graphql/OrderQueries";
import BookQueries from "@/graphql/BookQueries";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import BookshelfMixin from "@/mixins/BookshelfMixin";

export default {
  name: "NoCostOrder",
  components: {
    PrimaryButton,
  },
  mixins: [BookshelfMixin],
  props: {
    containerClasses: {
      type: String,
      default: "mt-3",
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    placeFreeOrderMutation() {
      return OrderQueries.placeFreeOrder;
    },
    orderId() {
      return this.$store.state.cart.currentCart;
    },
    getBookshelfQuery() {
      return BookQueries.getUserBookshelf;
    },
  },
  methods: {
    async onDone(data) {
      await this.refetchBookshelf();
      this.loading = false;
      let id = data.data.placeFreeOrder.order.id;
      this.$store.commit("setCurrentCart", null);
      if (id) {
        this.$router.push({
          name: "confirmed",
          params: {
            id: id,
          },
        });
      }
    },
  },
};
</script>
