import { DEFAULT_PAGE_SIZE } from "@/utils/Const";

export default {
  data() {
    return {
      page: 1,
      hasMore: true,
      loadingItemsCount: 12,
      defaultPageSize: DEFAULT_PAGE_SIZE,
    }
  },
  methods: {
    async loadMore(query) {
      this.page++
      await query.fetchMore({
        variables: {
          page: this.page,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || fetchMoreResult.books.collection.length < this.defaultPageSize) {
            this.hasMore = false
            return prev
          }
          return {
            books: {
              __typename: prev.books.__typename,
              collection: [...prev.books.collection, ...fetchMoreResult.books.collection],
            },
          }
        }
      })
    },
    showLoadingItems(isLoading) {
      return isLoading ? this.loadingItemsCount : 0;
    },
  },
};