import gql from "graphql-tag";
import BookFragments from '@/graphql/BookFragments';

const BOOK_DETAILS_FRAGMENT = BookFragments.bookDetailsFragment;

export default {
  getUserBookshelf: gql`
    query getUserBookshelf {
      books(myBookshelf: true) {
        collection {
          ...BookDetails
          readUrl
        }
      }
    }
    ${BOOK_DETAILS_FRAGMENT}
  `,

  getBook: gql`
    query getBook($id: ID!) {
      book(id: $id) {
        ...BookDetails
      }
    }
    ${BOOK_DETAILS_FRAGMENT}
  `,
};