<template>
  <ApolloMutation
    :mutation="(gql) => removeCouponMutation"
    :variables="{
      id: orderId,
    }"
    :refetch-queries="() => [{
      query: getOrderQuery,
      variables: { 
        orderId,
      },
    }]"
    @done="onDone"
  >
    <template #default="{mutate, loading}">
      <v-btn
        :loading="loading"
        :disabled="loading"
        text
        color="primary"
        :tooltip="$t('checkout.removeCoupon')"
        class="pa-0"
        @click="mutate"
      >
        {{ $t('common.remove') }}
      </v-btn>
    </template>
  </ApolloMutation>
</template>

<script>
import CouponMutations from "@/graphql/CouponMutations";
import OrderQueries from "@/graphql/OrderQueries";

export default {
  name: "RemoveCoupon",
  components: {
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  computed: {
    removeCouponMutation() {
      return CouponMutations.removeCoupon;
    },
    getOrderQuery() {
      return OrderQueries.getOrder;
    },
  },
  methods: {
    onDone() {
      this.$emit("snackbarMessage", this.$t('checkout.couponRemoved'));
    },
  },
};
</script>