<template>
  <PrimaryButton
    v-bind="$attrs"
    color1="tertiary"
    color2="tertiaryLight"
    elevation="0"
    :disabled="!isAvailable"
    @click="openBook()"
  >
    {{
      isAudio ? audioText :
      isAvailable ? $t("bookInfo.openBook") :
      $t("bookInfo.notAvailable")
    }}
    <v-icon
      v-if="isAudio"
      size="15"
      class="ml-2 has-glow"
    >
      $headphones
    </v-icon>
  </PrimaryButton>
</template>

<script>
import { FORMATS } from "@/utils/Const";
import i18n from '@/i18n';
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import { openAudiobookApp } from "@/helpers/audiobookHelper";

export default {
  name: "OpenBookButton",
  components: {
    PrimaryButton,
  },
  props: {
    readUrl: {
      type: String,
      required: false,
      default: null,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    audioText: {
      type: String,
      required: false,
      default: i18n.t("bookInfo.listenShort"),
    }
  },
  computed: {
    isAudio() {
      return this.format == FORMATS.audio;
    },
    isAvailable() {
      return !!this.readUrl || this.isAudio;
    },
  },
  methods: {
    async openBook() {
      if (this.isAudio) {
        openAudiobookApp();
      } else {
        window.open(this.readUrl);
      }
    },
  },
};
</script>
