const defaultCurrency = "KES";

import OrderQueries from "@/graphql/OrderQueries";
import gql from "graphql-tag";
import { ROUND_TO_INT_CURRENCIES } from "@/utils/Const";

export default {
  data() {
    return {
      loading: false,
    };
  },
  apollo: {
    currencies: {
      query: gql`
        query {
          currencies {
            edges {
              node {
                id
                _id
              }
            }
          }
        }
      `,
      update: (data) => data.currencies.edges.map((edge) => edge.node._id),
    },
  },
  methods: {
    shouldRoundToInt(currencyString) {
      return ROUND_TO_INT_CURRENCIES.includes(currencyString);
    },
    getPriceString(currencyString, price, shouldRound) {
      const shouldRoundToInt = this.shouldRoundToInt(currencyString);
      const precision = shouldRoundToInt ? 0 : 2;

      if (shouldRound && shouldRoundToInt) {
        price = price.toFixed(precision);
      }
      else {
        price = price.toFixed(precision);
      }
      
      const formatter = new Intl.NumberFormat(undefined, {
        style: "currency",
        currency: currencyString,
        maximumFractionDigits: precision,
      }); 

      return formatter.format(price);
    },
    async getPrice(baseCurrencyCode, pricePerUnit, taxRate, shouldRound, quantity) {
      let selectedCurrencyCode = this.getCurrencyCode();
      const shouldRoundToInt = this.shouldRoundToInt(selectedCurrencyCode);
      const precision = shouldRoundToInt ? 0 : 2;

      const conversionRate = await this.getConversionRate(baseCurrencyCode, selectedCurrencyCode);

      if (shouldRound) {
        pricePerUnit = Number(pricePerUnit.toFixed(precision));
      }
      else {
        pricePerUnit = Number(pricePerUnit);
      }

      let taxPerUnit = 0;

      if (taxRate != null) {
        taxPerUnit = pricePerUnit * taxRate

        const basePrecision = this.shouldRoundToInt(baseCurrencyCode) ? 0 : 2;
        if (shouldRound) {
          taxPerUnit = Number(taxPerUnit.toFixed(basePrecision));
        }
      }

      let quantityPrice = pricePerUnit * quantity;
      let quantityTax = taxPerUnit * quantity;

      quantityPrice = quantityPrice * conversionRate;
      quantityTax = quantityTax * conversionRate;

      if (shouldRound) {
        quantityPrice = Number(quantityPrice.toFixed(precision));
        quantityTax = Number(quantityTax.toFixed(precision));
      }

      const price = quantityPrice + quantityTax;

      return price;
    },
    getCurrencyCodes() {
      return this.currencies;
    },
    getDefaultCurrencyCode() {
      return defaultCurrency;
    },
    getCurrencyCode() {
      if (
        this.$store.state &&
        this.$store.state.currency.currencyCode &&
        typeof this.$store.state.currency.currencyCode == "string" &&
        this.$store.state.currency.currencyCode != ""
      ) {
        return this.$store.state.currency.currencyCode;
      }
      return this.getDefaultCurrencyCode();
    },
    async getConversionRate(fromCurrency, toCurrency) {
      let rate = 1;

      if (fromCurrency.toLowerCase() != toCurrency.toLowerCase()) {
        this.loading = true;
        let response = await this.$apollo
        .query({
          query: OrderQueries.convertPrice,
          variables: {
            fromCurrency,
            toCurrency,
          },
        });
        this.loading = false;

        rate = response.data.currencyConversionRate.rate;
      }

      return rate;
    }
  },
};
