<template>
  <div>
    <v-btn
      tile
      depressed
      color="secondary"
      v-bind="$attrs"
      @click="downloadUserData()"
    >
      {{ label }}
    </v-btn>
  </div>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: "DownloadUserDataButton",
  props: {
    userData: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: i18n.t('admin.downloadPage'),
    },
    page: {
      type: Number,
      default: null,
    },
  },
  computed: {
    fileName() {
      const date = new Date().toLocaleDateString().replaceAll('/', '_')
      if (this.page === null) {
        return "user_data_"+date+".csv";
      }
      return "user_data_page"+this.page+"_"+date+".csv";
    },
  },
  methods: {
    downloadUserData() {
      // create a row for each transaction
      const rows = [];

      //create a field_name for each field in the transaction
      const rowHeaders = [
        this.$t('userDashboard.userId'),
        this.$t('userDashboard.email'),
        this.$t('userDashboard.emailVerified'),
        this.$t('userDashboard.createdAt'),
        this.$t('userDashboard.lastLogin'),
        this.$t('userDashboard.name'),
        this.$t('userDashboard.familyName'),
        this.$t('userDashboard.givenName'),
        this.$t('userDashboard.nickname'),
        this.$t('userDashboard.phoneNumber'),
        this.$t('userDashboard.roles'),
        this.$t('userDashboard.totalOrders'),
      ];
      rows.push(rowHeaders);

      // Then set the desired columns in that row as comma seperated values
      // Then push that row into the rows array
      var dateOptions = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};

      this.userData.forEach(item => {
        let createdDateString = new Date(item.createdAt);
        createdDateString = createdDateString.toLocaleDateString('en-us', dateOptions);
        createdDateString = createdDateString.replaceAll(',', '');

        let lastLoginDateString = new Date(item.lastLogin);
        lastLoginDateString = lastLoginDateString.toLocaleDateString('en-us', dateOptions);
        lastLoginDateString = lastLoginDateString.replaceAll(',', '');

        const userRoles = item.roles.map(role => role).join('+');

        const row = [
          item.userId,
          item.email,
          item.emailVerified,
          createdDateString,
          lastLoginDateString,
          item.name,
          item.familyName,
          item.givenName,
          item.nickname,
          item.phoneNumber,
          userRoles,
          item.completedOrderCount,
        ];
        rows.push(row);
      });

      // Then join the rows array into a string with each row seperated by a new line
      const csvContent = "data:text/csv;charset=utf-8,"+rows.join("\n");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", this.fileName);
      link.click();
    },
  }
}
</script>