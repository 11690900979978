<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.5 5C0.5 2.51472 2.51472 0.5 5 0.5H16C18.4853 0.5 20.5 2.51472 20.5 5V16C20.5 18.4853 18.4853 20.5 16 20.5H5C2.51472 20.5 0.5 18.4853 0.5 16V5Z"
      fill="white"
      stroke="url(#paint0_linear_5296_12341)"
    />
    <path
      d="M13.5428 7.1263L11 9.38658V0.5H16.75V9.38658L14.2072 7.1263L13.875 6.83102L13.5428 7.1263Z"
      fill="white"
      stroke="url(#paint1_linear_5296_12341)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5296_12341"
        x1="0"
        y1="10.5"
        x2="21"
        y2="10.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7E2024" />
        <stop
          offset="0.9999"
          stop-color="#D2363C"
        />
        <stop
          offset="1"
          stop-color="#E43A41"
        />
        <stop
          offset="1"
          stop-color="#E43A41"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5296_12341"
        x1="10.5"
        y1="5.25"
        x2="17.25"
        y2="5.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7E2024" />
        <stop
          offset="0.9999"
          stop-color="#D2363C"
        />
        <stop
          offset="1"
          stop-color="#E43A41"
        />
        <stop
          offset="1"
          stop-color="#E43A41"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "BookListIcon",
  props: {
    size: {
      type: Number,
      default: 21,
    },
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>
