import i18n from '@/i18n';
import { supportedCountries } from "@/utils/Const";

export function getCountryText(countryCode) {
  let supportedCountry = supportedCountries.find((country) => country.alpha2 === countryCode);
  
  if (!supportedCountry) {
    supportedCountry = supportedCountries.find((country) => country.alpha3 === countryCode);
  }

  return supportedCountry ? i18n.t('countryCodes.' + supportedCountry.alpha3) : countryCode;
}