<template>
  <div :class="selected ? 'elevation-6 border-container selected' : 'elevation-0 border-container'">
    <div
      :class="selected ? selectedClasses : itemClasses"
      @click="$emit('input', radioValue)"
    >
      <v-radio
        class="d-sr-only"
        :label="label"
        :value="radioValue"
      />
      <div class="text-subtitle-2 fill-width">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentMethod",
  props: {
    value: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    radioValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    itemClasses() {
      return "d-flex flex align-center justify-center rounded-md payment-method overflow-hidden";
    },
    selectedClasses() {
      return this.itemClasses + " selected";
    },
  },
};
</script>

<style scoped lang="scss">
.border-container {
  height: 62px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.border-container::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: $quaternary-gradient;
  border-radius: 8px;
  z-index: -1;
}

.border-container.selected::before {
  background: $primary-gradient;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
}

.payment-method {
  background-color: var(--v-lightAccent-base);
  height: 100%;
  width: 100%;
  transition: all 0.3s;
}

.payment-method.selected {
  background-color: var(--v-background-base);
}
</style>
