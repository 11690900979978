import gql from "graphql-tag";

export default {
  couponDetailsFragment: gql`
    fragment CouponDetails on Coupon {
      id
      _id
      code
      active
      description
      discount
      isPercentage
      bookId
      bookTag
      publisherId
      startDate
      endDate
      maxNumberOfUses
      currentNumberOfUses
      maxNumberOfItems
      maxUsesPerUser
      forUserId
      fixedPrice
      taxInclusive
      forBookIds
      baseCurrency {
        id
        _id
      }
      event {
        id
        _id
      }
    }
  `,
};