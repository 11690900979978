<template>
  <DataTableHeaderContainer>
    <v-row no-gutters>
      <v-col class="col-auto">
        <h1 class="customer-header-title mb-5">
          {{ title ?? getByIdWebServiceUser?.name }}
        </h1>
        <div
          v-if="!getByIdWebServiceUser"
          class="d-flex"
          style="gap: 100px"
        >
          <v-skeleton-loader
            type="text@4"
            width="200px"
            height="120px"
          />
          <v-skeleton-loader
            type="text@4"
            width="200px"
            height="120px"
          />
        </div>
        <div
          v-else
          class="d-flex"
          style="gap: 100px"
        >
          <div>
            <div class="info-item">
              <span>{{ $t("userDashboard.name") }}: </span>
              {{ getByIdWebServiceUser?.name }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.phone") }}: </span>
              {{
                getByIdWebServiceUser?.phoneNumber != ""
                  ? getByIdWebServiceUser?.phoneNumber
                  : $t("common.none")
              }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.email") }}: </span>
              {{ getByIdWebServiceUser?.email }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.region") }}: </span>
              {{ getByIdWebServiceUser?.signupCountry }}
            </div>
          </div>
          <div>
            <div class="info-item">
              <span>{{ $t("userDashboard.createdAt") }}: </span>
              {{ formatDate(getByIdWebServiceUser?.createdAt) }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.lastLogin") }}: </span>
              {{ formatDate(getByIdWebServiceUser?.lastLogin) }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.totalOrders") }}: </span>
              {{ getByIdWebServiceUser?.completedOrderCount }}
            </div>
            <div class="info-item">
              <span>{{ $t("userDashboard.deliveryAddress") }}: </span>
              {{
                getByIdWebServiceUser?.address != ""
                  ? getByIdWebServiceUser?.address
                  : $t("common.none")
              }}
            </div>
          </div>
        </div>
      </v-col>
      <v-spacer />
      <v-col class="col-12 col-sm-auto mt-auto">
        <div
          v-if="hasDateFilter"
          class="mb-5"
        >
          <h4 class="data-table-filter-header mb-1">
            {{ $t("csvHeaders.orderDate") }}:
          </h4>
          <v-row
            class="d-flex"
            no-gutters
          >
            <ekitabu-text-input
              id="start-date"
              v-model="startDate"
              label="dd/mm/yyyy"
              class="d-flex flex-column-reverse"
              type="date"
            />
            <span class="mx-3 mt-3">{{ $t("common.to") }}</span>
            <ekitabu-text-input
              id="end-date"
              v-model="endDate"
              label="dd/mm/yyyy"
              class="d-flex flex-column-reverse"
              type="date"
            />
          </v-row>
        </div>
        <v-btn
          class="lightAccent no-print"
          width="270"
          tile
          depressed
          block
          :to="toPrevious"
        >
          {{ $t("common.backToPrevious") }}
        </v-btn>
      </v-col>
    </v-row>
  </DataTableHeaderContainer>
</template>

<script>
import gql from "graphql-tag";
import EkitabuTextInput from "@/components/EkitabuTextInput.vue";
import { formatDate } from "@/helpers/generalFunctions";
import DataTableHeaderContainer from "@/components/tables/DataTableHeaderContainer.vue";

export default {
  name: "OrderDetails",
  components: {
    EkitabuTextInput,
    DataTableHeaderContainer,
  },
  props: {
    hasDateFilter: {
      type: Boolean,
      default: true,
    },
    userId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    totalOrders: {
      type: Number,
      default: 0,
    },
    toPrevious: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
      toggle: this.toggleValue,
    };
  },
  apollo: {
    getByIdWebServiceUser: {
      query: gql`
        query webServiceUser($id: ID!) {
          getByIdWebServiceUser(userId: $id) {
            userId
            name
            email
            phoneNumber
            createdAt
            lastLogin
            completedOrderCount
            signupCountry
            address
          }
        }
      `,
      variables() {
        return {
          id: this.userId,
        };
      },
    },
  },
  watch: {
    startDate() {
      this.$emit("startDate", this.startDate);
    },
    endDate() {
      this.$emit("endDate", this.endDate);
    },
  },
  methods: {
    formatDate,
  },
};
</script>