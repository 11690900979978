import gql from "graphql-tag";

export default {
  addressDetailsFragment: gql`
    fragment AddressDetails on Address {
      id
      line1
      line2
      postalCode
      city
      country
      label
      isDefault
      placesId
    }
  `,
};