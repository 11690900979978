<template>
  <v-container>
    <ApolloQuery
      :ref="queryRef"
      :query="(gql) => ordersQuery"
      :variables="{
        eventId: eventId,
        customerId: userId,
        confirmed: true,
        startDate: filterDates.startDate,
        endDate: filterDates.endDate,
        orderBy: orderBy,
        itemsPerPage: itemsPerPage,
        nextCursor: nextCursor,
        prevCursor: prevCursor,
      }"
      :update="(data) => updateData(data, 'orders')"
    >
      <template #default="{ result: { error, data: orders }, isLoading }">
        <apollo-error-alert v-if="error" />
        <v-data-table
          v-model="selectedRows"
          :items="orders ?? []"
          item-key="id"
          :headers="customHeaders"
          class="data-table-container mb-6"
          disable-filtering
          :loading="!!isLoading"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          :server-items-length="totalCount"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            'items-per-page-options': defaultPaginationOptions,
            'items-per-page-text': $t('transactionReports.itemsPerPage'),
          }"
          :loading-text="$t('transactionReports.loading')"
          :no-data-text="$t('transactionReports.noData')"
          @update:sort-by="updateSort('by', $event)"
          @update:sort-desc="updateSort('desc', $event)"
          @update:page="setCursor(pageData, $event)"
        >
          <template #item.id="{ item }">
            <router-link
              :to="{ name: 'orderOverview', params: orderRouteParams(item) }"
            >
              <span>{{ item._id }}</span>
            </router-link>
          </template>
          <template #item.orderFulfillment.phoneNumber="{ item }">
            <span>{{
              item?.orderFulfillment?.phoneNumber == (null || "")
                ? $t("common.none")
                : item?.orderFulfillment?.phoneNumber
            }}</span>
          </template>
          <template #item.orderFulfillment.altPhoneNumber="{ item }">
            <span>{{
              item?.orderFulfillment?.altPhoneNumber ?? $t("common.none")
            }}</span>
          </template>
          <template #item.customerEmail="{ item }">
            <span>{{ item.customerEmail ?? item.customerId ?? null }}</span>
          </template>
          <template #item.updatedAt="{ item }">
            <span>{{ formatDate(item.updatedAt) }}</span>
          </template>
          <template #item.quantity="{ item }">
            <router-link
              :to="{ name: 'orderOverview', params: orderRouteParams(item) }"
            >
              <span>{{ getQuantity(item.orderLines) }}</span>
            </router-link>
          </template>
          <template #item.totalPriceSet="{ item }">
            {{ item.currency._id + " " + item.totalPriceSet }}
          </template>
          <template #item.provider="{ item }">
            <span>
              {{
                $t(
                  "paymentMethods." +
                    (getProviderDetails(item).provider ?? "none")
                )
              }}
            </span>
          </template>
        </v-data-table>
        <v-row>
          <v-spacer />
          <v-col class="col-12 col-sm-auto">
            <v-btn
              tile
              color="secondary"
              depressed
              :loading="!!isLoading"
              :disabled="!!isLoading"
              @click="refetchQuery(queryRef)"
            >
              {{ $t("transactionReports.refreshTransactions") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import { getProviderDetails } from "@/utils/PaymentProviderHelper";
import gql from "graphql-tag";
import OrderFragments from "@/graphql/OrderFragments";
import ServerPaginatedTableMixin from "@/mixins/ServerPaginatedTableMixin";
import { FROM_ROUTES } from "@/utils/Const";

const ORDER_ADMIN_DETAILS_FRAGMENT = OrderFragments.orderAdminDetailsFragment;

export default {
  name: "OrdersTable",
  components: {
    ApolloErrorAlert,
  },
  mixins: [ServerPaginatedTableMixin],
  props: {
    userId: {
      type: String,
      default: null,
    },
    eventId: {
      type: String,
      default: null,
    },
    fromPage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      queryRef: "customerOrdersReport",
      customHeaders: [
        {
          text: this.$t("transactionReports.orderId"),
          value: "id",
          class: "column-header",
          sortable: false,
        },
        {
          text: this.$t("transactionReports.date"),
          value: "updatedAt",
          class: "column-header",
        },
        {
          text: this.$t("transactionReports.quantity"),
          value: "quantity",
          class: "column-header",
          sortable: false,
        },
        {
          text: this.$t("transactionReports.totalPrice"),
          value: "totalPriceSet",
          class: "column-header",
        },
        {
          text: this.$t("transactionReports.paymentMethod"),
          value: "provider",
          class: "column-header",
          sortable: false,
        },
        { value: "data-table-expand" },
      ],
      orderBy: {
        updatedAt: "DESC",
      },
    };
  },
  computed: {
    ordersQuery() {
      return gql`
        query getCustomerOrders(
          $eventId: String
          $customerId: String
          $confirmed: Boolean
          $startDate: String
          $endDate: String
          $orderBy: OrderFilter_order
          $itemsPerPage: Int
          $nextCursor: String
          $prevCursor: String
        ) {
          orders(
            event_id: $eventId
            customerId: $customerId
            confirmed: $confirmed
            updatedAt: { after: $startDate, before: $endDate }
            order: [$orderBy]
            first: $itemsPerPage
            after: $nextCursor
            before: $prevCursor
          ) {
            totalCount
            pageInfo {
              endCursor
              startCursor
              hasNextPage
              hasPreviousPage
            }
            edges {
              cursor
              node {
                ...OrderAdminDetails
              }
            }
          }
        }
        ${ORDER_ADMIN_DETAILS_FRAGMENT}
      `;
    },
  },
  methods: {
    orderRouteParams(item) {
      const params = {};
      params.customerId = item.customerId;
      if (this.fromPage === FROM_ROUTES.customer) {
        params.customerName = this.$route.params.customerName
      }
      else if (this.fromPage === FROM_ROUTES.event) {
        params.eventId = this.$route.params.id;
        params.eventName = this.$route.params.eventName;
      }
      return { orderId: item._id, ...params };
    },
    getProviderDetails(item) {
      return getProviderDetails(item);
    },
    getQuantity(orderLines) {
      let quantity = 0;
      if (orderLines.edges?.length > 0) {
        quantity = orderLines.edges.reduce((acc, edge) => {
          return acc + edge.node.quantity;
        }, 0);
      }
      return quantity;
    },
  },
};
</script>
