<template>
  <svg
    class="custom-fill-icon"
    :height="size"
    :width="size"
    viewBox="239.5919 241.3451 20.8162 17.3098"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 257.061 250.878 C 256.877 253.305 256.693 255.732 256.51 258.159 C 256.481 258.169 256.136 258.284 255.874 258.072 C 255.623 257.869 255.552 257.455 255.747 257.107 C 255.93 255.206 256.114 253.305 256.298 251.405 C 256.3 251.379 256.331 251.074 256.595 250.922 C 256.81 250.798 257.025 250.866 257.061 250.878 Z"
      fill="white"
      transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
    />
    <g transform="matrix(1, 0, 0, 1, 232.9981384277344, 236.3451080322266)">
      <path
        d="M9.26679 14.8402C9.27241 15.4049 9.33486 15.8011 9.39398 16.2C9.5809 17.4589 9.82007 19.3 10.0299 21.7703C9.88585 21.9068 9.63044 22.1099 9.26679 22.2089C9.26679 22.2089 8.55553 22.4023 7.82532 21.9897C6.63009 21.3139 6.61177 19.2347 6.59574 17.4281C6.58533 16.2551 6.58908 14.9697 7.31638 14.5773C7.57948 14.4354 7.86528 14.4489 8.07947 14.4896C8.05554 14.0034 8.06137 13.3162 8.20666 12.5159C8.90086 8.69278 11.9855 6.65878 12.3614 6.4191C12.973 6.02952 15.1214 4.70597 18.0422 5.05936C20.9939 5.41663 22.7978 7.25208 23.3839 7.86648C24.1135 8.63141 25.4132 10.25 25.8851 12.6473C26.0604 13.5382 26.0756 14.3029 26.0548 14.8402C26.1926 14.8221 26.4192 14.8148 26.6482 14.9279C26.7562 14.9813 27.0676 15.1615 27.2841 15.8489C27.3699 16.1211 27.4952 16.6462 27.3266 17.9103C27.1609 19.1522 27.2111 19.3075 27.1405 20.0776C27.0903 20.6252 27.0972 20.717 26.9875 21.0682C26.7346 21.8773 25.8812 22.1949 25.8429 22.2085C24.9547 22.5238 24.1941 22.008 24.1048 21.9453C24.3167 19.7815 24.5288 17.6178 24.7407 15.454C24.7931 14.6296 24.8266 12.3962 23.4522 10.1944C23.0467 9.54493 21.2614 6.88404 17.9575 6.4626C15.3547 6.13074 13.4178 7.39378 12.9126 7.73447C12.6037 7.94271 11.2549 8.88488 10.2843 10.7169C9.91811 11.4077 9.24723 12.9025 9.26679 14.8402Z"
        fill="white"
      />
    </g>
    <path
      d="M 242.858 250.835 C 243.098 253.276 243.338 255.718 243.578 258.159 C 243.92 258.177 244.228 257.979 244.341 257.677 C 244.473 257.326 244.277 257.007 244.257 256.975 C 244.129 255.074 244.002 253.174 243.875 251.273 C 243.855 251.224 243.745 250.959 243.451 250.834 C 243.162 250.711 242.906 250.814 242.858 250.834 L 242.858 250.835 Z"
      fill="white"
      transform="matrix(1, 0, 0, 1, 0, 3.552713678800501e-15)"
    />
  </svg>
</template>

<script>
export default {
  name: "HeadPhonesIcon",
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
}
</script>
