<template>
  <div
    class="footer-links-container"
  >
    <div
      v-for="link in links"
      :key="link.name"
    >
      <a
        v-if="link?.url"
        class="help-link mx-2 ml-md-0"
        :href="link.url"
        target="_blank"
      >
        {{ $t('footer.'+link.name) }}
      </a>
      <router-link
        v-else
        text
        rounded
        class="help-link mx-2 ml-md-0"
        :to="{ name: link.name }"
      >
        {{ $t('footer.'+link.name) }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLinks",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  computed: {
    supportUrl() {
      return SUPPORT_URL;
    },
  },
};
</script>

<style scoped>
.footer-links-container {
  display: flex;  
}

.help-link:hover {
  text-decoration: 2px underline;
  text-decoration-color: var(--v-primary-base);
}

@media screen and (max-width: 959px) {
  .footer-links-container {
    align-items: center;
    justify-content: center;
  }
}
</style>