<template>
  <div>
    <data-table-header
      :title="$t('admin.userDashboard')"
      :search-placeholder="$t('userDashboard.searchUsers')"
      :search-label="$t('userDashboard.searchEmailName')"
      :date-label="$t('userDashboard.signupDate')"
      :has-search="true"
      @startDate="$event => startDate = $event"
      @endDate="$event => endDate = $event"
      @searchString="$event => searchString = $event"
    />
    <users-data-table
      :show-select="true"
      :start-date="startDate"
      :end-date="endDate"
      :search-string="searchString"
    />
  </div>
</template>

<script>
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import UsersDataTable from '@/components/tables/UsersDataTable.vue';

export default {
  name: "UserDashboard",
  components: {
    DataTableHeader,
    UsersDataTable,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
    };
  },
}
</script>