<template>
  <v-container>
    <ApolloQuery
      :ref="queryRef"
      :query="(gql) => eventsQuery"
      :variables="{
        searchString: searchString,
        startDate: filterDates.startDate,
        endDate: filterDates.endDate,
        orderBy: orderBy,
        itemsPerPage: itemsPerPage,
        nextCursor: nextCursor,
        prevCursor: prevCursor
      }"
      :update="(data) => updateData(data, 'events')"
    >
      <template #default="{ result: { error, data: events }, isLoading }">
        <apollo-error-alert v-if="error" />
        <v-data-table
          v-model="selectedRows"
          :items="events ?? []"
          item-key="id"
          :headers="customHeaders"
          class="data-table-container mb-6"
          disable-filtering
          :loading="!!isLoading"
          :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
          :server-items-length="totalCount"
          :items-per-page.sync="itemsPerPage"
          :footer-props="{
            'items-per-page-options': defaultPaginationOptions,
            'items-per-page-text': $t('transactionReports.itemsPerPage'),
          }"
          :loading-text="$t('transactionReports.loading')"
          :no-data-text="$t('transactionReports.noData')"
          @update:sort-by="updateSort('by', $event)"
          @update:sort-desc="updateSort('desc', $event)"
          @update:page="setCursor(pageData, $event)"
        >
          <template #item.id="{ item }">
            <router-link
              :to="{ name: 'eventOverview', params: { id: item._id, eventName: item?.name ?? null } }"
            >
              <span>{{ item._id }}</span>
            </router-link>
          </template>
          <template #item.startDate="{ item }">
            <span>{{ formatDate(item.startDate) }}</span>
          </template>
          <template #item.endDate="{ item }">
            <span>{{ formatDate(item.endDate) }}</span>
          </template>
          <template #item.taxRate.id="{ item }">
            <span>{{ $t('countryCodes.'+item.taxRate._id) }}</span>
          </template>
        </v-data-table>
        <v-row>
          <v-spacer />
          <v-col class="col-12 col-sm-auto">
            <v-btn
              tile
              color="secondary"
              depressed
              :loading="!!isLoading"
              :disabled="!!isLoading"
              @click="refetchQuery(queryRef)"
            >
              {{ $t("transactionReports.refreshTransactions") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import ServerPaginatedTableMixin from "@/mixins/ServerPaginatedTableMixin";
import gql from "graphql-tag";

export default {
  name: "EventsTable",
  components: {
    ApolloErrorAlert,
  },
  mixins: [
    ServerPaginatedTableMixin,
  ],
  props: {
    searchString: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      queryRef: "eventsReport",
      singleSelect: false,
      customHeaders: [
        {
          text: this.$t("event.id"),
          value: "id",
          class: "column-header",
          sortable: false,
        },
        {
          text: this.$t("event.name"),
          value: "name",
          class: "column-header",
        },
        {
          text: this.$t("event.startDate"),
          value: "startDate",
          class: "column-header",
        },
        {
          text: this.$t("event.endDate"),
          value: "endDate",
          class: "column-header",
        },
        {
          text: this.$t("event.region"),
          value: "taxRate.id",
          class: "column-header",
        },
        {
          text: this.$t("event.totalOrders"),
          value: "orders.totalCount",
          class: "column-header",
          sortable: false,
        },
        { value: "data-table-expand" },
      ],
      orderBy: {
        createdAt: "DESC",
      },
    };
  },
  computed: {
    eventsQuery() {
      return gql`
        query getEvents(
          $itemsPerPage: Int
          $nextCursor: String
          $prevCursor: String,
          $startDate: String
          $endDate: String
          $orderBy: EventFilter_order,
          $searchString: String
        ) {
          events(
            first: $itemsPerPage,
            after: $nextCursor,
            before: $prevCursor,
            startDate: { after: $startDate, before: $endDate },
            order: [$orderBy],
            name: $searchString,
          ) {
            totalCount
            pageInfo {
              endCursor
              startCursor
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                _id
                name
                notes
                orders {
                  totalCount
                }
                taxRate {
                  id
                  _id
                  rate
                }
                startDate
                endDate
                coupons {
                  id
                  code
                }
                books
                createdAt
                updatedAt
              }
            }
          }
        }
      `;
    },
  },
};
</script>