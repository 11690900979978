<template>
  <v-dialog
    v-model="dialog"
    height="90%"
  >
    <v-card
      tile
      max-height="100%"
    >
      <v-card-title class="align-center">
        <div>{{ title }}</div>
        <v-spacer />
        <v-btn
          icon
          color="textOnPrimary"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 text-container">
        <ApolloQuery
          :query="(gql) => getCouponQuery"
          :variables="{
            id: couponId,
          }"
          :skip="!couponId"
          :update="updateData"
        >
          <template #default="{ result: { error }, isLoading }">
            <apollo-error-alert
              v-if="error"
              :message="error.message"
            />
            <v-skeleton-loader
              v-if="isLoading"
              type="list-item-two-line"
              class="mb-4"
            />
            <manage-coupon-form
              v-else
              ref="couponForm"
              v-model="snackbar"
              :existing-coupon="existingCoupon"
            />
          </template>
        </ApolloQuery>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ManageCouponForm from '@/components/coupons/ManageCouponForm';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import gql from 'graphql-tag';
import CouponFragments from '@/graphql/CouponFragments';

export default {
  name: "ManageCouponModal",
  components: { 
    ManageCouponForm,
    ApolloErrorAlert,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    couponId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      existingCoupon: null,
      snackbar: false,
    }
  },
  computed: {
    getCouponQuery() {
      return gql`
        query GetCoupon($id: ID!) {
          coupon(id: $id) {
            ...CouponDetails
          }
        }
        ${CouponFragments.couponDetailsFragment}
      `;
    },
    title() {
      return !this.couponId ? this.$t('couponManager.create') : this.$t('couponManager.edit');
    },
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (!val) {
        this.$refs.couponForm.resetForm();
        this.existingCoupon = null;
      }
      this.$emit('input', val);
    },
    snackbar(val) {
      if (val) {
        const message = this.existingCoupon ? this.$t('couponManager.updateSuccess') : this.$t('couponManager.createSuccess');
        this.$emit('showSnackbar', message);
        this.dialog = false;
        this.snackbar = false;
      }
    },
  },
  methods: {
    updateData(data) {
      this.existingCoupon = data.coupon;
      return data;
    },
  },
}
</script>

<style scoped>
:deep(.v-dialog.v-dialog--active) {
  border-radius: 0px;
  max-width: 600px;
  overflow: hidden;
}

.text-container {
  display: flex;
  flex-direction: column;
}
</style>