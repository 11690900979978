<template>
  <div>
    <div class="bottom-border mb-3 pb-1">
      <p class="new-list-header mb-0">
        {{ $t('bookList.newList') }}
      </p>
    </div>
    <form>
      <div class="d-flex align-center">
        <ekitabu-text-input
          id="list-name-Input"
          v-model="newListTitle"
          required
          :placeholder="$t('bookList.newListName')"
          class="mr-3"
        />
        <ApolloMutation
          :mutation="(gql) = createBookListMutation"
          :variables="{ input: { name: newListTitle }}"
          :update="updateCache"
        >
          <template #default="{ mutate, loading }">
            <v-btn
              :disabled="!validListTitle || loading"
              :loading="loading"
              tile
              elevation="0"
              color="primary"
              type="submit"
              @click="mutate"
            >
              {{ $t('bookList.newList') }}
            </v-btn>
          </template>
        </ApolloMutation>
      </div>
    </form>
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import BookListQueries from '@/graphql/BookListQueries';

export default {
  name: "CreateBooklistForm",
  components: {
    EkitabuTextInput,
  },
  data() {
    return {
      newListTitle: "",
    }
  },
  computed: {
    createBookListMutation() {
      return BookListQueries.createBookList
    },
    validListTitle() {
      return this.newListTitle?.length > 3;
    },
  },
  methods: {
    updateCache(cache, { data: { createBookList } }) {
      const data = cache.readQuery({ query: BookListQueries.getUserBookLists });

      cache.modify({
        fields: {
          getMyBookLists(existingBookLists) {
            return [...existingBookLists, createBookList.bookList];
          },
        },
      });

      this.newListTitle = "";
    },
  }
}
</script>

<style scoped>
.bottom-border {
  border-bottom: 1px solid var(--v-accent-base);
}
</style>