import BookQueries from '@/graphql/BookQueries';

export default {
  name: 'BookshelfMixin',
  data() {
    return {
      bookshelfQueryLoading: 0,
    };
  },
  apollo: {
    bookshelf: {
      query: BookQueries.getUserBookshelf,
      loadingKey: 'bookshelfQueryLoading',
      update(data) {
        return data?.books?.collection || [];
      },
      skip() {
        return !this.$auth.isAuthenticated || !this.$auth?.user?.email_verified || !this.getMe
      },
    },
  },
  computed: {
    getMe() {
      return this.$store.state.auth.getMe;
    },
    bookshelfLoading() {
      return Boolean(this.bookshelfQueryLoading);
    },
  },
  methods: {
    checkInBookshelf(id) {
      return this.bookshelf?.find((book) => book.id === id);
    },
    refetchBookshelf() {
      this.$apollo.queries.bookshelf.refetch()
    },
  },
}