<template>
  <div>
    <apollo-error-alert 
      v-if="error"
      class="mt-3"
      :error="error"
    />
    <v-container class="pa-0">
      <v-row class="ma-n1 ma-md-n2">
        <book-list-item
          v-for="book in data"
          :id="book.id"
          :key="book.id"
          :price="book.price"
          :thumbnail-url="book.thumbnailUrl"
          :title="book.title"
          :currency="book.currency"
          :format="book.format"
          :show-buy-now="showBuyNow"
          :elevation="$vuetify.breakpoint.xs ? '16' : '4'"
        />
        <v-col
          v-for="n in showLoadingItems(isLoading)"
          :key="n"
          class="col-6 col-sm-3 col-lg-2 pa-1 pa-md-2"
        >
          <div class="d-flex flex-column fill-height">
            <v-skeleton-loader
              type="image"
              height="260px"
              class="mb-2 flex-grow-1"
            />
            <v-skeleton-loader
              type="paragraph"
              class="mt-auto"
            />
          </div>
        </v-col> 
      </v-row>
    </v-container>
    <div
      v-if="!hasMore && data?.length > defaultPageSize && !isLoading"
      class="mx-auto"
    >
      <div class="mt-3 d-flex flex-column align-center">
        <h4>{{ $t('shop.endOfCategory') }}</h4>
        <h3 class="mt-3">
          {{ $t('shop.backToTop') }}
        </h3>
        <v-btn
          color="secondary"
          fab
          @click="goToTop"
        >
          <v-icon>mdi-arrow-up</v-icon>
        </v-btn>
      </div>
    </div>
    <div
      v-if="data && hasMore && data.length >= defaultPageSize && !isLoading"
      v-intersect.once="() => loadMore(query)"
    />
    <v-progress-circular
      v-if="(data && data.length > 0) && isLoading"
      indeterminate
      style="width: 100%;"
      color="primary"
    />
  </div>
</template>

<script>
import BookListItem from "@/components/bookList/BookListItem";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import loadBooksMixin from '@/mixins/loadBooksMixin';

export default {
  name: "BookDisplay",
  components: {
    BookListItem,
    ApolloErrorAlert,
  },
  mixins: [
    loadBooksMixin,
  ],
  props: {
    query: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: null,
    },
    loadingItems: {
      type: Number,
      default: 12,
    },
    paginationEnabled: {
      type: Boolean,
      default: true,
    },
    showOwned: {
      type: Boolean,
      default: true,
    },
    showBuyNow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      hasMore: this.paginationEnabled,
      loadingItemsCount: this.loadingItems,
    };
  },
  methods: {
    goToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
}
</script>