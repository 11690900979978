<template>
  <div v-if="orderFulfillment">
    <h4 class="mb-1">
      {{ $t('transactionReports.fulfillmentDetails') }}
    </h4>
    <table  
      class="admin-order-table"
    >
      <thead>
        <tr>
          <th>{{ $t('transactionReports.fulfilledAt') }}</th>
          <th>{{ $t('checkout.delivery.method') }}</th>
          <th>{{ $t('profile.phone') }}</th>
          <th>{{ $t('profile.alternativePhoneNumber') }}</th>
          <th>{{ $t('checkout.delivery.orderNotes') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ orderFulfillment?.fulfilledAt ? formatDate(orderFulfillment.fulfilledAt) : $t('checkout.orderComplete.deliveryPending') }}</td>
          <td>
            {{
              order?.shippingFeeSet ? $t('checkout.delivery.deliveryMethods.delivery') :
              orderFulfillment?.school ? $t('checkout.delivery.deliveryMethods.school') :
              $t('checkout.delivery.deliveryMethods.pickup')
            }}
          </td>
          <td>{{ orderFulfillment?.phoneNumber ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.altPhoneNumber ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.notes?.length > 0 ? formatNotes(orderFulfillment.notes) : $t('common.none') }}</td>
        </tr>
      </tbody>
      <thead v-if="order?.shippingFeeSet">
        <tr>
          <th>{{ $t('profile.address') }}</th>
          <th>{{ $t('profile.addressDetails') }}</th>
          <th>{{ $t('profile.postalCode') }}</th>
          <th colspan="2">
            {{ $t('checkout.delivery.shippingFee') }}
          </th>
        </tr>
      </thead>
      <tbody v-if="order?.shippingFeeSet">
        <tr>
          <td>{{ orderFulfillment?.addressLine1 ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.addressLine2 ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.addressPostalCode ?? $t('common.none') }}</td>
          <td colspan="2">
            <price
              :book-price="Number(order.shippingFeeSet)"
              :base-currency="order.currency._id"
            />
          </td>
        </tr>
      </tbody>
      <thead v-if="orderFulfillment?.school">
        <tr>
          <th>{{ $t('checkout.delivery.school') }}</th>
          <th>{{ $t('checkout.delivery.studentClass') }}</th>
          <th colspan="3">
            {{ $t('checkout.delivery.studentName') }}
          </th>
        </tr>
      </thead>
      <tbody v-if="orderFulfillment?.school">
        <tr>
          <td>{{ orderFulfillment.school?.name ?? $t('common.none') }}</td>
          <td>{{ orderFulfillment?.studentClass ?? $t('common.none') }}</td>
          <td colspan="3">
            {{ orderFulfillment?.studentName ?? $t('common.none') }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/generalFunctions.js';
import Price from '@/components/Price';

export default {
  name: "TransactionReportFulfillmentDetails",
  components: {
    Price,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    }
  },
  computed: {
    orderFulfillment() {
      return this.order?.orderFulfillment;
    }
  },
  methods: {
    formatDate,
    formatNotes(notes) {
      let formattedNotes = "";
      for (const note of notes) {
        formattedNotes += note + "\n";
      }
      return formattedNotes;
    }
  },
};
</script>