<template>
  <ApolloQuery
    :query="userInfoQuery"
    :update="(data) => data.getWebServiceUser"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <v-container
        class="profile-info-container"
      >
        <div class="profile-header-container d-flex justify-space-between align-center pb-3 mb-6">
          <h1 class="user-profile-header">
            {{ $t("profile.userInformation") }}
          </h1>
          <router-link
            v-if="!isLoading && $auth.user.email_verified"
            class="profile-edit-link"
            :to="{ name: 'editUserProfile' }"
          >
            {{ $t("profile.edit") }}
          </router-link>
        </div>
        <verification-prompt v-if="!$auth.user.email_verified" />
        <apollo-error-alert v-if="error" />
        <div
          v-if="isLoading"
        >
          <v-skeleton-loader
            v-for="n in 4"
            :key="n"
            type="paragraph"
            class="my-3"
          />
        </div>
        <div v-else-if="data">
          <div class="my-2">
            <div class="label">
              {{ $t("profile.name") }}
            </div>
            <div class="profile-detail">
              {{ data.givenName || data.familyName ? name(data.givenName, data.familyName) : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.email") }}
            </div>
            <div class="profile-detail">
              {{ data.email ? data.email : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.phone") }}
            </div>
            <div class="profile-detail">
              {{ data.phoneNumber ? data.phoneNumber : $t("profile.noData") }}
            </div>
            <div
              v-if="!data.phoneNumber"
              class="phone-prompt"
            >
              {{ $t('profile.primaryPhonePrompt') }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.alternativePhoneNumber") }}
            </div>
            <div class="profile-detail">
              {{ data.alternativePhoneNumber ? data.alternativePhoneNumber : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.address") }}
            </div>
            <div class="profile-detail">
              {{ getDefaultAddress?.line1 ? getDefaultAddress.line1 : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.addressDetails") }}
            </div>
            <div class="profile-detail">
              {{ getDefaultAddress?.line2 ? getDefaultAddress.line2 : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.postalCode") }}
            </div>
            <div class="profile-detail">
              {{ getDefaultAddress?.postalCode ? getDefaultAddress.postalCode : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.city") }}
            </div>
            <div class="profile-detail">
              {{ getDefaultAddress?.city ? getDefaultAddress.city : $t("profile.noData") }}
            </div>
          </div>
          <div class="my-2">
            <div class="label">
              {{ $t("profile.country") }}
            </div>
            <div class="profile-detail">
              {{ getDefaultAddress?.country ? getCountryText(getDefaultAddress.country) : $t("profile.noData") }}
            </div>
          </div>
        </div>
      </v-container>
    </template>
  </ApolloQuery>
</template>

<script>
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import VerificationPrompt from '@/components/VerificationPrompt';
import { getCountryText } from '@/utils/CountryCodeHelper';
import gql from 'graphql-tag';
import AddressFragments from '@/graphql/AddressFragments';

const ADDRESS_DETAILS_FRAGMENT = AddressFragments.addressDetailsFragment;

export default {
  components: {
    ApolloErrorAlert,
    VerificationPrompt,
  },
  apollo: {
    getDefaultAddress: {
      query: gql`
        query getDefaultAddress {
          getDefaultAddress {
            ...AddressDetails
          }
        }
        ${ADDRESS_DETAILS_FRAGMENT}
      `,
    },
  },
  computed: {
    userInfoQuery() {
      return gql`
        query getUserInfo {
          getWebServiceUser {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
            alternativePhoneNumber
          }
        }
      `;
    }
  },
  methods: {
    name(givenName, familyName) {
      return this.$t('common.fullName', {firstname: givenName, lastname: familyName})
    },
    getCountryText
  },
}
</script>

<style scoped>
  .profile-info-container {
    background-color: var(--v-lightestAccent-base);
  }

  .profile-header-container {
    border-bottom: 1px solid var(--v-lightAccent-base);
  }

  .user-profile-header {
    font-size: 1.25rem;
  }

  .label {
    font-size: 0.875rem;
    color: var(--v-secondaryText-base);
  }

  .profile-detail {
    font-size: 1rem;
    color: var(--v-primaryText-base);
    font-weight: normal;
  }

  .phone-prompt {
    font-size: 0.75rem;
    color: var(--v-error-base);
    max-width: 500px;
  }

  @media (max-width: 599px) {
    .user-profile-header {
      font-size: 1rem;
    }

    .profile-edit-link {
      font-size: 0.688rem;
    }

    .label {
      font-size: 0.688rem;
    }

    .profile-detail {
      font-size: 0.75rem;
    }
  }
</style>