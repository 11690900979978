import { render, staticRenderFns } from "./ReadMore.vue?vue&type=template&id=ae55547c&scoped=true&"
import script from "./ReadMore.vue?vue&type=script&lang=js&"
export * from "./ReadMore.vue?vue&type=script&lang=js&"
import style0 from "./ReadMore.vue?vue&type=style&index=0&id=ae55547c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-2f6a8101d6/2/home/apps/.yarn/berry/cache/vue-loader-npm-15.10.1-3a0b6c19d2-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae55547c",
  null
  
)

export default component.exports