<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "HideMobileNav",
  mounted() {
    // check route param for hideMobileNav
    const hideMobileNav = this.$route.params.hide;
    if (hideMobileNav != undefined) {
      const show = hideMobileNav == 'true' ? false : true;
      this.changeShowMobileNav(show);
    }
    // redirect to home
    this.$router.push({ name: 'home' });
  },
  methods: {
    ...mapActions('siteSettings', ['changeShowMobileNav']),
  },
};
</script>