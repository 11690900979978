<template>
  <svg
    
    :height="size"
    :width="size"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.37012 2.4796H6.47461V2.14511C6.47461 1.22292 7.24832 0.472656 8.19933 0.472656H10.269C11.22 0.472656 11.9937 1.22292 11.9937 2.14511V2.4796H15.0982C15.479 2.4796 15.7881 2.7793 15.7881 3.14858V4.15205C15.7881 4.52133 15.479 4.82104 15.0982 4.82104H3.37012C2.9893 4.82104 2.68023 4.52133 2.68023 4.15205V3.14858C2.68023 2.7793 2.9893 2.4796 3.37012 2.4796ZM10.6139 2.14511C10.6139 1.96081 10.4591 1.81062 10.269 1.81062H8.19933C8.00926 1.81062 7.85438 1.96081 7.85438 2.14511V2.4796H10.6139V2.14511Z"
      fill="url(#paint0_linear_5296_12734)"
    />
    <path
      d="M3.61859 5.70933C3.74933 5.56984 3.93491 5.49023 4.12911 5.49023H14.3394C14.5336 5.49023 14.7192 5.56984 14.85 5.70933C14.9807 5.84881 15.0445 6.03545 15.0262 6.2231L14.1784 14.8155C14.0821 15.7922 13.196 16.5288 12.117 16.5288H6.35158C5.27294 16.5288 4.38644 15.7922 4.2902 14.8155L3.44233 6.2231C3.4237 6.03545 3.48786 5.84881 3.61859 5.70933ZM10.8465 14.8546C10.8614 14.8553 10.8759 14.856 10.8907 14.856C11.2518 14.856 11.5554 14.5837 11.5785 14.2291L11.9924 7.87382C12.0162 7.50521 11.7275 7.18744 11.3474 7.16403C10.9662 7.14396 10.6392 7.42092 10.6154 7.78952L10.2015 14.1448C10.1777 14.5135 10.4664 14.8312 10.8465 14.8546ZM6.89004 14.2291C6.91315 14.5837 7.2167 14.856 7.57786 14.856C7.59234 14.856 7.60718 14.8556 7.62201 14.8546C8.00248 14.8312 8.29085 14.5135 8.26705 14.1448L7.85312 7.78952C7.82897 7.42058 7.503 7.14329 7.12115 7.16403C6.74068 7.18744 6.4523 7.50521 6.47611 7.87382L6.89004 14.2291Z"
      fill="url(#paint1_linear_5296_12734)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5296_12734"
        x1="17.6702"
        y1="2.64685"
        x2="1.40305"
        y2="2.64685"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#ED1C24"
        />
        <stop
          offset="1"
          stop-color="#E85C25"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5296_12734"
        x1="16.6935"
        y1="11.0095"
        x2="2.30986"
        y2="11.0095"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#ED1C24" />
        <stop
          offset="1"
          stop-color="#E85C25"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "TrashIcon",
  props: {
    size: {
      type: Number,
      default: 18,
    },
  },
}
</script>
