export default {
  namespaced: true,
  state: () =>  ({
    showCartDrawer: false,
  }),
  getters: {
    showCartDrawer: (state) => state.showCartDrawer,
  },
  mutations: {
    setShowCartDrawer: (state, newValue) => state.showCartDrawer = newValue,
  },
  actions: {
    changeShowCartDrawer({ commit }, newValue) {
      commit('setShowCartDrawer', newValue);
    }
  },
}