<template>
  <div>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <v-form v-model="valid">
        <div :class="containerClasses">
          <v-skeleton-loader
            v-if="$apollo.queries.getWebServiceUser.loading"
            class="mb-2"
            type="text@8"
          />
          <div v-else>
            <ekitabu-masked-phone-input
              id="phone-input"
              class="fill-width mb-3"
              :value="accountPhone"
              :label="$t('checkout.form.phoneNumber')"
              rules="required|max:9|min:9"
              placeholder="### ### ###"
              type="tel"
              required
              :dropdown-disabled="paymentProvider == paymentTypes.mpesa"
              @formattedPhoneChanged="setFormattedPhone"
            />
            <admin-order
              v-if="isAdminOrder"
              class="mb-3"
              :show-phone-input="false"
              @updateAdminOrderDetails="setAdminOrderDetails"
            />
            <delivery-options
              v-if="isPrintOrder"
              @updateShippingInfo="setShippingInfo"
              @updateSchoolInfo="setSchoolInfo"
              @updateIsPickupOrder="setIsPickupOrder"
              @updateIsValid="setDeliveryOptionsValid"
            />
            <v-checkbox
              v-if="formattedPhone != accountPhone && !invalid"
              v-model="saveAccountInfo"
              :label="$t('checkout.saveAccountInfo')"
            />
          </div>
        </div>
        <place-order-button
          :should-save-account-info="saveAccountInfo"
          :invalid="invalid || !deliveryOptionsValid"
          :prefixed-phone-number="formattedPhone"
          :shipping-info="shippingInfo"
          :school-info="schoolInfo"
          :is-pickup-order="isPickupOrder"
          :is-admin-order="isAdminOrder"
          :admin-order-details="adminOrderDetails"
          :on-completed="onCompleted"
          :place-order-query="paymentProvider == paymentTypes.mpesa ? mpesaPlaceOrder : mtnPlaceOrder"
        />
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import PlaceOrderButton from "@/views/checkout/PlaceMobilePaymentOrderButton";
import { required, max, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, setInteractionMode } from "vee-validate";
import i18n from "@/i18n";
import EkitabuMaskedPhoneInput from "@/components/EkitabuMaskedPhoneInput";
import DeliveryOptions from "@/views/checkout/print/DeliveryOptions";
import gql from "graphql-tag";
import AdminOrder from "@/views/checkout/AdminOrder";
import OrderQueries from "@/graphql/OrderQueries";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend("max", {
  ...max,
  message: i18n.t("errors.maxLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

extend("min", {
  ...min,
  message: i18n.t("errors.minLength", {
    field: "{_field_}",
    length: "{length}",
  }),
});

export default {
  name: "MobilePaymentForm",
  components: {
    PlaceOrderButton,
    ValidationObserver,
    EkitabuMaskedPhoneInput,
    DeliveryOptions,
    AdminOrder,
  },
  props: {
    paymentTypes: {
      type: Object,
      required: true,
    },
    paymentProvider: {
      type: String,
      required: true,
    },
    isPrintOrder: {
      type: Boolean,
      default: false,
    },
    isAdminOrder: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: "mt-3",
    },
  },
  data() {
    return {
      accountPhone: "",
      valid: false,
      saveAccountInfo: false,
      formattedPhone: "",
      shippingInfo: {
        altPhoneNumber: null,
        addressDetails: null,
        placeId: null,
        eventId: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
      isPickupOrder: false,
      deliveryOptionsValid: this.isPrintOrder ? false : true,
      adminOrderDetails: {
        forEmail: null,
        forName: null,
        phoneNumber: null,
        event: null,
      },
    };
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
            alternativePhoneNumber
          }
        }
      `,
      result({ data }) {
        this.accountPhone = data.getWebServiceUser?.phoneNumber;
      },
    },
  },
  computed: {
    mpesaPlaceOrder() {
      return OrderQueries.placeMpesaOrder
    },
    mtnPlaceOrder() {
      return OrderQueries.placeMtnOrder
    }
  },
  methods: {
    setFormattedPhone(value) {
      this.formattedPhone = value;
    },
    setShippingInfo(shippingInfo) {
      this.shippingInfo = shippingInfo;
    },
    setSchoolInfo(schoolInfo) {
      this.schoolInfo = schoolInfo;
    },
    setIsPickupOrder(isPickupOrder) {
      this.isPickupOrder = isPickupOrder;
    },
    setDeliveryOptionsValid(isValid) {
      this.deliveryOptionsValid = isValid;
    },
    setAdminOrderDetails(adminOrderDetails) {
      this.adminOrderDetails = adminOrderDetails;
    },
    onCompleted(result) {
      if (this.paymentProvider == this.paymentTypes.mpesa) {
        const id = result?.data?.placeOrder?.order?.id;
        const safaricomMpesaRequestId = result?.data?.placeOrder?.order?.newSafaricomMpesaRequest?.id;

        if (id) {
          this.$router.push({
            name: "orderPending",
            params: {
              id: id,
              safaricomMpesaRequestId: safaricomMpesaRequestId,
            },
          });
        }
      } else if (this.paymentProvider == this.paymentTypes.mtn) {
        const id = result?.data?.placeMtnOrder?.order?.id;
        const momoRequests = result?.data?.placeMtnOrder?.order?.momoMtnPayments;
        const momoMtnRequestId = momoRequests[momoRequests.length - 1]?.id;

        if (id) {
          this.$router.push({
            name: "orderPending",
            params: {
              id: id,
              momoMtnRequestId: momoMtnRequestId,
            },
          });
        }
      }
    }
  },
};
</script>
