export default {
  namespaced: true,
  state: () =>  ({
    errorCode: '',
    errorMessage: '',
  }),
  getters: {
    errorCode: (state) => state.errorCode,
    errorMessage: (state) => state.errorMessage,
  },
  mutations:{
    setErrorCode: (state, errorCode) => state.errorCode = errorCode,
    setErrorMessage: (state, errorMessage) => state.errorMessage = errorMessage,
  },
}