<template>
  <v-snackbar
    v-model="snackbar"
    tile
    multi-line
    color="primary"
    :timeout="10000"
    class="pa-15 font-weight-bold"
  >
    <v-container>
      <v-row
        class=""
        align-content="center"
        align-items="center"
      >
        <v-col
          cols="auto"
          class="align-self-center"
        >
          <v-icon x-large>
            mdi-alert-outline
          </v-icon>
        </v-col>
        <v-col>
          <v-container class="">
            <v-row v-if="errorLabel != ''">
              <v-col class="pa-0 text-uppercase">
                {{ errorLabel }}:
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pa-0">
                {{ $te('errors.'+errorMessage) ? $t('errors.'+errorMessage) : errorMessage }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <template #action="{ attrs }">
      <v-btn
        color="red"
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        <v-icon color="white">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
    errorLabel: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: this.value,
    };
  },
  watch: {
    value(val) {
      this.snackbar = val;
    },
    snackbar(val) {
      this.$emit("input", val);
    },
  },
};
</script>
