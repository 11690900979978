<template>
  <ApolloMutation
    :mutation="(gql) => removeItemQuery"
    :variables="{
      id: id,
    }"
    @done="onDone"
  >
    <template #default="{ mutate, loading }">
      <v-btn
        color="warning"
        icon
        small
        :loading="loading"
        :disabled="loading"
        @click="() => {
          removeLoading = true;
          mutate();
        }"
      >
        <v-icon
          size="18"
        >
          $trash
        </v-icon>
      </v-btn>
    </template>
  </ApolloMutation>
</template>

<script>
import OrderQueries from "@/graphql/OrderQueries"

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      orderId: this.$store.state.cart.currentCart,
      removeLoading: false,
    }
  },
  computed: {
    removeItemQuery() {
      return OrderQueries.deleteOrderLine;
    },
  },
  watch: {
    removeLoading() {
      this.$emit("updateLoading", this.removeLoading);
    }
  },
  methods: {
    onDone() {
      this.$emit("onRemoved", this.id);
    }
  },
};
</script>
<style scoped>
  a {
    font-weight: 500;
    text-decoration: underline;
  }

  @media screen and (max-width: 899px) {
    a {
      font-size: 0.75rem;
    }
  }
</style>
