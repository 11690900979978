<template>
  <div class="d-flex align-center">
    <h4 class="mr-2 body-2 font-weight-bold">
      {{ $t("checkout.quantity") }}:
    </h4>
    <v-btn
      :disabled="loading || currentQuantity <= 1"
      color="secondary"
      x-small
      depressed
      tile
      @click="currentQuantity -= stepCount"
    >
      <v-icon small>
        mdi-minus
      </v-icon>
    </v-btn>
    <v-chip
      class="rounded-0 mx-2"
      small
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        size="14"
        width="2"
        color="secondary"
      />
      <div v-else>
        {{ quantity }}
      </div>
    </v-chip>
    <v-btn
      :disabled="loading"
      color="primary"
      x-small
      depressed
      tile
      @click="currentQuantity += stepCount"
    >
      <v-icon small>
        mdi-plus
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import OrderQueries from "@/graphql/OrderQueries";

export default {
  name: "QuantityInput",
  props: {
    orderLineId: {
      type: String,
      required: true,
    },
    stepCount: {
      type: Number,
      default: 1,
    },
    quantity: {
      type: Number,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentQuantity: this.quantity,
      loading: false,
    };
  },
  watch: {
    currentQuantity() {
      this.updateQuantity();
    },
  },
  methods: {
    async updateQuantity() {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: OrderQueries.adjustQuantity,
        variables: {
          input: {
            id: this.orderLineId,
            newQuantity: this.currentQuantity,
          },
        },
      });
      this.loading = false;
    },
  },
};
</script>
