import { render, staticRenderFns } from "./TagSearchBar.vue?vue&type=template&id=7bdfe6c6&scoped=true&"
import script from "./TagSearchBar.vue?vue&type=script&lang=js&"
export * from "./TagSearchBar.vue?vue&type=script&lang=js&"
import style0 from "./TagSearchBar.vue?vue&type=style&index=0&id=7bdfe6c6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-2f6a8101d6/2/home/apps/.yarn/berry/cache/vue-loader-npm-15.10.1-3a0b6c19d2-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bdfe6c6",
  null
  
)

export default component.exports