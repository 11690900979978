<template>
  <div>
    <h4>{{ $t('checkout.delivery.deliveryMethods.pickupText') }}</h4>
  </div>
</template>

<script>
export default {
  name: "PickupFromOffice",
}
</script>