<template>
  <div>
    <h4
      v-if="showHeader"
      class="mt-6 mb-3"
    >
      {{ $t('checkout.delivery.shippingDetails') }}
    </h4>
    <ekitabu-masked-phone-input
      :id="'alternative-phone-input'"
      :value="formattedAlternativePhone"
      class="mb-3 fill-width"
      :label="$t('profile.alternativePhoneNumber')"
      rules="max:9|min:9"
      placeholder="### ### ###"
      type="tel"
      @formattedPhoneChanged="setAlternativeFormattedPhone"
    />
    <div class="mb-3">
      <ekitabu-text-input
        id="autocomplete"
        v-model="searchAddress"
        :label="$t('profile.address')"
        :placeholder="$t('profile.address')"
      />
      <h5>
        {{ $t('profile.searchAddressText') }}
      </h5>
    </div>
    <ekitabu-text-input
      id="address-info-input"
      v-model="line2"
      class="mb-3"
      :label="$t('profile.addressDetails')"
      :placeholder="$t('profile.addressDetails')"
    />
    <div class="mt-3">
      <h4>{{ $t('profile.addressName') }}</h4>
      <p>{{ addressComponents.label ?? $t('common.none') }}</p>
      <h4>{{ $t('profile.country') }}</h4>
      <p>
        {{ addressComponents.country ? getCountryText(addressComponents.country) : $t('common.none') }}
      </p>
      <h4>{{ $t('profile.address') }}</h4>
      <p>{{ addressComponents.line1 ?? $t('common.none') }}</p>
      <h4>{{ $t('profile.addressDetails') }}</h4>
      <p>{{ line2 ?? $t('common.none') }}</p>
      <h4>{{ $t('profile.city') }} </h4>
      <p>{{ addressComponents.city ?? $t('common.none') }}</p>
      <h4>{{ $t('profile.postalCode') }}</h4>
      <p>{{ addressComponents.postalCode ?? $t('common.none') }}</p>
    </div>
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import EkitabuMaskedPhoneInput from '@/components/EkitabuMaskedPhoneInput';
import gql from 'graphql-tag';
import { getCountryText } from '@/utils/CountryCodeHelper';
import AddressFragments from '@/graphql/AddressFragments';

const ADDRESS_DETAILS_FRAGMENT = AddressFragments.addressDetailsFragment;

const deliveryCountries = [
  "ke"
];

const nairobiCenter = { lat: -1.2920699904133635, lng: 36.821931500512214 };

const approximateBoundaryDeltas = {
  north: 0.15,
  south: 0.15,
  east: 0.3,
  west: 0.2,
};

// Create a bounding box with sides being their respective distance away from the center point
const approximateNairobiBounds = {
  north: nairobiCenter.lat + approximateBoundaryDeltas.north,
  south: nairobiCenter.lat - approximateBoundaryDeltas.south,
  east: nairobiCenter.lng + approximateBoundaryDeltas.east,
  west: nairobiCenter.lng - approximateBoundaryDeltas.west,
};

export default {
  name: "AddressFields",
  components: {
    EkitabuMaskedPhoneInput,
    EkitabuTextInput,
  },
  props: {
    fieldsRequired: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formattedAlternativePhone: "",
      searchAddress: "",
      placeId: null,
      addressComponents: {
        label: null,
        line1: null,
        subLocality: null,
        city: null,
        postalCode: null,
        country: null,
      },
      line2: null,
    };
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            phoneNumber
            alternativePhoneNumber
          }
        }
      `,
      result({ data }) {
        this.formattedAlternativePhone = data.getWebServiceUser?.alternativePhoneNumber;
      },
    },
    getDefaultAddress: {
      query: gql`
        query getDefaultAddress {
          getDefaultAddress {
            ...AddressDetails
          }
        }
        ${ADDRESS_DETAILS_FRAGMENT}
      `,
      result({ data }) {
        this.placeId = data.getDefaultAddress?.placesId;
        this.line2 = data.getDefaultAddress?.line2;
        this.addressComponents.line1 = data.getDefaultAddress?.line1;
        this.addressComponents.city = data.getDefaultAddress?.city;
        this.addressComponents.postalCode = data.getDefaultAddress?.postalCode == "" ? null : data.getDefaultAddress?.postalCode;
        this.addressComponents.country = data.getDefaultAddress?.country;
      },
    },
  },
  computed: {
    isChanged() {
      return this.placeId != this.getDefaultAddress?.placesId ||
        this.line2 != this.getDefaultAddress?.line2 ||
        this.formattedAlternativePhone != this.getWebServiceUser?.alternativePhoneNumber;
    },
    isValid() {
      return this.placeId && this.line2 && this.formattedAlternativePhone;
    },
    autocomplete() {
      const options = {
        componentRestrictions: { country: deliveryCountries },
        fields: ["address_components", "place_id", "name"],
        bounds: approximateNairobiBounds,
        strictBounds: true,
      }
      const input = document.getElementById('autocomplete');

      const autocomplete = new google.maps.places.Autocomplete(input, options);

      return autocomplete;
    },
  },
  watch: {
    placeId() {
      this.updateShippingDetails();
    },
    line2() {
      this.updateShippingDetails();
    },
    formattedAlternativePhone() {
      this.updateShippingDetails();
    },
    isChanged() {
      this.$emit('updateIsChanged', this.isChanged);
    },
    isValid() {
      this.$emit('updateIsValid', this.isValid);
    },
  },
  mounted() {
    this.autocomplete.addListener("place_changed", () => this.getAddressComponents());
  },
  methods: {
    setAlternativeFormattedPhone(formattedPhone) {
      this.formattedAlternativePhone = formattedPhone;
    },
    updateShippingDetails() {
      this.$emit('updateShippingDetails', {
        placeId: this.placeId,
        addressDetails: this.line2,
        altPhoneNumber: this.formattedAlternativePhone,
      });
    },
    getCountryText,
    getAddressComponents() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      let address1 = "";
      let postcode = "";
      this.placeId = place.place_id;
      let label = place?.name ?? null;

      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case "route": {
            address1 += component.short_name;
            break;
          }

          case "postal_code": {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case "postal_code_suffix": {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }

          case "locality": {
            this.addressComponents.city = component.long_name;
            break;
          }

          case "country": {
            this.addressComponents.country = component.long_name;
            break;
          }
        }
      }

      this.addressComponents.label = label;
      this.addressComponents.line1 = address1 != "" ? address1 : null;
      this.addressComponents.postalCode = postcode != "" ? postcode : null;
    }
  }
}
</script>