import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import createMultiTabState from 'vuex-multi-tab-state';
import serverError from './modules/serverError.js';
import auth from './modules/auth/auth.js';
import locale from './modules/locale.js';
import cart from './modules/cart.js';
import currency from './modules/currency.js';
import siteSettings from './modules/siteSettings.js';
import siteFunctionality from './modules/siteFunctionality.js';

Vue.use(Vuex);

window.localStorage.removeItem('vuex-multi-tab')

const store = new Vuex.Store({
  plugins: [
    createPersistedState(),
    createMultiTabState(),
  ],
  modules: {
    serverError,
    auth,
    locale,
    cart,
    currency,
    siteSettings,
    siteFunctionality,
  },
});

export default store;