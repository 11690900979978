import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import VerificationPrompt from '@/components/VerificationPrompt';
import gql from 'graphql-tag';
import OrderQueries from '@/graphql/OrderQueries';
import BookQueries from '@/graphql/BookQueries';

export default {
  components: {
    ApolloErrorAlert,
    VerificationPrompt,
  },
  data() {
    return {
      showOrderClaimedSnackbar: false,
      showOrderClaimedModal: false,
      claimedItems: [],
      claimedOrders: [],
      claimedItemsLoading: false,
      loadingItemsCount: 3,
    };
  },
  apollo: {
    getWebServiceUser: {
      query: gql`
        query getUserInfo {
          getWebServiceUser {
            id
            phoneNumber
          }
        }
      `,
      skip() {
        return !this.$auth.isAuthenticated || !this.$auth?.user?.email_verified || !this.getMe;
      },
      error(error) {
        console.error("Error in fetching user info", error);
      }
    },
  },
  computed: {
    claimOrderMutation() {
      return OrderQueries.claimPaidOrder;
    },
    userPhoneNumber() {
      return this.getWebServiceUser?.phoneNumber ?? null;
    },
    getMe() {
      return this.$store.state.auth.getMe;
    },
  },
  watch: {
    userPhoneNumber(val) {
      if (val) {
        this.claimOrder();
      }
    }
  },
  methods: {
    async getClaimedOrders() {
      this.claimedItemsLoading = true;
      const claimedResult = await this.$apollo.query({
        query: gql`
          query {
            getRecentlyClaimedOrders {
              id
              _id
              updatedAt
              orderFulfillment {
                id
                fulfilledAt
              }
              confirmed
              customerId
              adminOrder {
                id
                claimedAt
              }
              orderLines {
                edges {
                  node {
                    id
                    productId
                    name
                    format
                    quantity
                  }
                }
              }
            }
          }
        `,
      });

      const claimedOrders = claimedResult.data.getRecentlyClaimedOrders ?? [];
      let claimedItems = [];
      for (let order of claimedOrders) {
        for (let orderLine of order.orderLines?.edges) {
          claimedItems.push(orderLine.node);
        }
      }

      this.claimedItems = claimedItems;
      this.claimedOrders = claimedOrders;
      this.claimedItemsLoading = false;
    },
    async claimOrder() {
      if (!this.userPhoneNumber) {
        return;
      }

      const result = await this.$apollo.mutate({
        mutation: this.claimOrderMutation,
      });

      if (result.data?.claimPaidOrder?.order) {
        await this.getClaimedOrders();

        await this.$apollo.query({
          query: BookQueries.getUserBookshelf,
          fetchPolicy: "network-only",
        });
        this.updatePreviousOrderCache();
        this.showOrderClaimedSnackbar = true;
        this.showOrderClaimedModal = true;
      }
    },
    updatePreviousOrderCache() {
      const getMyOrdersQuery = gql`
        query {
          getMyOrders(confirmed: true) {
            id
            _id
            updatedAt
          }
        }
      `;

      let data = this.$apolloProvider.defaultClient.cache.readQuery({
        query: getMyOrdersQuery,
        variables: {confirmed: true},
      });

      if (!data?.getMyOrders) {
        return;
      }

      // filter out existing order from claimed orders
      const claimedOrders = this.claimedOrders.filter((claimedOrder) => {
        return !data.getMyOrders.some((order) => {
          return order.id === claimedOrder.id;
        });
      });

      const newOrders = [...data.getMyOrders, ...claimedOrders]

      this.$apolloProvider.defaultClient.cache.writeQuery({
        query: getMyOrdersQuery,
        variables: {confirmed: true},
        data: {
          ...data,
          getMyOrders: newOrders,
        },
      });
    }
  },
}