import gql from "graphql-tag";

export default {
  getTaxRate: gql`
    query getTaxRate($id: ID!) {
      taxRate(id: $id) {
        id
        _id
        rate
      }
    }
  `,
}