<template>
  <div>
    <div
      class="coupon-header-container mx-n6 px-6 px-md-12 mb-6"
    >
      <v-row class="align-center">
        <v-col class="col-12 col-sm-auto">
          <h1>{{ $t('admin.couponManager') }}</h1>
        </v-col>
        <v-spacer />
        <v-col class="col-12 col-sm-auto">
          <v-btn
            :small="$vuetify.breakpoint.xs"
            tile
            depressed
            color="primary"
            :block="$vuetify.breakpoint.xs"
            @click="openDialog()"
          >
            {{ $t("couponManager.create") }}
          </v-btn>
          <manage-coupon-modal
            v-model="dialog"
            :coupon-id="currentCouponId"
            @showSnackbar="showSnackbar"
          />
        </v-col>
      </v-row>
    </div>
    <v-container
      class="pa-0"
    >
      <div>
        <ApolloQuery
          :query="(gql) => getCouponsQuery"
          :update="(data) => data.coupons"
        >
          <template #default="{ result: { error, data }, isLoading }">
            <apollo-error-alert v-if="error" />
            <v-data-table
              :items="data ? data : []"
              item-key="id"
              :headers="customHeaders"
              class="data-table-container mb-6"
              disable-filtering
              :loading="!!isLoading"
              :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 100],
                'items-per-page-text': $t('transactionReports.itemsPerPage'),
              }"
              show-expand
              :loading-text="$t('couponManager.loading')"
              :no-data-text="$t('couponManager.noData')"
            >
              <template #item.active="{ item }">
                <v-checkbox
                  v-model="item.active"
                  off-icon="mdi-close"
                  on-icon="mdi-check"
                  disabled
                />
              </template>
              <template #item.startDate="{ item }">
                <span>{{ formatDate(item.startDate) }}</span>
              </template>
              <template #item.endDate="{ item }">
                <span>{{ formatDate(item.endDate) }}</span>
              </template>
              <template #item.actions="{ item }">
                <div class="d-flex">
                  <v-btn
                    icon
                    small
                    @click="openDialog(item)"
                  >
                    <v-icon small>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </div>
              </template>
              <template #expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <coupon-details :item="item" />
                </td>
              </template>
            </v-data-table>
          </template>
        </ApolloQuery>
      </div>
    </v-container>
    <success-snackbar
      v-model="snackbar"
      :message="snackbarMessage"
    />
  </div>
</template>

<script>
import ManageCouponModal from '@/components/coupons/ManageCouponModal';
import SuccessSnackbar from '@/components/SuccessSnackbar';
import CouponDetails from '@/components/CouponDetails';
import CouponFragments from '@/graphql/CouponFragments';
import gql from 'graphql-tag';

export default {
  name: "CouponManager",
  components: {
    ManageCouponModal,
    SuccessSnackbar,
    CouponDetails,
  },
  data() {
    return {
      coupon: true,
      dialog: false,
      snackbar: false,
      snackbarMessage: "",
      currentCouponId: null,
      customHeaders: [
        { text: this.$t('common.active'), value: "active" },
        { text: this.$t('couponManager.couponId'), sortable: false, value: "_id" },
        { text: this.$t('couponManager.couponCode'), value: "code" },
        { text: this.$t('couponManager.currentUses'), value: "currentNumberOfUses" },
        { text: this.$t('couponManager.startDate'), value: "startDate" },
        { text: this.$t('couponManager.endDate'), value: "endDate" },
        { text: null, value: 'actions', sortable: false },
        { value: 'data-table-expand' },
      ],
    };
  },
  computed: {
    getCouponsQuery() {
      return gql`
        query GetCoupons {
          coupons {
            ...CouponDetails
          }
        }
        ${CouponFragments.couponDetailsFragment}
      `;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.currentCouponId = null;
      }
    },
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
      return publicationDate.toLocaleDateString(undefined, options);
    },
    openDialog(item) {
      if (item) {
        this.currentCouponId = item.id;
      } else {
        this.currentCouponId = null;
      }
      this.dialog = true;
    },
    showSnackbar(message) {
      this.snackbarMessage = message;
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
  .coupon-header-container {
    background-color: var(--v-lightestAccent-base);
    margin-top: -110px;
    padding-top: 122px;
    padding-bottom: 24px;
  }

  .coupon-details-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
  }
</style>