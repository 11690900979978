<template>
  <v-expansion-panels
    v-bind="$attrs"
    class="ekitabu-expansion-panels rounded-lg px-0 elevation-4"
  >
    <v-expansion-panel class="rounded-lg pa-0">
      <v-expansion-panel-header
        class="primary-gradient text-caption rounded-lg border-sm font-weight-bold border-footerBackground"
        expand-icon="mdi-menu-down"
      >
        {{ title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "EkitabuExpansionPanel",
  props: {
    title: {
      type: String,
      default: "",
    },
  }
}
</script>