import gql from "graphql-tag";

export default {
  getUserBookLists: gql`
    query getUserBookLists {
      getMyBookLists {
        id
        _id
        name
        bookListItems {
          id
          productId
        }
      }
    }
  `,

  addItemToBookList: gql`
    mutation addItemToBookList($input: addItemToBookListInput!) {
      addItemToBookList(input: $input) {
        bookList {
          id
          _id
          name
          bookListItems {
            id
            productId
          }
        }
      }
    }
  `,

  deleteBookListItem: gql`
    mutation deleteBookListItem($id: ID!) {
      deleteBookListItem(input: {id: $id}) {
        bookListItem {
          id
        }
      }
    }
  `,

  deleteBookList: gql`
    mutation deleteBookList($id: ID!) {
      deleteBookList(input: {id: $id}) {
        bookList {
          id
        }
      }
    }
  `,

  setPrivacyBookList: gql`
    mutation setPrivacyBookList($input: setPrivacyBookListInput!) {
      setPrivacyBookList(input: $input) {
        bookList {
          id
          isPublic
        }
      }
    }
  `,

  createBookList: gql`
  mutation createBookList($input: createBookListInput!) {
    createBookList(input: $input) {
      bookList {
        id
        _id
        name
        isPublic
        notes
        forSchool {
          id
          _id
          name
        }
        bookListItems {
          id
          productId
          name
        }
        archivedAt
      }
    }
  }
  `,

  updateBookList: gql`
  mutation updateBookList($input: updateBookListInput!) {
    updateBookList(input: $input) {
      bookList {
        id
        _id
        name
        isPublic
        notes
        forSchool {
          id
          _id
          name
        }
        bookListItems {
          id
          productId
          name
        }
        archivedAt
      }
    }
  }
  `,
};