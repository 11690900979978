<template>
  <div>
    <v-row class="pt-3">
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.discount') }}:
          </h4>
          <span>{{ item.discount }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.isPercentage') }}:
          </h4>
          <span>{{ item.isPercentage }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.description') }}:
          </h4>
          <span>{{ item.description }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.maxUses') }}:
          </h4>
          <span>{{ item.maxNumberOfUses ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.maxUsesPerUser') }}:
          </h4>
          <span>{{ item.maxUsesPerUser ?? 1 }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.maxItems') }}:
          </h4>
          <span>{{ item.maxNumberOfItems ?? $t('common.none') }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row class="pb-3">
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.forPublisher') }}:
          </h4>
          <span>{{ item.publisherId ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.forBook') }}:
          </h4>
          <span>{{ item.bookId ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.forTag') }}:
          </h4>
          <span>{{ item.bookTag ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.forUser') }}:
          </h4>
          <span>{{ item.forUserId ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.currency') }}:
          </h4>
          <span>{{ item.baseCurrency._id }}</span>
        </div>
      </v-col> 
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'CouponDetails',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>