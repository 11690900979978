<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <template #activator="{ on }">
        <v-btn
          icon
          color="error"
          :loading="loading"
          v-on="on"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
      <v-card tile>
        <v-card-title class="align-center">
          <div>{{ title }}</div>
        </v-card-title>
        <v-card-text class="pa-5">
          <h2>{{ confirmationMessage }}</h2>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="mr-2"
            color="primary"
            tile
            elevation="0"
            @click="() => {
              onConfirm();
              dialog = false
            }"
          >
            {{ $t('button.delete') }}
          </v-btn>
          <v-btn
            color="secondary"
            tile
            elevation="0"
            @click="dialog = false"
          >
            {{ $t('button.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DeleteConfirmationModal",
  props: {
    title: {
      type: String,
      required: true
    },
    confirmationMessage: {
      type: String,
      required: true
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
}
</script>
