import gql from "graphql-tag";

export default {
  bookDetailsFragment: gql`
    fragment BookDetails on Book {
      id
      _id
      title
      authors
      imageUrl
      thumbnailUrl
      price
      format
      description
      currency {
        id
        _id
      }
    }
  `,
};