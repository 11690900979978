<template>
  <div>
    <customer-header
      :title="$route.params.orderId"
      :user-id="userId"
      :has-date-filter="false"
      :to-previous="previousRoute"
    />
    <v-skeleton-loader
      v-if="!order"
      type="image"
      width="100%"
      height="500px"
      tile
    />
    <order-details
      v-else
      :order="order"
      :provider-details="getProviderDetails(order)"
    />
  </div>
</template>

<script>
import iris from "@/iris";
import gql from "graphql-tag";
import CustomerHeader from "@/components/CustomerHeader.vue";
import OrderDetails from "@/components/tables/OrderDetails.vue";
import { getProviderDetails } from "@/utils/PaymentProviderHelper";
import OrderFragments from "@/graphql/OrderFragments";
import { FROM_ROUTES } from "@/utils/Const";

const ORDER_ADMIN_DETAILS_FRAGMENT = OrderFragments.orderAdminDetailsFragment;

export default {
  name: "OrderOverview",
  components: {
    CustomerHeader,
    OrderDetails,
  },
  apollo: {
    order: {
      query: gql`
        query getOrder($orderId: ID!) {
          order(id: $orderId) {
            ...OrderAdminDetails
          }
        }
        ${ORDER_ADMIN_DETAILS_FRAGMENT}
      `,
      variables() {
        return {
          orderId: this.orderId,
        };
      },
    },
  },
  computed: {
    orderId() {
      return iris.order + this.$route.params.orderId;
    },
    userId() {
      return this.$route.params.customerId;
    },
    eventId() {
      return this.$route.params.eventId;
    },
    customerName() {
      return this.$route.params.customerName;
    },
    eventName() {
      return this.$route.params.eventName;
    },
    previousRoute() {
      const route = {};
      if (this.eventId) {
        route.name = FROM_ROUTES.event;
        route.params = { id: this.eventId, eventName: this.eventName };
      } else {
        route.name = FROM_ROUTES.customer;
        route.params = { id: this.userId, customerName: this.customerName };
      }
      return route;
    },
  },
  methods: {
    getProviderDetails,
  },
};
</script>
