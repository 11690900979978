<template>
  <div>
    <v-row
      class="admin-header-container pb-3"
      align="center"
      justify="space-between"
      no-gutters
    >
      <h3>{{ $t("admin.adminInfo") }}</h3>
      <router-link
        v-if="$auth.isAuthenticated"
        class="admin-header-link"
        :to="{ name: 'userProfile' }"
      >
        {{ $t("admin.viewDetails") }}
      </router-link>
    </v-row>
    <div class="my-2">
      <div class="label">
        {{ $t("profile.name") }}
      </div>
      <div class="profile-detail">
        {{ name() }}
      </div>
    </div>
    <div class="my-2">
      <div class="label">
        {{ $t("profile.email") }}
      </div>
      <div class="profile-detail">
        {{ username() }}
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: "AdminInfo",
  methods: {
    name() {
      // TODO: Replace with graphql property when auth0 profile integration is complete
      //  Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/35
      return this.$t("common.fullName", {
        firstname: this.$auth.user.given_name,
        lastname: this.$auth.user.family_name,
      });
    },
    username() {
      return this.$auth.user.email;
    },
  },
}
</script>

<style scoped>
.label {
  font-size: 0.875rem;
  color: var(--v-secondaryText-base);
}

@media (max-width: 599px) {
  .label {
    font-size: 0.75rem;
  }

  .profile-detail {
    font-size: 0.75rem;
  }
}
</style>