<template>
  <div class="d-flex">
    <v-checkbox
      :id="id"
      v-model="currentValue"
      v-bind="$attrs"
      class="pa-0 ma-0 checkbox-input"
      hide-details
    />
    <label
      v-if="label"
      class="input-label"
      :for="id"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  name: "EkitabuCheckbox",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    }
  },
  watch: {
    currentValue(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },
  },
};
</script>

<style scoped>
  :deep(.theme--light.v-icon) {
    color: var(--v-secondaryText-base);
  }
</style>