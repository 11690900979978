<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t('eventManager.events') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <v-list
      class="event-list"
    >
      <v-list-item-group
        v-if="events?.length > 0"
        v-model="selectedEventId"
      >
        <v-list-item
          v-for="event in events"
          :key="event.id"
          :value="event.id"
        >
          <v-list-item-title>
            {{ event.name }}
          </v-list-item-title>
          <ApolloMutation
            :mutation="archiveEventMutation"
            :variables="{
              input: {
                id: event.id,
              },
            }"
            :update="updateCache"
          >
            <template #default="{ mutate, loading }">
              <DeleteConfirmationModal
                :title="$t('eventManager.deleteEvent', { event: event.name })"
                :confirmation-message="$t('eventManager.deleteEventConfirmation')"
                :on-confirm="() => {
                  selectedEventId = null;
                  selected = null;
                  mutate()
                }"
                :loading="loading"
              />
            </template>
          </ApolloMutation>
        </v-list-item>
      </v-list-item-group>
      <v-list-item
        v-else
      >
        <v-list-item-title>{{ $t('eventManager.noEvents') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal.vue";

export default {
  name: "EventList",
  components: {
    DeleteConfirmationModal,
  },
  data() {
    return {
      selected: null,
      selectedEventId: null,
    };
  },
  apollo: {
    events: {
      query: gql`
        query Events {
          events {
            edges {
              node {
                id
                _id
                name
              }
            }
          }
        }
      `,
      update: (result) => {
        return result?.events?.edges.map((edge) => edge.node);
      },
    },
  },
  computed: {
    archiveEventMutation() {
      return gql`
        mutation archiveEvent($input: archiveEventInput!) {
          archiveEvent(input: $input) {
            event {
              id
              name
              archivedAt
            }
          }
        }
      `;
    },
  },
  watch: {
    selectedEventId(val) {
      this.$emit("eventSelected", val);
    },
  },
  methods: {
    updateCache(cache, { data: { archiveEvent } }) {
      const data = cache.readQuery({ query: gql`
        query Events {
          events {
            edges {
              node {
                id
                _id
                name
              }
            }
          }
        }
      ` });

      cache.writeQuery({
        query: gql`
          query Events {
            events {
              edges {
                node {
                  id
                  _id
                  name
                }
              }
            }
          }
        `,
        data: {
          events: {
            edges: data.events.edges.filter((edge) => edge.node.id !== archiveEvent.event.id),
          },
        },
      });
    }
  }
};
</script>

<style scoped>
.event-list {
  max-height: 500px;
  overflow-y: auto;
}
</style>