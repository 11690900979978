<template>
  <div class="copy-link-container">
    <div :class="'url-container pl-3 pr-3 mx-0' + (disabled ? ' disabled-text' : '')">
      <p class="my-0">
        {{ url }}
      </p>
    </div>
    <v-tooltip top>
      <template #activator="{ on }">
        <v-btn
          color="secondary rounded-0"
          elevation="0"
          class="copy-button"
          :small="$vuetify.breakpoint.xs"
          :disabled="disabled"
          v-bind="$attrs"
          v-on="on"
          @click="() => {
            on;
            copyLink();
          }"
        >
          {{ $t('common.copy') }}
          <v-icon class="ml-2">
            {{ linkCopied ? 'mdi-check-circle-outline' : 'mdi-content-copy' }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('common.copyLink') }}</span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        linkCopied: false,
      };
    },
    methods: {
      copyLink() {
        navigator.clipboard.writeText(this.url);
        this.linkCopied = true;

        setTimeout(() => {
          this.linkCopied = false;
        }, 5000);
      },
    },
  }
</script>

<style scoped>
  .copy-link-container {
    display: flex;
    align-items: center;
  }

  .url-container {
    margin-right: 10px;
    width: 100px;
    outline: 1px solid var(--v-secondary-base);
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
  }

  .url-container.disabled-text {
    outline-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.12) !important;
  }

  .url-container p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .copy-button {
    border-radius: 0px;
  }
</style>