<template>
  <EkitabuExpansionPanel
    accordion
    :value="0"
    :title="$t('bookInfo.bookDetails')"
  >
    <table class="book-details-table">
      <tr v-if="book.title">
        <td>{{ $t("bookInfo.title") }}</td>
        <td>{{ decodeHtml(book.title) }}</td>
      </tr>
      <tr v-if="book.modified">
        <td>{{ $t("bookInfo.published") }}</td>
        <td>{{ publicationDate(book.modified) }}</td>
      </tr>
      <tr v-if="book.publisher">
        <td>{{ $t("bookInfo.publisher") }}</td>
        <td>{{ book.publisher }}</td>
      </tr>
      <tr v-if="book.language">
        <td>{{ $t("bookInfo.language") }}</td>
        <td>{{ book.language }}</td>
      </tr>
      <tr v-if="book.format">
        <td>{{ $t("bookInfo.format") }}</td>
        <td>{{ formattedAndTranslated }}</td>
      </tr>
      <tr v-if="book.isbn">
        <td>{{ $t("bookInfo.isbn") }}</td>
        <td>{{ book.isbn }}</td>
      </tr>
    </table>
  </EkitabuExpansionPanel>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import EkitabuExpansionPanel from "@/components/EkitabuExpansionPanel.vue";

export default {
  name: "BookDetails",
  components: {
    EkitabuExpansionPanel,
  },
  props: {
    book: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    formattedAndTranslated() {
      const formatText = this?.book?.format.replace("application/", "");
      const translationKey = `formats.${formatText}`;
      return this.$te(translationKey) ? this.$t(translationKey) : formatText;
    },
  },
  methods: {
    publicationDate(date) {
      let publicationDate = new Date(date);
      return publicationDate.getFullYear();
    },
    decodeHtml(html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style scoped lang="scss">
table {
  border: none;
  border-collapse: collapse;
}

.book-details-table tr td {
  padding: 7px 14px;
  font-size: 12px;
  line-height: normal;
}

.book-details-table tr:first-of-type td{ 
  padding-top: 14px;
}

.book-details-table tr:last-of-type td {
  padding-bottom: 14px;
}

.book-details-table tr td:first-child {
  color: var(--v-quaternaryText-base);
  font-weight: bold;
  padding-right: 30px;
  border-right: 2px solid var(--v-lightAccent-base);
}

.book-details-table tr td:last-of-type {
  word-break: break-word;
  padding-left: 30px;
}
</style>
