<template>
  <div
    class="banner-container textOnPrimary--text d-flex align-start justify-center"
  >
    <div
      v-if="loading"
      class="d-flex flex-column flex-md-row flex-wrap align-center justify-center pt-10 px-md-12 pb-10 pb-md-5"
      style="gap: 40px"
    >
      <div class="d-flex flex-grow-1 flex-column flex-md-row align-center">
        <v-skeleton-loader
          class="mx-13 mb-10 mb-md-0"
          type="image"
          height="347"
          width="248"
        />
        <div
          class="mt-0 mt-md-16 flex-column justify-space-around"
          style="min-width: 248px"
        >
          <v-skeleton-loader
            type="heading"
            class="mb-6"
          />
          <v-skeleton-loader
            type="paragraph@3"
            width="100%"
          />
        </div>
      </div>
      <div class="d-flex flex-column align-center text-center">
        <div class="d-flex">
          <v-skeleton-loader
            v-for="index in 2"
            :key="index"
            class="ma-1"
            type="image"
            height="194"
            width="130"
          />
        </div>
        <div class="d-flex">
          <v-skeleton-loader
            v-for="index in 2"
            :key="index"
            class="ma-1"
            type="image"
            height="194"
            width="130"
          />
        </div>
      </div>
    </div>
    <div v-else-if="featuredBook">
      <div
        class="d-flex flex-column flex-md-row flex-wrap align-center justify-center pt-10 px-md-12 pb-10 pb-md-5"
        style="gap: 40px"
      >
        <div class="d-flex flex-column flex-md-row align-center">
          <div class="mb-10 mb-md-0">
            <h1
              class="text-h3 text-uppercase font-weight-black text-center pb-6"
            >
              {{ $t("newReleaseBanner.title") }}
            </h1>
            <v-img
              class="featured-cover mx-13"
              :src="featuredBook.imageUrl"
              contain
              @click="
                $router.push({ name: 'book', params: { id: featuredBook.id } })
              "
            >
              <template #placeholder>
                <ImageLoading />
              </template>
            </v-img>
          </div>
          <div
            class="mt-0 mt-md-16 px-8 px-md-0 d-flex flex-column flex-shrink-1 justify-space-around"
          >
            <h2 class="text-h5 text-md-h4 font-weight-bold">
              {{ decodeHtml(featuredBook.title) }}
            </h2>
            <div
              v-if="featuredBook.authors && featuredBook.authors.length > 0"
              class="mb-4"
            >
              <h3 class="text-h6 text-md-h5 font-weight-regular">
                <span>
                  {{ $t("bookInfo.by") }}
                </span>
                <span
                  v-for="(author, index) in featuredBook.authors"
                  :key="index"
                >
                  {{
                    $t("newReleaseBanner.authorName")
                  }}
                </span>
              </h3>
            </div>
            <p
              class="text-h6 font-weight-regular mb-9"
              style="max-width: 575px; line-height: normal"
            >
              {{ featuredBook.description }}
            </p>
            <AddToCartButton
              :id="featuredBook.id"
              button-classes="text-h5 rounded-xxl"
              :simplified-id="featuredBook._id"
              :height="$vuetify.breakpoint.smAndDown ? '56px' : '65px'"
              min-width="200px"
              :query-loading="false"
              show-cart
              color1="background"
              color2="background"
              text-color="primaryDark"
              icon-classes="text-h5 ml-2"
            />
          </div>
        </div>
        <div class="d-flex flex-column align-center text-center">
          <h5
            class="text-h5 mb-10 mb-md-2"
            style="text-wrap: nowrap"
          >
            {{ $t("newReleaseBanner.otherTitles", { name: "Empress Ciku" }) }}
          </h5>
          <div class="book-thumbnails pb-1">
            <div
              v-for="(book, index) in bookThumbnails.slice(0, 2)"
              :key="index"
              class="book-thumbnail-container"
              @click="navigateToSearch(book.link)"
            >
              <v-img
                :src="require(`@/assets/img/thumbnails/${book.thumbnail}.png`)"
                class="book-thumbnail"
                object-fit="cover"
              >
                <template #placeholder>
                  <ImageLoading />
                </template>
              </v-img>
            </div>
          </div>
          <div class="book-thumbnails">
            <div
              v-for="(book, index) in bookThumbnails.slice(2)"
              :key="index"
              class="book-thumbnail-container"
              @click="navigateToSearch(book.link)"
            >
              <v-img
                :src="require(`@/assets/img/thumbnails/${book.thumbnail}.png`)"
                class="book-thumbnail"
                object-fit="cover"
              >
                <template #placeholder>
                  <ImageLoading />
                </template>
              </v-img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookQueries from "@/graphql/BookQueries.js";
import { decodeHtml } from "@/helpers/generalFunctions";
import AddToCartButton from "@/views/checkout/AddToCartButton.vue";
import ImageLoading from "@/components/ImageLoading.vue";

export default {
  name: "NewReleaseBanner",
  components: {
    AddToCartButton,
    ImageLoading,
  },
  computed: {
    loading() {
      return this.$apollo.queries.featuredBook.loading;
    },
    featuredBookId() {
      return 6180;
    },
    relatedBookIds() {
      return [6110, 6042, 6041, 6109];
    },
    windowBaseUrl() {
      return window.location.origin;
    },
    bookThumbnails() {
      return [
        {
          link: "nairobi cocktail",
          thumbnail: "nairobi",
        },
        {
          link: "immigrant cocktail",
          thumbnail: "immigrant",
        },
        {
          link: "cocktail from the savannah",
          thumbnail: "savannah",
        },
        {
          link: "a cocktail of unlikely tales",
          thumbnail: "unlikely",
        },
      ];
    },
  },
  apollo: {
    featuredBook: {
      query: BookQueries.getBook,
      variables() {
        return {
          id: "/books/" + this.featuredBookId,
        };
      },
      update: (data) => data.book,
    },
  },
  methods: {
    navigateToSearch(term) {
      this.$router.push({ name: "searchResults", params: { searchTerm: term } });
    },
    decodeHtml,
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.banner-container {
  position: relative;
  min-height: 500px;
  background: $primary-gradient;
  overflow: hidden;
}

.banner-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/img/skyline_bg.png");
  background-size: 100%;
  background-position: right 0;
  z-index: 0;
}

.featured-cover {
  width: 248px;
  height: 347px;
  cursor: pointer;
}

.book-thumbnails {
  display: flex;
  align-items: center;
  gap: 6px;
}

.book-thumbnail {
  height: auto;
  object-fit: contain;
  height: 194px;
  width: 130px;
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .banner-container {
    background: $primary-gradient-vertical;
  }
  .banner-container::before {
    background-size: 110%;
    background-position: 50% 16.75%;
  }
}
</style>
