<template>
  <div>
    <NewReleaseBanner />
    <v-container>
      <div class=" book-types-container">
        <h1 class="text-center book-type-header">
          {{ $t("shop.bookTypes") }}
        </h1>
        <div class="justify-center tags-container">
          <v-chip
            v-for="(item, index) in frontPageTags"
            :key="index"
            :color="index + 1 != frontPageTags.length ? 'secondary' : 'primary'"
            class="tag"
            elevation="0"
            :to="{ name: 'filteredBookList', params: { id: item.id, tagName: item.name } }"
          >
            <div>{{ item.name }}</div>
            <v-icon
              color="white"
              right
              small
            >
              mdi-arrow-right 
            </v-icon>
          </v-chip>
        </div>
      </div>
      <div v-if="SHOW_AUDIOBOOKS">
        <div class="divider-heading d-flex flex-column flex-sm-row mb-3 pb-1">
          <h1 class="text-sm-left text-center">
            {{ $t('tags["2730"]') }}
          </h1>
        </div>
        <AudioBooks />
      </div>
      <div class="divider-heading d-flex flex-column flex-sm-row justify-content-between align-center justify-space-between pb-1">
        <h1 class="text-sm-left text-center">
          {{ $t("shop.ourSelection") }}
        </h1>
        <filter-books />
      </div>
      <ApolloQuery
        :query="
          (gql) => getBooksQuery
        "
        :variables="{
          tags: [featuredTag.id],
          page: 1,
        }"
        :update="(data) => data.books.collection"
      >
        <template #default="{ result: { error, data }, isLoading, query }">
          <book-display
            :data="data"
            :is-loading="!!isLoading"
            :error="error"
            :query="query"
            :loading-items="loadingItemsCount"
          />
          <empty-book-list v-if="!isLoading && (!data || data?.length === 0)" />
        </template>
      </ApolloQuery>
    </v-container>
  </div>
</template>

<script>
import FilterBooks from "@/components/FilterBooks";
import EmptyBookList from '@/components/EmptyBookList';
import BookFragments from '@/graphql/BookFragments';
import AudioBooks from '@/components/audioBooks/AudioBooks';
import gql from "graphql-tag";
import BookDisplay from '@/components/BookDisplay';
import { featuredRegionTags, supportedCountries, FEATURED_BOOK_ID, FEATURED_BOOK_PRINT_ID, FEATURED_PROMO_CODE, FEATURED_PROMO_DISCOUNT, RWANDA_ALPHA_3 } from '@/utils/Const';
import { AUDIOBOOK_TAG_ID } from "@/utils/Const";
import NewReleaseBanner from "@/components/featuredBookBanners/NewReleaseBanner";

const BOOK_DETAILS_FRAGMENT = BookFragments.bookDetailsFragment;

const BgPath = require("@/assets/img/ekitabu-logo-repeating.svg");
export default {
  name: "HomeScreen",
  components: {
    FilterBooks,
    EmptyBookList,
    BookDisplay,
    AudioBooks,
    NewReleaseBanner,
},
  data() {
    return {
      bg: BgPath,
      frontPageTags: [
        { name: this.$t('tags.32'), id: 32 },
        { name: this.$t('tags.19'), id: 19 },
        { name: this.$t('tags.20'), id: 20 },
        { name: this.$t('tags.2'), id: 2 },
        { name: this.$t('tags.2729'), id: 2729 },
        { name: this.$t('tags.2730'), id: 2730 }
      ],
      loadingItemsCount: 12,
    };
  },
  computed: {
    audiobookTagId() {
      return AUDIOBOOK_TAG_ID;
    },
    SHOW_AUDIOBOOKS() {
      return process.env.VUE_APP_FEATURE_FLAG_SHOW_AUDIOBOOKS == "true" || process.env.VUE_APP_FEATURE_FLAG_SHOW_AUDIOBOOKS == 1 || process.env.VUE_APP_FEATURE_FLAG_SHOW_AUDIOBOOKS == "1";
    },
    getBooksQuery() {
      return gql`query filterTags($tags: [Int], $page: Int!) {
          books(tags_list: $tags, page: $page) {
            __typename
            collection {
              ...BookDetails
            }
          }
        }
        ${BOOK_DETAILS_FRAGMENT}
      `
    },
    userCountry() {
      return this.$store.state.locale.countryCode;
    },
    featuredTag() {
      if (featuredRegionTags[this.userCountry])
        return featuredRegionTags[this.userCountry];
      else if (featuredRegionTags["default"])
        return featuredRegionTags["default"];
      else return null;
    },
    featuredBookId() {
      return '/books/'+FEATURED_BOOK_ID;
    },
    featuredPrintBookId() {
      return FEATURED_BOOK_PRINT_ID.toString();
    },
    featuredPromoCode() {
      return `<span class="promo-code">${FEATURED_PROMO_CODE}</span>`;
    },
    featuredPromoDiscount() {
      return `<span class="promo-discount">${this.$t('shop.featuredBook.percentDiscount', { percent: FEATURED_PROMO_DISCOUNT })}</span>`;
    },
    supportedCountries() {
      return supportedCountries;
    },
    rwandaAlpha3() {
      return RWANDA_ALPHA_3;
    },
  },
};
</script>

<style scoped>
.theme--light.v-chip:not(.v-chip--active) {
  background: var(--v-secondary-base);
  color: var(--v-textOnPrimary-base);
}

.book-types-container {
  margin-bottom: 42px;
  box-sizing: border-box;
  padding: 0;
}

.book-types-container .col {
  flex-grow: revert;
}

.book-types-container .v-chip {
  border-radius: 0;
  font-size: 1rem;
  padding: 8px 12px;
  margin-bottom: 8px;
}

.product-sort-filter-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.book-type-header {
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 500;
  padding-bottom: 30px;
}

.tags-container {
  display: flex;
}

.tag {
  margin: 0px 12px;
}

@media screen and (max-width: 899px) {
  .tag {
    margin: 0px 6px;
  }
}

@media screen and (max-width: 599px) {
  .tag {
    margin: 4px 0px;
  }

  .tag i {
    position: absolute;
    right: 16px;
  }

  .tags-container {
    flex-direction: column;
  }

  .book-type-header {
    padding-bottom: 10px;
  }

  .product-sort-filter-container {
    justify-content: center;
  }
}
</style>
