<template>
  <div class="gradient-text text-left subtitle-2 font-weight-bold">
    {{
      loading || queriesLoading ? $t('common.loading') :
      isOwned ? $t("bookshelf.owned") :
      (bookPrice != 0 || !showFreeText) ? formattedPrice : $t('shop.free')
    }}
  </div>
</template>

<script>
import currencyMixins from '@/mixins/currencyMixins';
import TaxRates from '@/graphql/TaxRates';
import { supportedCountries, DEFAULT_TAX_REGION } from '@/utils/Const';

export default {
	name: "PriceComponent",
  mixins: [currencyMixins],
  props: {
    bookPrice: {
      type: Number,
      required: true,
    },
    baseCurrency: {
      type: String,
      required: true,
    },
    includeTax: {
      type: Boolean,
      default: false,
    },
    showFreeText: {
      type: Boolean,
      default: true,
    },
    shouldRound: {
      type: Boolean,
      default: false,
    },
    isOwned: {
      type: Boolean,
      default: false,
    },
    quantity: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      formattedPrice: '',
      rate: 0,
    };
  },
  computed: {
    selectedCurrencyCode() {
      return this.getCurrencyCode();
    },
    taxRegion() {
      const storedRegion = this.$store.state.locale.countryCode;

      if (supportedCountries.find((item) => item.alpha2 === storedRegion || item.alpha3 === storedRegion)) {
        return storedRegion;
      }
      else {
        return DEFAULT_TAX_REGION;
      }
    },
    queriesLoading() {
      return this.$apollo.queries.taxRate.loading || this.$apollo.queries.currencies.loading;
    },
  },
  watch: {
    selectedCurrencyCode: 'updateFormattedPrice',
    baseCurrency: 'updateFormattedPrice',
    includeTax: 'updateFormattedPrice',
    rate: 'updateFormattedPrice',
    bookPrice: 'updateFormattedPrice',
    quantity: 'updateFormattedPrice',
  },
  created() {
    this.priceString().then((result) => {
      this.formattedPrice = result;
    });
  },
  apollo: {
    taxRate: {
      query: TaxRates.getTaxRate,
      variables() {
        return {
          id: "/tax_rates/"+this.taxRegion,
        };
      },
      update(data) {
        this.rate = data.taxRate.rate;
        return data;
      },
      skip() {
        return !this.includeTax;
      },
      loadingKey: 'loading',
    },
  },
  methods: {
    async updateFormattedPrice() {
      this.formattedPrice = await this.priceString();
    },
    async priceString() {
      if (this.bookPrice == null) {
        return this.$("common.loading");
      } else {
        const price = await this.getPrice(this.baseCurrency, Number(this.bookPrice), this.includeTax ? this.rate : 0, this.shouldRound, this.quantity);
        const formattedPrice = await this.getPriceString(this.getCurrencyCode(), price, this.shouldRound);
        return formattedPrice;
      }
    },
  },
};
</script>
