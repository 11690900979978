const DEFAULT_ITEMS_PER_PAGE = 10;
const DEFAULT_PAGINATION_OPTIONS = [10, 20, 50, 100];
const ORDER_DIRECTIONS = {
  ASC: "ASC",
  DESC: "DESC",
};

export default {
  props: {
    startDate: {
      type: String,
      default: "",
    },
    endDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      queryRef: "",
      singleSelect: false,
      selectedRows: [],
      orderBy: {},
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      nextCursor: null,
      prevCursor: null,
      totalCount: 0,
      pageData: {},
      currentPage: 1,
      defaultPaginationOptions: DEFAULT_PAGINATION_OPTIONS,
    };
  },
  computed: {
    filterDates() {
      let dates = {};
      if (this.startDate) {
        dates.startDate = new Date(this.startDate.replaceAll("-", "/"));
      }
      if (this.endDate) {
        dates.endDate = new Date(this.endDate.replaceAll("-", "/"));
        dates.endDate.setDate(dates.endDate.getDate() + 1);
      }
      return dates;
    },
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return publicationDate.toLocaleDateString("en-us", options);
    },
    updateSort(byDesc, event) {
      this.orderBy = {};
      if (byDesc == "by") {
        this.sortBy = null;
        if (event != null) {
          let orderByString = event.replace(".", "_");
          this.sortBy = orderByString;
          this.orderBy[this.sortBy] = this.sortDescending(false);
        }
      } else if (byDesc == "desc") {
        if (event != null) {
          this.orderBy[this.sortBy] = this.sortDescending(event);
        }
      }
    },
    customSort(value) {
      return value;
    },
    sortDescending(isDescending) {
      return isDescending ? ORDER_DIRECTIONS.DESC : ORDER_DIRECTIONS.ASC;
    },
    updateData(data, entityName) {
      this.totalCount = data[entityName].totalCount;
      this.pageData = data[entityName].pageInfo;
      return data[entityName].edges.map((edge) => edge.node);
    },
    setCursor(newCursor, isNext) {
      if (isNext > this.currentPage && this.nextCursor != newCursor.endCursor) {
        this.currentPage++;
        this.nextCursor = newCursor.endCursor;
        this.prevCursor = null;
      } else if (
        isNext < this.currentPage &&
        this.prevCursor != newCursor.startCursor
      ) {
        this.currentPage--;
        this.nextCursor = null;
        this.prevCursor = newCursor.startCursor;
      }
    },
    refetchQuery(queryRef) {
      this.$refs[queryRef].$apollo.queries.query.refetch();
    },
  },
}