import { render, staticRenderFns } from "./TransactionsDataTable.vue?vue&type=template&id=25dc7a7e&"
import script from "./TransactionsDataTable.vue?vue&type=script&lang=js&"
export * from "./TransactionsDataTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-2f6a8101d6/2/home/apps/.yarn/berry/cache/vue-loader-npm-15.10.1-3a0b6c19d2-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports