<template>
  <div>
    <DataTableHeaderContainer>
      <h1>
        {{ $t('bookListManager.header') }}
      </h1>
    </DataTableHeaderContainer>
    <v-container class="booklist-manager-container mt-6">
      <v-row>
        <v-col>
          <school-book-lists @bookListSelected="selectedBookListId = $event" />
        </v-col>
        <v-col>
          <manage-school-book-list-form :id="selectedBookListId" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ManageSchoolBookListForm from '@/components/forms/ManageSchoolBookListForm.vue';
import SchoolBookLists from '@/components/lists/SchoolBookLists.vue';
import DataTableHeaderContainer from '@/components/tables/DataTableHeaderContainer.vue';

export default {
  name: "EventManager",
  components: {
    ManageSchoolBookListForm,
    SchoolBookLists,
    DataTableHeaderContainer,
  },
  data() {
    return {
      selectedBookListId: null,
    };
  },
}
</script>

<style scoped>
.booklist-manager-container {
  background-color: var(--v-lightestAccent-base);
}
</style>