<template>
  <div>
    <customer-header
      :user-id="userId"
      :has-date-filter="true"
      :to-previous="{ name: 'customerDashboard' }"
      @startDate="($event) => (startDate = $event)"
      @endDate="($event) => (endDate = $event)"
    />
    <orders-table
      :user-id="userId"
      :start-date="startDate"
      :end-date="endDate"
      :confirmed="true"
      :from-page="fromRoutes.customer"
    />
  </div>
</template>

<script>
import OrdersTable from "@/components/tables/OrdersTable.vue";
import gql from "graphql-tag";
import CustomerHeader from "@/components/CustomerHeader.vue";
import { FROM_ROUTES } from "@/utils/Const";

export default {
  name: "CustomerView",
  components: {
    OrdersTable,
    CustomerHeader,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
    };
  },
  computed: {
    fromRoutes() {
      return FROM_ROUTES;
    },
    userId() {
      return this.$route.params.id;
    },
  },
  apollo: {
    user: {
      query: gql`
        query GetByIdWebServiceUser($id: ID!) {
          getByIdWebServiceUser(userId: $id) {
            id
            email
            familyName
            givenName
            phoneNumber
            emailVerified
          }
        }
      `,
      variables() {
        return {
          id: this.userId,
        };
      },
      update: (data) => data.getByIdWebServiceUser,
      skip() {
        return !this.userId;
      },
    },
  },
};
</script>
