<template>
  <v-container>
    <bookshelf-items />
    <bookshelf-book-lists />
  </v-container>
</template>

<script>
import BookshelfItems from "@/components/BookshelfItems";
import BookshelfBookLists from "@/components/BookshelfBookLists";

export default {
  name: "BookshelfUser",
  components: {
    BookshelfItems,
    BookshelfBookLists,
  },
};
</script>
