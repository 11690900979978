<template>
  <v-btn
    v-bind="$attrs"
    :class="'primary-button font-weight-bold '+(rounded ? 'rounded-pill' : 'rounded-lg')"
    :style="`color: var(--v-${textColor}-base); ` + (disabled ? `background-color: var(--v-${disabledColor}-base)` : `background-image: ${gradientStyle};`)"
    :disabled="disabled"
    :elevation="elevation"
    small
    @click.stop="$emit('click')"
    v-on="on"
  >
    <slot />
  </v-btn>
</template>

<script>
import { GRADIENT_DEFAULTS } from '@/utils/Const';

export default {
  name: "PrimaryButton",
  props: {
    color1: {
      type: String,
      default: "black",
    },
    color2: {
      type: String,
      default: "footerBackground",
    },
    gradientPercent: {
      type: Number,
      default: GRADIENT_DEFAULTS.startPercent,
    },
    gradientDeg: {     
      type: Number,
      default: GRADIENT_DEFAULTS.directionDegrees,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: "textOnPrimary",
    },
    disabledTextColor: {
      type: String,
      default: "textOnPrimary",
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabledColor: {
      type: String,
      default: "lightAccent",
    },
    elevation: {
      type: [Number, String],
      default: 0,
    },
    on: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    gradientStyle() {
      return `linear-gradient(${this.gradientDeg}deg, var(--v-${this.color1}-base) ${this.gradientPercent/2}%, var(--v-${this.color2}-base) ${50 + (this.gradientPercent/2)}%, var(--v-${this.color1}-base) ${100 - this.gradientPercent}%)`;
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.v-btn {
  background-position: left bottom;
  background-size: 200% auto;
  transition: 0.5s;
}

.v-btn:hover {
  background-position: right top;
}
</style>