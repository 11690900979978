<template>
  <v-container>
    <div class="pa-6 refund-policy-container">
      <h1 class="mb-6">
        {{ $t('routes.refundPolicy') }}
      </h1>
      <p>{{ $t('refundPolicy.pg1') }}</p>
      <div class="mb-4">
        <p>{{ $t('refundPolicy.listTitle') }}</p>
        <ul>
          <li class="mb-2">
            {{ $t('refundPolicy.li1') }}
          </li>
          <li class="mb-2">
            {{ $t('refundPolicy.li2') }}
          </li>
          <i18n
            path="refundPolicy.li3"
            tag="li"
            class="mb-2"
          >
            <template #bookshelfLink>
              <router-link
                :to="{ name: 'bookshelf' }"
                class="red-text"
              >
                {{ $t('refundPolicy.ekitabuBookshelf') }}
              </router-link>
            </template>
          </i18n>
        </ul>
      </div>
      <p>{{ $t('refundPolicy.pg2') }}</p>
      <p>{{ $t('refundPolicy.pg3') }}</p>
      <i18n
        path="refundPolicy.pg4"
        tag="p"
      >
        <template #supportUrl>
          <a :href="supportUrl">{{ supportUrl }}</a>
        </template>
        <template #supportEmail>
          <span class="red-text">{{ helpEmail }}</span>
        </template>
        <template #supportPhone>
          <span class="red-text">{{ helpPhone }}</span>
        </template>
        <template #whatsAppLink>
          <a
            :href="whatsAppUrl"
            target="_blank"
          >
            <span>{{ $t('help.whatsApp') }}</span>
          </a>
        </template>
      </i18n>
    </div>
  </v-container>
</template>

<script>
import { HELP_EMAIL, HELP_PHONE_NUMBER, WHATS_APP_LINK, SUPPORT_URL } from '@/utils/Const'
export default {
  name: 'RefundPolicy',
  computed: {
    helpEmail() {
      return HELP_EMAIL
    },
    helpPhone() {
      return HELP_PHONE_NUMBER
    },
    whatsAppUrl() {
      return WHATS_APP_LINK
    },
    supportUrl() {
      return SUPPORT_URL
    }
  }
}
</script>

<style scoped>
.refund-policy-container {
  background-color: var(--v-lightestAccent-base);
}
</style>