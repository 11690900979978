export default {
  computed: {
    // Returns the current timezone as a string
    currentTimeZone() {
      return new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2];
    },
  },
  methods: {
    // Converts a date string to an ISO string
    toIsoString(dateString) {
      return dateString ? new Date(dateString).toISOString() : new Date().toISOString();
    },
    // Truncates an ISO string to include date and time but not timezone
    truncateToDateTime(date) {
      date = this.toIsoString(date);
      return date?.slice(0, 16);
    }
  },
}