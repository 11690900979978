<template>
  <ApolloMutation
    :mutation="(gql) => placeCreditCardOrderMutation"
    :variables="{
      input: {
        ...paymentInfo,
        phoneNumber: phoneNumber ?? null,
        alternativePhoneNumber: shippingInfo?.altPhoneNumber,
        placeId: shippingInfo?.placeId,
        addressDetails: shippingInfo?.addressDetails,
        schoolId: schoolInfo?.schoolId,
        studentName: schoolInfo?.studentName,
        studentClass: schoolInfo?.studentClass,
        ...(isAdminOrder && {
          isForPhone: true,
          forFullName: adminOrderDetails?.forName,
          forEmail: adminOrderDetails?.forEmail,
          eventId: adminOrderDetails?.eventId,
        })
      }
    }"
    @done="onDone"
    @error="() => { loading = false; }"
  >
    <template #default="{ mutate }">
      <PrimaryButton
        id="submit"
        class="text-uppercase"
        block
        :disabled="loading || invalid"
        :loading="loading"
        @click="() => {
          loading = true;
          mutate();
        }"
      >
        {{ $t('checkout.form.placeOrder') }}
        <v-icon
          class="ml-2 has-glow"
          x-small
        >
          $triangleRight
        </v-icon>
      </PrimaryButton>
      <p v-if="loading">
        {{ $t('checkout.orderProcessing') }}
      </p>
    </template>
  </ApolloMutation>
</template>

<script>
import OrderQueries from '@/graphql/OrderQueries';
import BookQueries from "@/graphql/BookQueries";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

export default {
  name: "CreditCardPlaceOrderButton",
  components: {
    PrimaryButton,
  },
  props: {
    paymentInfo: {
      type: Object,
      required: true,
    },
    invalid: {
      type: Boolean,
      required: true,
    },
    shouldSaveAccountInfo: {
      type: Boolean,
      default: false,
    },
    shippingInfo: {
      type: Object,
      default: null,
    },
    schoolInfo: {
      type: Object,
      default: null,
    },
    isPickupOrder: {
      type: Boolean,
      default: false,
    },
    isAdminOrder: {
      type: Boolean,
      default: false,
    },
    adminOrderDetails: {
      type: Object,
      default: null,
    },
    phoneNumber: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    placeCreditCardOrderMutation() {
      return OrderQueries.placeCreditCardOrder;
    },
  },
  methods: {
    async onDone({ data }) {
      if (data.placeCreditCardOrder.order.confirmed) {
        await this.$apollo.query({
          query: BookQueries.getUserBookshelf,
          fetchPolicy: "network-only",
        });
        this.$store.commit("setCurrentCart", null);
        this.loading = false,
        this.$router.push({
          name: "confirmed",
          params: {
            id: this.paymentInfo.id,
          },
        });
      }
    },
  },
}
</script>