<template>
  <div>
    <div v-if="$apollo.queries.book.loading">
      <v-col class="d-flex">
        <v-skeleton-loader
          type="image"
          class="book-cover-image mr-3"
        />
        <div class="d-flex flex-column flex-grow-1">
          <v-skeleton-loader
            type="heading"
            class="mb-2"
          />
          <v-skeleton-loader
            type="text"
            style="max-width: 400px"
          />
          <v-skeleton-loader
            type="heading"
            class="mt-auto"
            style="max-width: 200px"
          />
        </div>
      </v-col>
    </div>
    <apollo-error-alert
      v-else-if="bookError"
      :error="bookError"
    />
    <div
      v-else
      class="d-flex"
    >
      <div class="mr-3">
        <v-img
          class="book-cover-image"
          :src="book.thumbnailUrl"
        >
          <template #placeholder>
            <image-loading />
          </template>
        </v-img>
      </div>
      <div class="d-flex flex-column justify-space-between mr-3">
        <h3 class="subtitle-2 font-weight-bold">
          {{ decodeHtml(orderLine.name) }}
        </h3>
        <div v-if="book.authors && book.authors.length > 0">
          <h3 class="subtitle-2 font-weight-regular">
            <span>
              {{ $t("bookInfo.by") }}
            </span>
            <span
              v-for="(author, index) in authorList"
              :key="author"
              class="mr-1"
            >
              {{
                index + 1 == authorList.length
                  ? decodeHtml(author)
                  : $t("bookInfo.authorListItem", {
                    author: decodeHtml(author),
                  })
              }}
            </span>
            <span
              v-if="remainingAuthorsCount > 0"
              class="book-info__author"
            >
              {{ $t("bookInfo.moreAuthors", { count: remainingAuthorsCount }) }}
            </span>
          </h3>
        </div>
        <h4
          v-else
          class="book-info__author"
        >
          {{ $t("bookInfo.authorUnknown") }}
        </h4>
        <div class="mt-auto">
          <div
            v-if="showPrice && orderCurrency"
            class="d-flex"
          >
            <price
              :class="'mt-auto mr-2 ' + (hasDiscount ? ' striked-text' : '')"
              :book-price="Number(orderLine.basePrice)"
              :base-currency="orderLine.baseCurrency._id"
              :quantity="orderLine?.quantity ?? 1"
              include-tax
              should-round
            />
            <price
              v-if="hasDiscount"
              class="mt-auto discount-text"
              :book-price="Number(orderLine.price)"
              :base-currency="orderCurrency._id"
            />
          </div>
          <h5
            v-if="hasDiscount"
            class="discount-text"
          >
            {{ $t("checkout.hasCoupon") }}
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import Price from "@/components/Price";
import ImageLoading from "@/components/ImageLoading";
import gql from "graphql-tag";
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import { FORMATS } from "@/utils/Const";

export default {
  name: "OrderBookDisplayItem",
  components: {
    Price,
    ImageLoading,
    ApolloErrorAlert,
  },
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    orderCurrency: {
      type: Object,
      default: null,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    showQuantityInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      book: {},
      authorsShownCount: 2,
      bookError: null,
    };
  },
  computed: {
    authorList() {
      return this.book.authors.slice(0, this.authorsShownCount);
    },
    remainingAuthorsCount() {
      return this.book.authors.length - this.authorList.length;
    },
    hasDiscount() {
      return this.orderLine.discount && this.orderLine.discount > 0;
    },
    formats() {
      return FORMATS;
    },
  },
  apollo: {
    book: {
      query: gql`
        query Book($id: ID!) {
          book(id: $id) {
            id
            thumbnailUrl
            authors
          }
        }
      `,
      variables() {
        return {
          id: "/books/" + this.orderLine.productId,
        };
      },
      error(error) {
        this.bookError = error;
      },
    },
  },
  methods: {
    decodeHtml,
  },
};
</script>

<style scoped>
.book-cover-image {
  width: 62px;
  height: 90px;
}

.discount-text,
.discount-text.gradient-text {
  color: var(--v-success-base);
  -webkit-text-fill-color: currentColor;
}
</style>
