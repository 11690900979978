import i18n from '@/i18n';

export const USERNAME_RULES = [
  // phone # regex from https://stackoverflow.com/a/16699507/834393
  // email regex from https://stackoverflow.com/a/201378/834393
  v=> (/((\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4})/.test(v) ||
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(v))
    || "Must be an email or phone number"
];

export const PASSWORD_RULES = [ v => (v.length >= 8) || i18n.t('login.createAccount.passwordLengthAtLeast')];
export const PASSWORD_REPEAT_RULES = (newPassword) => [ v => (v == newPassword) || i18n.t('login.createAccount.passwordRepeatNotSame')];

export const HELP_PHONE_NUMBER = "+254 740 524 629";
export const HELP_EMAIL = "support@ekitabu.com";
export const SUPPORT_URL = "https://support.ekitabu.com/shophelp/";
export const PRIVACY_POLICY_URL = "https://www.ekitabu.com/privacy-policy";
export const TERMS_OF_SERVICE_URL = "https://www.ekitabu.com/terms-of-service";
export const WHATS_APP_LINK = "https://wa.me/254740524629";

export const languages = [
  { code: "eng", name: "English" },
  { code: "kin", name: "Kinyarwanda" },
  { code: "swh", name: "Kiswahili" },
  { code: "nya", name: "Chichewa" },
];

export const KENYA_ALPHA_3 = "KEN";
export const RWANDA_ALPHA_3 = "RWA";
export const MALAWI_ALPHA_3 = "MWI";

export const supportedCountries = [
  { alpha2: "KE", alpha3: KENYA_ALPHA_3, currencyCode: "KES", name: "Kenya" },
  { alpha2: "RW", alpha3: RWANDA_ALPHA_3, currencyCode: "RWF", name: "Rwanda" },
  { alpha2: "MW", alpha3: MALAWI_ALPHA_3, currencyCode: "MWK", name: "Malawi" },
];

export const DEFAULT_TAX_REGION = "KEN";
export const DEFAULT_CURRENCY = "KES";
export const NOT_SUPPORTED_CURRENCY = "USD";

export const ROUND_TO_INT_CURRENCIES = ["KES", "RWF", "MWK"];

function createTagObjectsArray(tagIds) {
  const tags =  [];

  tagIds.forEach(tagId => {
    tags.push({ name: i18n.t('tags.' + tagId.toString()), _id: tagId});
  });

  return tags;
}

const featuredTagIds = [
  152,
  170,
  157,
  175,
  159,
  165,
  309,
  179,
  184,
  169,
  164,
  150,
  180,
  198,
  227,
  156,
  177,
  181,
  18,
  2730,
];

export const FEATURED_TAGS = createTagObjectsArray(featuredTagIds);

export const gradeTagIds = [
  25,
  24,
  134,
  135,
  136,
  137,
  138,
  139,
  140,
  141,
  142,
  143,
  144,
  145
];

export const AUDIOBOOK_TAG_ID = 2730;

export const BUY_NOW_IDS = [
  AUDIOBOOK_TAG_ID
];

export const GRADE_LEVEL_TAGS = createTagObjectsArray(gradeTagIds);

export const DEFAULT_PAGE_SIZE = 20;

export const USER_ROLES = {
  ADMIN: "ROLE_ADMIN",
  USER: "ROLE_USER",
  CHECKER: "ROLE_CHECKER",
}

export const COUPON_RESTRICTIONS = Object.freeze({
  NONE: "NONE",
  BOOK: "BOOK",
  BOOKS: "BOOKS",
  PUBLISHER: "PUBLISHER",
  TAG: "TAG"
});

export const LOGIN_PROVIDERS = [
  "google",
];

export const PAYMENT_TYPES = Object.freeze({
  mpesa: "mpesa",
  creditCard: "creditCard",
  rwf: "rwf",
});

export const PAYMENT_PROVIDERS = Object.freeze({
  safaricom: "Safaricom MPESA",
  dpo: "DPO",
  mtn: "MTN",
  cash: "Cash"
});

export const featuredRegionTags = Object.freeze({
  "default": {
    id: 3,
    name: "Featured"
  },
  "KEN": {
    id: 15,
    name: "Featured-KE"
  },
  "MWI": {
    id: 16,
    name: "Featured-MW"
  },
  "RWA": {
    id: 17,
    name: "Featured-RW"
  }
});

export const FORMATS = Object.freeze({
  pdf: "application/pdf+lcp",
  epub: "application/epub+zip",
  print: "print",
  audio: "application/lpf+zip"
});

export const FEATURED_BOOK_ID = 4897;
export const FEATURED_BOOK_PRINT_ID = 5076;
export const FEATURED_PROMO_CODE = 'VERA';
export const FEATURED_PROMO_DISCOUNT = 20;

export const FROM_ROUTES = Object.freeze({
  event: "eventOverview",
  customer: "customer",
});

export const AUDIO_APP_LINK = "myapp://shop.ekitabu.com/mybookshelf";
export const AUDIO_APP_STORE_LINK = "https://play.google.com/store/apps/details?id=com.ekitabu.audio";
export const AUDIO_APP_STORE_LINK_IOS = "https://apps.apple.com/us/app/on-ekitabu/id6479268402";

export const GRADIENT_DEFAULTS = Object.freeze({
  directionDegrees: -90,
  startPercent: 0,
  endPercent: 100,
});
