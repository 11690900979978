<template>
  <svg
    class="custom-fill-icon"
    :width="size"
    :height="size"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.85596 0.767578H13.349C14.1786 0.767578 14.7795 1.55999 14.5569 2.36039L13.4443 6.36039C13.2933 6.90349 12.7994 7.27921 12.2365 7.27921H3.99373L2.85596 0.767578Z"
    />
    <path
      d="M0 0.593023C0 0.265506 0.265095 0 0.592105 0H1.76655C1.88872 0 2.01044 0.0147107 2.1291 0.0438156C2.71264 0.186945 3.15779 0.660046 3.26586 1.25195L4.40259 7.4781C4.50237 8.0246 4.97419 8.42406 5.52883 8.43163L12.4964 8.5267C12.8289 8.53124 13.096 8.80244 13.096 9.13542C13.096 9.47163 12.8238 9.74419 12.4881 9.74419H4.65171C4.21886 9.74419 3.82196 9.50302 3.62201 9.11853L3.44568 8.77946C3.39342 8.67896 3.35611 8.57136 3.33493 8.46006L1.95046 1.18605H0.592105C0.265095 1.18605 0 0.920541 0 0.593023Z"
    />
    <ellipse
      cx="4.50452"
      cy="11.813"
      rx="1.18421"
      ry="1.18605"
    />
    <ellipse
      cx="11.7958"
      cy="11.813"
      rx="1.18421"
      ry="1.18605"
    />
  </svg>
</template>

<script>
export default {
  name: "BookshelfIcon",
  props: {
    size: {
      type: Number,
      default: 15,
    },
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>
