<template>
  <ApolloQuery
    :query="(gql) => bookListsQuery"
    :update="(data) => data.getMyBookLists"
  >
    <template #default="{result: {error, data}, isLoading }">
      <div class="booklists-container mt-6 py-6 mx-n7 px-7">
        <div class="booklists-header-container mb-6">
          <div class="d-flex justify-start align-center mb-3">
            <book-list-icon
              :size="$vuetify.breakpoint.xs ? 18 : 24"
              class="mr-3"
              color="var(--v-primary-base)"
            />
            <h1 class="booklists-header">
              {{ $t("bookshelf.yourBookLists") }}
            </h1>
          </div>
        </div>
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <div v-if="isLoading">
          <v-skeleton-loader
            type="text@3"
          />
        </div>
        <div
          v-else-if="data && data.length == 0"
          class="booklist-no-books"
        >
          <p>{{ $t("bookList.createAndAddTo") }}</p>
          <v-btn
            :to="{ name: 'home' }"
            color="primary"
            tile
            depressed
          >
            {{ $t("common.returnToShop") }}
            <v-icon right>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
        <div
          v-else
        >
          <v-row
            v-for="(bookList, index) in data"
            :key="bookList.id"
            class="my-0 py-0 align-center"
          >
            <v-col class="my-0 py-0">
              <v-btn
                :color="index == data.length - 1 ? 'primary' : 'secondary'"
                :to="{ name: 'userBookList', params: { id: bookList._id } }"
                elevation="0"
                tile
                block
                :small="$vuetify.breakpoint.xs"
                class="booklist-button my-1"
              >
                {{ bookList.name }}
                <v-spacer />
                <v-icon size="17">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="col-auto my-0 py-0 pl-0">
              <delete-book-list-button
                v-model="snackbar"
                :book-list-id="bookList.id"
                :book-list-name="bookList.name"
              />
            </v-col>
          </v-row>
        </div>
        <CreateBookListForm class="mt-6" />
        <success-snackbar
          v-model="snackbar"
          :message="$t('bookshelf.bookListDeleted')"
        />
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import BookListIcon from "@/components/icons/BookListIcon";
import BookListQueries from "@/graphql/BookListQueries";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import DeleteBookListButton from "@/components/DeleteBookListButton";
import SuccessSnackbar from "@/components/SuccessSnackbar";
import CreateBookListForm from "@/components/CreateBookListForm";

export default {
  name: "BookshelfBookLists",
  components: {
    BookListIcon,
    ApolloErrorAlert,
    DeleteBookListButton,
    SuccessSnackbar,
    CreateBookListForm,
    CreateBookListForm
},
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    bookListsQuery() {
      return BookListQueries.getUserBookLists;
    },
  },
};
</script>

<style scoped>
  .booklists-container {
    background-color: var(--v-lightestAccent-base);
  }

  .booklists-header {
    font-size: 1.5rem;
    font-weight: 500;
    align-self: flex-end;
    line-height: 1;
    text-transform: uppercase;
  }

  .booklists-container p {
    font-size: 1rem;
  }

  @media screen and (max-width: 599px) {
    .booklists-header {
      font-size: 1rem;
    }

    .booklists-container p {
      font-size: 0.75rem;
    }
  }
</style>
