<template>
  <!-- TODO: Add to this to include a country's flag in addition to the country code -->
  <!-- Github Issue: https://github.com/ekitabu-LLC/shop-web/issues/349 -->
  <div>
    <div class="footer-option-container">
      <span class="footer-option-label">{{ $t("common.country") }}:</span>
      <div class="country-code">
        {{ detectedCountry }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetectedCountry",
  computed: {
    detectedCountry() {
      return this.$store.state.locale.countryCode;
    },
  },
};
</script>

<style>
.country-code {
  color: var(--v-textOnPrimary-base);
}
</style>