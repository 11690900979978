<template>
  <ekitabu-select
    id="tax-region-select"
    v-model="currentValue"
    v-bind="$attrs"
    :loading="$apollo.queries.taxRates.loading"
    :disabled="$apollo.queries.taxRates.loading"
    class="mt-2"
    :label="$t('transactionReports.taxRegion')"
    :placeholder="$t('transactionReports.taxRegion')"
    :items="taxRates ?? []"
    :item-text="(item) => $t('countryCodes.'+item._id) + ': ' + (item.rate * 100) + '%'"
    item-value="id"
    clearable
  />
</template>

<script>
import gql from "graphql-tag";
import EkitabuSelect from "@/components/EkitabuSelect";

export default {
  name: "EkitabuEventSelect",
  components: {
    EkitabuSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  apollo: {
    taxRates: {
      query: gql`
        query TaxRates {
          taxRates {
            id
            _id
            rate
          }
        }
      `,
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit("input", val);
    },
  },
}
</script>
