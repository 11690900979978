<template>
  <div>
    <v-btn
      class="audio-button"
      tile
      outlined
      elevation="0"
      color="primary"
      :loading="!canPlay"
      :disabled="!canPlay"
      :small="$vuetify.breakpoint.md || $vuetify.breakpoint.sm"
      @click="!isPlaying ? playAudio() : stopAudio()"
    >
      <v-icon class="mr-2">
        {{ !isPlaying ? "mdi-play" : "mdi-pause" }}
      </v-icon>
      {{ $t("common.preview") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    audioUrl: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      audio: null,
      canPlay: false,
      isPlaying: false,
    };
  },
  watch: {
    audio() {
      if (this.audio && !this.audio.error) {
        this.canPlay = true;
      }
    }
  },
  mounted() {
    this.audio = new Audio(this.audioUrl);
    this.audio.addEventListener("play", () => (this.isPlaying = true));
    this.audio.addEventListener("pause", () => (this.isPlaying = false));
  },
  beforeDestroy() {
    this.stopAudio();
    this.audio.removeEventListener("play", () => (this.isPlaying = true));
    this.audio.removeEventListener("pause", () => (this.isPlaying = false));
    this.audio = null;
  },
  methods: {
    playAudio() {
      if (this.audio) {
        this.audio.play();
      }
    },
    stopAudio() {
      if (this.audio) {
        this.audio.pause();
      }
    },
  },
};
</script>
