<template>
  <div class="options-container">
    <currency-select />
    <language-select />
    <detected-country />
  </div>
</template>

<script>
  import CurrencySelect from './CurrencySelect.vue';
  import LanguageSelect from  './LanguageSelect.vue';
  import DetectedCountry from './DetectedCountry.vue';

  export default {
    components: {
      CurrencySelect,
      LanguageSelect,
      DetectedCountry,
    },
  };
</script>
