<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t(id ? 'eventManager.updateEvent' : 'eventManager.createEvent') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <ApolloMutation
      :mutation="gql => id ? updateEventMutation : createEventMutation"
      :variables="{ input: {
        ...(id ? { id: id } : {}),
        name: name,
        notes: notes,
        ...(id ? {} : {
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        }),
        books: books,
        taxRate: taxRate,
        startDate: dates.startDate,
        endDate: dates.endDate,
      }}"
      :update="onDone"
    >
      <template #default="{ mutate, loading, error }">
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
        >
          <EkitabuTextInput
            id="event-name"
            v-model="name"
            class="fill-width"
            :label="$t('eventManager.eventName')"
            :placeholder="$t('eventManager.eventName')"
            rules="required|min:3"
            required
            :disabled="loading || $apollo.loading"
          />
          <EkitabuTextArea
            id="event-notes"
            v-model="notes"
            class="fill-width mt-3"
            rules="min:5|max:500"
            :label="$t('eventManager.eventNotes')"
            :placeholder="$t('eventManager.eventNotes')"
            :disabled="loading || $apollo.loading"
          />
          <v-row>
            <v-col class="col-12 col-sm-6">
              <EkitabuTextInput
                id="event-start-date"
                v-model="dates.startDate"
                class="fill-width mt-3"
                :label="$t('couponManager.startDate')"
                type="date"
                rules="required|before:@event-end-date"
                required
              />
            </v-col>
            <v-col class="col-12 col-sm-6">
              <EkitabuTextInput
                id="event-end-date"
                v-model="dates.endDate"
                class="fill-width mt-3"
                :label="$t('couponManager.endDate')"
                type="date"
                rules="required"
                required
              />
            </v-col>
          </v-row>
          <TaxRegionSelect
            v-model="taxRate"
            class="fill-width"
            required
            rules="required"
          />
          <BookSearch
            id="coupon-books"
            v-model="books"
            class="mt-3"
            multiple
            required
            :label="$t('couponManager.forBooks')"
          />
          <v-btn
            class="mt-3"
            tile
            color="primary"
            elevation="0"
            :disabled="!valid || loading || $apollo.loading || !isChanged"
            :loading="loading || $apollo.loading"
            block
            @click="mutate"
          >
            {{ id ? $t('eventManager.updateEvent') : $t('eventManager.createEvent') }}
          </v-btn>
        </validation-observer>
      </template>
    </ApolloMutation>
    <SuccessSnackbar
      :value="showSnackbar"
      :message="id ? $t('eventManager.eventUpdated') : $t('eventManager.eventCreated')"
    />
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import EkitabuTextArea from '@/components/EkitabuTextArea';
import { required, min, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import i18n from '@/i18n';
import gql from 'graphql-tag';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import EventMutations from '@/graphql/EventMutations';
import SuccessSnackbar from '@/components/SuccessSnackbar.vue';
import BookSearch from '@/components/BookSearch.vue';
import TaxRegionSelect from '@/components/inputs/TaxRegionSelect.vue';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', min: '{min}'}),
})

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', max: '{max}'}),
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    if (!target) return true;
    return new Date(value) < new Date(target);
  },
  message: i18n.t('couponManager.dateError'),
});

export default {
  name: "ManageEventForm",
  components: {
    EkitabuTextInput,
    EkitabuTextArea,
    ValidationObserver,
    ApolloErrorAlert,
    SuccessSnackbar,
    BookSearch,
    TaxRegionSelect,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      notes: '',
      taxRate: null,
      books: [],
      showSnackbar: false,
      dates: {
        startDate: null,
        endDate: null,
      },
    };
  },
  apollo: {
    event: {
      query: gql`
        query Event($id: ID!) {
          event(id: $id) {
            id
            _id
            name
            notes
            books
            taxRate {
              id
              _id
              rate
            }
            startDate
            endDate
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data }) {
        this.name = data.event.name;
        this.notes = data.event.notes;
        this.books = data.event.books;
        this.taxRate = data.event.taxRate?.id;
        this.dates.startDate = new Date(data.event.startDate).toISOString().slice(0, 10);
        this.dates.endDate = new Date(data.event.endDate).toISOString().slice(0, 10);
      },
    },
  },
  computed: {
    createEventMutation() {
      return EventMutations.createEvent;
    },
    updateEventMutation() {
      return EventMutations.updateEvent;
    },
    booksChanged() {
      return this.event?.books != (this.books.length > 0 ? this.books : null);
    },
    isChanged() {
      if (!this.event) {
        return true;
      }
      else {
        return(
          this.name !== this.event.name ||
          this.notes !== this.event.notes ||
          this.booksChanged ||
          this.taxRate !== this.event.taxRate?.id ||
          this.dates.startDate !== new Date(this.event.startDate).toISOString().slice(0, 10) ||
          this.dates.endDate !== new Date(this.event.endDate).toISOString().slice(0, 10)
        );
      }
    },
  },
  watch: {
    id(val) {
      if (!val) {
        this.name = '';
        this.notes = '';
        this.books = [];
        this.taxRate = null;
        this.dates.startDate = null;
        this.dates.endDate = null;
      }
    },
  },
  methods: {
    onDone(cache, { data }) {
      if (data?.createEvent?.event) {
        const events = cache.readQuery({ query: gql`
          query Events {
            events {
              edges {
                node {
                  id
                  _id
                  name
                }
              }
            }
          }
        `});

        cache.writeQuery({
          query: gql`
            query Events {
              events {
                edges {
                  node {
                    id
                    _id
                    name
                  }
                }
              }
            }
          `,
          data: {
            events: {
              edges: [
                ...events.events.edges,
                {
                  node: data.createEvent.event,
                  __typename: 'EventNodeEdge',
                },
              ],
              __typename: 'EventNodeConnection',
            },
          },
        });

        this.name = "";
        this.notes = "";
        this.books = [];
        this.taxRate = null;
        this.dates.startDate = null;
        this.dates.endDate = null;
      }

      this.$refs.observer.reset();
      this.showSnackbar = true;
    },
  },
}
</script>