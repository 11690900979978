<template>
  <ApolloMutation
    :mutation="(gql) => sendHelpRequestMutation"
    :variables="{
      input: {
        fromEmail: email,
        phone: phone,
        name: name,
        message: message,
      },
    }"
    @done="onDone"
  >
    <template #default="{ mutate, loading, error }">
      <v-container>
        <v-row
          no-gutters
          align="start"
        >
          <v-col class="col-12 col-md-8">
            <h1 class="contact-header mb-4">
              {{ $t("help.questions") }}
            </h1>
            <div class="contact-email-form pa-3">
              <validation-observer
                ref="observer"
                v-slot="{ invalid }"
              >
                <v-form
                  ref="contactForm"
                >
                  <v-row no-gutters>
                    <v-col
                      class="py-1 pr-sm-3"
                      cols="12"
                      sm="6"
                    >
                      <ekitabu-text-input
                        id="name"
                        v-model="name"
                        rules="required|max:50|min:3"
                        :label="$t('profile.name')"
                        label-style="color: var(--v-primarytext-base);"
                        required
                        type="name"
                        class="fill-width"
                      />
                    </v-col>
                    <v-col
                      class="py-1 pl-sm-3"
                      cols="12"
                      sm="6"
                    >
                      <ekitabu-text-input
                        id="email"
                        v-model="email"
                        rules="required|email"
                        :label="$t('profile.email')"
                        label-style="color: var(--v-primarytext-base);"
                        required
                        type="email"
                        class="fill-width"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col
                      class="py-1"
                      cols="12"
                    >
                      <ekitabu-masked-phone-input
                        id="phone"
                        v-model="phone"
                        :label="$t('profile.phone')"
                        label-style="color: var(--v-primarytext-base);"
                        type="phone"
                        class="fill-width"
                        rules="max:9|min:9"
                      />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="py-1">
                      <ekitabu-text-area
                        id="message"
                        v-model="message"
                        rules="required|min:10"
                        :label="$t('help.descriptionOfIssue')"
                        label-style="color: var(--v-primarytext-base);"
                        required
                        class="fill-width contact-text-area"
                        rows="5"
                      />
                      <apollo-error-alert
                        v-if="error"
                        :error="error"
                      />
                      <div class="d-flex">
                        <v-btn
                          elevation="0"
                          tile
                          color="primary"
                          class="mt-2 ml-auto"
                          :loading="loading"
                          :disabled="invalid"
                          @click="mutate"
                        >
                          {{
                            $t("help.send")
                          }}
                        </v-btn>                        
                      </div>
                    </v-col>
                  </v-row>
                </v-form>
              </validation-observer>
            </div>
          </v-col>
          <v-col class="px-md-6">
            <h2 class="contact-details-header pa-0 mb-4 mt-4 mt-md-0">
              {{ $t("help.contactUs") }}
            </h2>
            <div class="contact-card-container">
              <div class="contact-card mb-4 px-2">
                <span class="contact-card-label">{{ $t("help.whatsApp") }}</span>
                <br>
                <a
                  class="contact-card-link"
                  style="color: var(--v-primaryText-base);"
                  target="#"
                  :href="whatsAppLink"
                >
                  {{ $t('help.whatsAppLink') }}
                </a>
              </div>
              <div class="contact-card mb-4 px-2">
                <span class="contact-card-label">{{ $t("help.phone") }}</span>
                <br>{{ helpNumber }}
              </div>
              <div class="contact-card mb-4 px-2">
                <span class="contact-card-label">{{ $t("profile.email") }}</span>
                <br>{{ helpEmail }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <success-snackbar
        v-model="snackbar"
        :message="$t('help.messageSent')"
      />
    </template>
  </ApolloMutation>
</template>

<script>
import { HELP_PHONE_NUMBER, HELP_EMAIL, WHATS_APP_LINK } from "@/utils/Const";
import EkitabuTextInput from "@/components/EkitabuTextInput";
import EkitabuTextArea from "@/components/EkitabuTextArea";
import OrderQueries from "@/graphql/OrderQueries";
import SuccessSnackbar from "@/components/SuccessSnackbar";
import { required, email, max, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import i18n from '@/i18n';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import EkitabuMaskedPhoneInput from '@/components/EkitabuMaskedPhoneInput';

setInteractionMode('eager')

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', length: '{length}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', length: '{length}'}),
})

extend('email', {
  ...email,
  message: i18n.t('errors.email'),
})

export default {
  name: "HelpScreen",
  components: {
    EkitabuTextInput,
    EkitabuTextArea,
    ValidationObserver,
    SuccessSnackbar,
    ApolloErrorAlert,
    EkitabuMaskedPhoneInput,
  },
  data() {
    return {
      helpNumber: HELP_PHONE_NUMBER,
      helpEmail: HELP_EMAIL,
      whatsAppLink: WHATS_APP_LINK,
      name: "",
      email: "",
      message: "",
      phone: "",
      snackbar: false,
    };
  },
  computed: {
    sendHelpRequestMutation() {
      return OrderQueries.sendHelpRequest;
    },
  },
  methods: {
    onDone() {
      this.$refs.contactForm.reset();
      this.$refs.observer.reset();
      this.snackbar = true;
    }
  },
};
</script>

<style>
.contact-header {
  font-size: 1.5rem;
  font-weight: 500;
}

.contact-email-form {
  background-color: var(--v-lightestAccent-base);
}

.contact-details-header {
  font-size: 1.5rem;
  font-weight: 500;
}

.contact-card {
  border-left: 3px solid var(--v-primary-base);
  font-size: 1rem;
  width: fit-content;
}

.contact-card-label {
  color: var(--v-secondaryText-base);
  text-transform: uppercase;
}

.contact-card-container {
  display: flex;
  flex-direction: column;
}

.contact-card-link {
  text-decoration: none;
}

.contact-card-link:hover {
  text-decoration: underline;
}

.fill-width {
  max-width: 100% !important;
}

@media (max-width: 899px) {
  .contact-header {
    font-size: 1.375rem;
  }

  .contact-details-header {
    font-size: 1.375rem;
  }

  .contact-card {
    font-size: 0.875rem;
  }
}

@media (max-width: 599px) {
  .contact-header {
    font-size: 1.25rem;
  }

  .contact-details-header {
    font-size: 1.25rem;
  }

  .contact-card {
    font-size: 0.75rem;
  }
}
</style>
