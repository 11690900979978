<template>
  <v-container>
    <div class="verification-container">
      <v-icon
        color="primaryText"
        size="100"
      >
        mdi-email-outline
      </v-icon>
      <div v-if="!isVerified">
        <h1>{{ $t('profile.verifyEmailHeader') }}</h1>
        <p>{{ $t('profile.verifyEmailBody') }}</p>          
      </div>
      <div v-else>
        <h1>{{ $t('profile.verificationSuccessHeader') }}</h1>
        <p>{{ $t('profile.verificationSuccessBody') }}</p>
        <v-btn
          depressed
          tile
          color="primary"
          :to="{ name: 'home' }"
        >
          {{ $t('profile.continueShopping') }}
        </v-btn>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "VerificationPrompt",
  props: {
    isVerified: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<style scoped>
.verification-container {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
}
</style>