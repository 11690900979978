<template>
  <div>
    <v-row class="py-3">
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.customerCountry') }}:
          </h4>
          <span>{{ item.forOrder.customerLocale ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.taxRegion') }}:
          </h4>
          <span>{{ item.forOrder.taxRate._id }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.taxRate') }}:
          </h4>
          <span>{{ item.forOrder.taxRate.rate }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.publisher') }}:
          </h4>
          <span>{{ item.publisher }}</span>                        
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.basePrice') }}:
          </h4>
          <span>{{ item.basePrice }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.baseCurrency') }}:
          </h4>
          <span>{{ item.baseCurrency._id }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.pricePaid') }}:
          </h4>
          <span>{{ item.priceSet }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.quantity') }}:
          </h4>
          <span>{{ item.quantity }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.orderCurrency') }}:
          </h4>
          <span>{{ item.forOrder.currency._id }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.taxPaid') }}:
          </h4>
          <span>{{ (item.priceSet) - (item.preTaxPriceSet - item.discountSet) }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.couponUsed') }}:
          </h4>
          <span>{{ item.forOrder?.orderCoupon?.code ?? $t('common.none') }}</span>                        
        </div>
      </v-col>
      <v-col
        v-if="item.forOrder?.orderCoupon"
        class="col-auto"
      >
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('couponManager.discount') }}:
          </h4>
          <span>{{ item.discountSet ?? 0 }}</span>
        </div>
      </v-col>
      <v-col
        v-if="providerDetails.transactionId"
        class="col-auto"
      >
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('transactionReports.providerTransactionId', { provider: providerDetails.provider }) }}:
          </h4>
          <span>{{ providerDetails?.transactionId }}</span>
        </div>
      </v-col>
      <v-col
        v-if="item.forOrder?.event"
        class="col-auto"
      >
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('eventManager.event') }}:
          </h4>
          <span>{{ item.forOrder.event.name }}</span>
        </div>
      </v-col>
    </v-row>
    <transaction-report-fulfillment-details
      v-if="item?.forOrder?.orderFulfillment"
      class="my-3"
      :order="item?.forOrder"
    />
    <transaction-report-admin-order-details
      v-if="item?.forOrder?.adminOrder"
      :admin-order="item?.forOrder?.adminOrder"
    />
  </div>
</template>

<script>
import { getProviderDetails } from '@/utils/PaymentProviderHelper';
import TransactionReportAdminOrderDetails from './TransactionReportAdminOrderDetails';
import TransactionReportFulfillmentDetails from './TransactionReportFulfillmentDetails';

export default {
  name: 'TranasctionReportDetails',
  components: {
    TransactionReportAdminOrderDetails,
    TransactionReportFulfillmentDetails
},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    providerDetails() {
      return getProviderDetails(this.item.forOrder);
    }
  },
};
</script>