<template>
  <div class="form-container pa-6 pb-16 pb-sm-0">
    <ApolloMutation
      :mutation="gql => modifyCouponMutation"
      :variables="{
        input: {
          ...(!isNew && {id: existingCoupon.id}),
          code: coupon.code ?? '',
          description: coupon.description,
          discount: parseFloat(coupon.discount) ?? null,
          active: coupon.isActive,
          isPercentage: coupon.isPercentage,
          bookTag: coupon.bookTag,
          maxNumberOfItems: parseInt(coupon.maxItems) ?? null,
          maxNumberOfUses: parseInt(coupon.maxUses) ?? null,
          startDate: couponDates.startDate ?? null,
          endDate: couponDates.endDate ?? null,
          publisherId: coupon.forPublisher,
          bookId: coupon.forBook,
          bookTag: coupon.forTag ? String(coupon.forTag) : null,
          forUserId: coupon.forUser,
          baseCurrencyId: coupon.currency,
          maxUsesPerUser: parseInt(coupon.maxUsesPerUser) ?? null,
          fixedPrice: coupon.isFixedPrice,
          taxInclusive: coupon.taxInclusive,
          forBookIds: coupon.forBookIds?.length > 0 ? coupon.forBookIds : null,
          eventId: coupon.eventId,
        },
      }"
      :refetch-queries="() => [{
        query: refetchCouponsQuery,
      }]"
      @done="onDone"
    >
      <template #default="{ loading, mutate }">
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
          <v-form
            ref="createCouponForm"
          >
            <h3 class="mb-3">
              {{ $t('couponManager.couponDetails') }}
            </h3>
            <v-row>
              <v-col class="col-12 col-sm-6">
                <ekitabu-checkbox
                  id="coupon-active"
                  v-model="coupon.isActive"
                  :label="$t('couponManager.isActive')"
                />
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col class="col-12 col-sm-6">
                <ekitabu-text-input
                  id="coupon-code"
                  v-model="coupon.code"
                  :label="$t('couponManager.couponCode')"
                  :placeholder="$t('couponManager.couponCode')"
                  rules="max:50|min:3"
                />
              </v-col>
              <v-col class="col-12 col-sm-6">
                <ekitabu-text-area
                  id="coupon-description"
                  v-model="coupon.description"
                  :label="$t('couponManager.description')"
                  :placeholder="$t('couponManager.description')"
                  rules="required|max:50|min:3"
                  required
                  rows="1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-12 col-sm-6">
                <div>
                  <ekitabu-checkbox
                    id="coupon-fixed-price"
                    v-model="coupon.isFixedPrice"
                    class="mb-3"
                    :label="$t('couponManager.isFixedPrice')"
                  />
                  <ekitabu-checkbox
                    v-if="!coupon.isFixedPrice"
                    id="coupon-percentage"
                    v-model="coupon.isPercentage"
                    :label="$t('couponManager.isPercentage')"
                  />
                  <ekitabu-checkbox
                    v-if="coupon.isFixedPrice"
                    id="coupon-tax-inclusive"
                    v-model="coupon.taxInclusive"
                    :label="$t('couponManager.taxInclusive')"
                  />
                </div>
                <ekitabu-text-input
                  id="coupon-discount"
                  v-model="coupon.discount"
                  placeholder="0"
                  :label="coupon.isFixedPrice ? $t('couponManager.discountedPrice') : $t('couponManager.discount')"
                  type="number"
                  :rules="discountAmountRules"
                  required
                  :max="isPercentage ? 100 : null"
                  min="1"
                />
              </v-col>
              <v-col class="col-12 col-sm-6 mt-auto">
                <form-currency-select
                  v-if="!coupon.isPercentage"
                  id="coupon-currency"
                  v-model="coupon.currency"
                  rules="required"
                  required
                />
              </v-col>
            </v-row>
            <h3 class="mt-5 mb-3">
              {{ $t('couponManager.couponDates') }}
            </h3>
            <v-row>
              <v-col class="col-12 col-sm-6">
                <ekitabu-text-input
                  id="coupon-start-date"
                  v-model="coupon.startDate"
                  :label="$t('couponManager.startDate')"
                  type="date"
                  rules="required|before:@coupon-end-date"
                  required
                />
              </v-col>
              <v-col class="col-12 col-sm-6">
                <ekitabu-text-input
                  id="coupon-end-date"
                  v-model="coupon.endDate"
                  :label="$t('couponManager.endDate')"
                  type="date"
                  rules="required"
                  required
                />
              </v-col>
            </v-row>
            <h3 class="mt-5 mb-2">
              {{ $t('couponManager.couponRestrictions') }}
            </h3>
            <v-row>
              <v-col class="col-12 col-sm-6">
                <user-search
                  id="coupon-for-user"
                  v-model="coupon.forUser"
                  class="mb-3"
                />
                <ekitabu-text-input
                  id="coupon-max-uses-per-user"
                  v-model="coupon.maxUsesPerUser"
                  :use-checkbox="true"
                  :label="$t('couponManager.maxUsesPerUser')"
                  placeholder="1"
                  type="number"
                  class="mb-3"
                  rules="numeric|min_value:1"
                  min="1"
                />
                <ekitabu-text-input
                  id="coupon-max-uses"
                  v-model="coupon.maxUses"
                  :use-checkbox="true"
                  :label="$t('couponManager.maxUses')"
                  :placeholder="$t('common.none')"
                  type="number"
                  class="mb-3"
                  rules="numeric|min_value:1"
                  min="1"
                />
                <ekitabu-text-input
                  v-if="coupon.isPercentage"
                  id="coupon-max-items"
                  v-model="coupon.maxItems"
                  :use-checkbox="true"
                  :label="$t('couponManager.maxItems')"
                  :placeholder="$t('common.none')"
                  type="number"
                  class="mb-3"
                  rules="numeric|min_value:1"
                  min="1"
                />
                <ekitabu-event-select
                  v-model="coupon.eventId"
                />
              </v-col>
              <v-col class="col-12 col-sm-6">
                <div class="d-flex flex-column fill-height justify-space-between">
                  <v-radio-group
                    v-model="restrictionType"
                    :label="$t('couponManager.additionalRestrictions')"
                    class="restriction-radio-group mt-0 pt-0"
                  >
                    <v-radio
                      :label="$t('common.none')"
                      :value="couponRestrictions.NONE"
                      class="coupon-radio-button"
                    />
                    <v-radio
                      :label="$t('couponManager.forBook')"
                      :value="couponRestrictions.BOOK"
                      class="coupon-radio-button"
                    />
                    <v-radio
                      :label="$t('couponManager.forBooks')"
                      :value="couponRestrictions.BOOKS"
                      class="coupon-radio-button"
                    />
                    <v-radio
                      :label="$t('couponManager.forPublisher')"
                      :value="couponRestrictions.PUBLISHER"
                      class="coupon-radio-button"
                    />
                    <v-radio
                      :label="$t('couponManager.forTag')"
                      :value="couponRestrictions.TAG"
                      class="coupon-radio-button"
                    />
                  </v-radio-group>
                </div>
              </v-col>
            </v-row>
            <v-row class="mb-16">
              <v-col>
                <book-search
                  v-if="restrictionType === couponRestrictions.BOOK"
                  id="coupon-book"
                  v-model="coupon.forBook"
                  rules="required|min:3"
                  required
                  :label="$t('couponManager.forBook')"
                />
                <book-search
                  v-if="restrictionType === couponRestrictions.BOOKS"
                  id="coupon-books"
                  v-model="coupon.forBookIds"
                  multiple
                  rules="required|min:3"
                  required
                  :label="$t('couponManager.forBooks')"
                />
                <ekitabu-text-input
                  v-if="restrictionType === couponRestrictions.PUBLISHER"
                  id="coupon-publisher"
                  v-model="coupon.forPublisher"
                  :label="$t('couponManager.forPublisher')"
                  :placeholder="$t('couponManager.publisher')"
                  rules="required|min:3"
                  required
                />
                <tag-search
                  v-if="restrictionType === couponRestrictions.TAG"
                  id="coupon-bookTag"
                  v-model="coupon.forTag"
                  rules="required"
                  required
                />
              </v-col>
            </v-row>
            <v-btn
              tile
              depressed
              color="primary"
              class="floating-submit-button"
              :disabled="loading || invalid || (!isChanged && !isNew)"
              :loading="loading"
              @click="mutate"
            >
              {{ $t('common.submit') }}
            </v-btn>
          </v-form>
        </validation-observer>
      </template>
    </ApolloMutation>
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import CouponMutations from '@/graphql/CouponMutations';
import EkitabuCheckbox from '@/components/EkitabuCheckbox';
import { max, min, required, numeric, min_value, max_value } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import i18n from '@/i18n';
import { COUPON_RESTRICTIONS } from '@/utils/Const';
import EkitabuTextArea from '@/components/EkitabuTextArea';
import gql from 'graphql-tag';
import CouponFragments from '@/graphql/CouponFragments';
import UserSearch from '@/components/UserSearch';
import TagSearch from '@/components/TagSearch';
import BookSearch from '@/components/BookSearch';
import FormCurrencySelect from '@/components/coupons/FormCurrencySelect';
import EkitabuEventSelect from '@/components/inputs/EkitabuEventSelect.vue';

setInteractionMode('eager')

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', length: '{length}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', length: '{length}'}),
})

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

extend('numeric', {
  ...numeric,
  message: i18n.t('errors.number', {field: '{_field_}'}),
})

extend('min_value', {
  ...min_value,
  message: i18n.t('errors.minValue', {field: '{_field_}', value: '{min}'}),
})

extend('max_value', {
  ...max_value,
  message: i18n.t('errors.maxValue', {field: '{_field_}', value: '{max}'}),
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    if (!target) return true;
    return new Date(value) < new Date(target);
  },
  message: i18n.t('couponManager.dateError'),
});

const emptyCoupon = {
  code: null,
  description: null,
  isActive: true,
  discount: null,
  isPercentage: true,
  forPublisher: null,
  forTag: null,
  forBook: null,
  maxItems: null,
  maxUses: null,
  startDate: null,
  endDate: null,
  forUser: null,
  currency: null,
  maxUsesPerUser: null,
  isFixedPrice: false,
  taxInclusive: false,
  forBookIds: [],
  eventId: null,
};

export default {
  name: "ManageCouponForm",
  components: {
    EkitabuTextInput,
    EkitabuCheckbox,
    ValidationObserver,
    EkitabuTextArea,
    UserSearch,
    TagSearch,
    BookSearch,
    FormCurrencySelect,
    EkitabuEventSelect,
  },
  props: {
    existingCoupon: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      coupon: {...emptyCoupon},
      snackbar: false,
      restrictionType: COUPON_RESTRICTIONS.NONE,
      originalCouponData: null,
    }
  },
  computed: {
    modifyCouponMutation() {
      return this.isNew ? CouponMutations.createCoupon : CouponMutations.updateCoupon;
    },
    refetchCouponsQuery() {
      return gql`
        query GetCoupons {
          coupons {
            ...CouponDetails
          }
        }
        ${CouponFragments.couponDetailsFragment}
      `;
    },
    couponRestrictions() {
      return COUPON_RESTRICTIONS;
    },
    couponDates() {
      let dates = {};
      if (this.coupon.startDate) {
        dates.startDate = new Date(this.coupon.startDate.replaceAll("-", "/"));
      }
      if (this.coupon.endDate) {
        dates.endDate = new Date(this.coupon.endDate.replaceAll("-", "/"));
        dates.endDate.setDate(dates.endDate.getDate() + 1);
      }
      return dates;
    },
    isNew() {
      return !this.existingCoupon;
    },
    isChanged() {
      return JSON.stringify(this.coupon) !== JSON.stringify(this.originalCouponData);
    },
    isFixedPrice() {
      return this.coupon.isFixedPrice;
    },
    taxInclusive() {
      return this.coupon.taxInclusive;
    },
    isPercentage() {
      return this.coupon.isPercentage;
    },
    discountAmountRules() {
      if (this.isPercentage) {
        return "required|min_value:0|max_value:100";
      }
      return "required|min_value:0";
    }
  },
  watch: {
    existingCoupon(val) {
      if (!val) {
        this.coupon = {...emptyCoupon};
      }
      else {
        this.setCouponValues(val);
      }
    },
    isFixedPrice(val) {
      if (val) {
        this.coupon.isPercentage = false;
      }
      else {
        this.coupon.taxInclusive = false;
      }
    },
    isPercentage(val) {
      if (!val) {
        this.coupon.currency = null;
        this.coupon.maxItems = null;
      }
    },
    restrictionType(val) {
      this.coupon.forPublisher = null;
      this.coupon.forTag = null;
      this.coupon.forBook = null;
      this.coupon.forBookIds = [];
    }
  },
  created() {
      if (this.existingCoupon) {
        this.setCouponValues(this.existingCoupon);
      }
      else {
        this.coupon = {...emptyCoupon};
      }
  },
  methods: {
    onDone() {
      this.resetForm();
      this.$emit("input", true);
    },
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
      return publicationDate.toLocaleDateString('en-us', options);
    },
    setCouponValues(existingCoupon) {
        this.coupon.code = existingCoupon.code;
        this.coupon.description = existingCoupon.description;
        this.coupon.isActive = existingCoupon.active;
        this.coupon.discount = existingCoupon.discount;
        this.coupon.isPercentage = existingCoupon.isPercentage;
        this.coupon.forPublisher = existingCoupon.publisherId;
        this.coupon.forTag = existingCoupon.bookTag;
        this.coupon.forBook = existingCoupon.bookId;
        this.coupon.maxItems = existingCoupon.maxNumberOfItems;
        this.coupon.maxUses = existingCoupon.maxNumberOfUses;
        this.coupon.startDate = new Date(existingCoupon.startDate).toISOString().slice(0, 10);
        this.coupon.endDate = new Date(existingCoupon.endDate).toISOString().slice(0, 10);
        this.coupon.forUser = existingCoupon.forUserId;
        this.coupon.currency = existingCoupon.baseCurrency.id;
        this.coupon.maxUsesPerUser = existingCoupon.maxUsesPerUser;
        this.coupon.isFixedPrice = existingCoupon.fixedPrice;
        this.coupon.taxInclusive = existingCoupon.taxInclusive;
        this.coupon.forBookIds = existingCoupon.forBookIds;
        this.coupon.eventId = existingCoupon.event?.id;
        this.originalCouponData = {...this.coupon};

        if (this.coupon.forBook) {
          this.restrictionType = COUPON_RESTRICTIONS.BOOK;
        }
        else if (this.coupon.forBookIds) {
          this.restrictionType = COUPON_RESTRICTIONS.BOOKS;
        }
        else if (this.coupon.forPublisher) {
          this.restrictionType = COUPON_RESTRICTIONS.PUBLISHER;
        }
        else if (this.coupon.forTag) {
          this.restrictionType = COUPON_RESTRICTIONS.TAG;
        }
        else {
          this.restrictionType = COUPON_RESTRICTIONS.NONE;
        }
    },
    resetForm() {
      this.coupon = {...emptyCoupon};
      this.restrictionType = COUPON_RESTRICTIONS.NONE;
      this.$refs.observer.reset();
    },
  },
};
</script>

<style scoped>
:deep(.restriction-radio-group .theme--light.v-icon) {
  color: var(--v-secondaryText-base);
}

/* !important flags necessary to override vuetify !important flags on labels */
:deep(.restriction-radio-group legend) {
  color: var(--v-secondaryText-base) !important;
  font-weight: bold;
}

:deep(.coupon-radio-button label) {
  color: var(--v-secondaryText-base) !important;
}

.floating-submit-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.form-container {
  display: flex;
  flex-direction: column;
  max-height: 75vh;
  overflow-y: auto;
}

@media screen and (max-width: 599px) {
  .floating-submit-button {
    right: 20px;
    left: 20px;
    box-sizing: border-box;
  }
}
</style>