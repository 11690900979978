<template>
  <div>
    <success-snackbar
      v-model="snackbar"
      :message="$t('shop.addedToLibrary')"
    />
    <ApolloMutation
      :mutation="(gql) => addToLibraryMutation"
      :variables="{
        productId: productId,
        locale: countryCode,
      }"
      @done="onDone"
    >
      <template #default="{ mutate, loading }">
        <PrimaryButton
          v-bind="$attrs"
          :class="buttonClasses"
          :disabled="loading || queryLoading || !isLoggedIn || !isVerified"
          :loading="loading || queryLoading"
          @click="mutate"
        >
          {{
            isLoggedIn && isVerified ?
              $t("shop.addToLibrary") :
              isLoggedIn ? (shortText ? $t("shop.verifyShort") : $t("shop.verifyToAdd")) :
              (shortText ? $t("shop.loginShort") : $t("shop.loginToAdd"))
          }}
          <v-icon
            v-if="showIcon"
            size="15"
            class="ml-2 has-glow"
          >
            $bookshelf
          </v-icon>
        </PrimaryButton>
      </template>
    </ApolloMutation>
  </div>
</template>

<script>
import OrderQueries from "@/graphql/OrderQueries";
import SuccessSnackbar from "@/components/SuccessSnackbar.vue";
import BookQueries from "@/graphql/BookQueries";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";

export default {
  name: "AddToLibraryButton",
  components: {
    SuccessSnackbar,
    PrimaryButton,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
    queryLoading: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: "",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    refetchBookshelf: {
      type: Function,
      default: () => {},
    },
    shortText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  computed: {
    addToLibraryMutation() {
      return OrderQueries.addToLibrary;
    },
    refetchBookshelfQuery() {
      return () => [
        {
          query: BookQueries.getUserBookshelf,
          loadingKey: "bookshelfLoading",
        },
      ];
    },
    isLoggedIn() {
      return this.$auth.isAuthenticated;
    },
    isVerified() {
      return this.$auth.user.email_verified;
    },
    countryCode() {
      return this.$store.state.locale.countryCode;
    },
  },
  methods: {
    onDone() {
      this.refetchBookshelf();
      this.snackbar = true;
    },
  },
};
</script>
