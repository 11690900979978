<template>
  <div>
    <v-row class="py-3">
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.emailVerified') }}:
          </h4>
          <span>{{ item?.emailVerified != "" ? item.emailVerified : $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.familyName') }}:
          </h4>
          <span>{{ item?.familyName ? item.familyName : $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.givenName') }}:
          </h4>
          <span>{{ item?.givenName ? item.givenName : $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.nickname') }}:
          </h4>
          <span>{{ item?.nickname ? item.nickname : $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.phoneNumber') }}:
          </h4>
          <span>{{ item?.phoneNumber ? item.phoneNumber : $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.roles') }}:
          </h4>
          <span
            v-for="(role, index) in item?.roles"
            :key="index"
          >
            {{ $t('roles.'+role ) + (index < item.roles.length - 1 ? ', ' : '') }}
          </span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.signupCountry') }}:
          </h4>
          <span>{{ item?.signupCountry ?? $t('common.none') }}</span>
        </div>
      </v-col>
      <v-col class="col-auto">
        <div class="d-flex">
          <h4 class="mr-2">
            {{ $t('userDashboard.signupIp') }}:
          </h4>
          <span>{{ item?.signupIp ?? $t('common.none') }}</span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UserRowDetails',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>