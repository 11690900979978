<template>
  <v-alert
    v-if="error"
    color="error"
  >
    <v-row align="center">
      <v-col cols="auto">
        <v-icon color="textOnPrimary">
          mdi-alert
        </v-icon>
      </v-col>
      <v-col>
        <h3 class="error-text">
          {{ $te('errors.'+error.message) ? $t('errors.'+error.message) : error.message }}
        </h3>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
  export default {
    name: "ApolloErrorBar",
    props: {
      error: {
        type: Error,
        default: null,
      },
    },
  };
</script>

<style scoped>
  .error-text {
    color: var(--v-textOnError-base);
  }
</style>