<template>
  <div>
    <div class="d-flex">
      <div class="left-column">
        <v-skeleton-loader type="image" />
      </div>
      <div class="d-flex flex-grow-1 pa-0 py-sm-2 py-md-4 pl-3 pl-sm-5 pl-md-11">
        <v-skeleton-loader
          type="article"
          style="width: 100%; height: 100%"
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="left-column details-column pt-xs-5">
        <v-skeleton-loader
          type="article"
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="flex-grow-1 pl-sm-5 pl-md-11 pt-sm-0 px-0 pt-7">
        <v-row>
          <v-col
            v-for="n in loadingItemsCount"
            :key="n"
            class="col-4 col-md-3 col-lg-2"
          >
            <v-skeleton-loader
              type="card"
              style="width: 100%; height: 100%;"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookInfoLoader",
  props: {
    loadingItemsCount: {
      type: Number,
      default: 12,
    },
  },
}
</script>