import gql from "graphql-tag";

export default {
  createEvent: gql`
    mutation CreateEvent($input: createEventInput!) {
      createEvent(input: $input) {
        event {
          id
          _id
          name
          notes
          createdAt
          updatedAt
          taxRate {
            id
            _id
            rate
          }
          startDate
          endDate
          coupons {
            id
            code
          }
          books
        }
      }
    }
  `,

  updateEvent: gql`
    mutation UpdateEvent($input: updateEventInput!) {
      updateEvent(input: $input) {
        event {
          id
          _id
          name
          notes
          createdAt
          updatedAt
          taxRate {
            id
            _id
            rate
          }
          startDate
          endDate
          coupons {
            id
            code
          }
          books
        }
      }
    }
  `
}