<template>
  <v-container>
    <v-row justify="center">
      <v-col
        class="col-12 col-sm-8 pa-16"
        justify="center"
        align="center"
      >
        <book-list-icon :size="72" />
        <h1>{{ $t('shop.noBooksFound') }}</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookListIcon from "@/components/icons/BookListIcon";

export default {
  name: "EmptyBookList",
  components: {
    BookListIcon,
  },
};
</script>