<template>
  <v-container>
    <div class="header-border pb-1">
      <div class="d-flex align-center">
        <book-list-icon
          :size="24"
          color="var(--v-primary-base)"
          class="mr-3"
        />
        <h2>
          {{ $t('schoolBookLists.header') }}
        </h2>
      </div>
    </div>
    <div
      class="mb-2"
      style="height: 4px;"
    >
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <v-row>
      <v-col class="col-6">
        <h3>{{ $t('schoolBookLists.schools') }}</h3>
        <v-list
          class="school-booklists"
        >
          <v-list-item-group
            v-if="schools?.length > 0"
            v-model="selectedSchoolId"
          >
            <v-list-item
              v-for="school in schools"
              :key="school.id"
              :value="school._id"
              class="school-list-item mb-2"
              dark
            >
              {{ school.name }}
              <v-icon
                class="ml-auto"
                small
              >
                mdi-arrow-right 
              </v-icon>
            </v-list-item>
          </v-list-item-group>
          <v-list-item
            v-else
          >
            <v-list-item-title>
              {{ $t('schoolBookLists.noSchools') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col class="col-6">
        <h3>{{ $t('schoolBookLists.bookLists') }}</h3>
        <v-list
          class="school-booklists flex-grow-1 flex-shrink-0"
        >
          <v-list-item-group
            v-if="bookLists?.length > 0"
          >
            <v-list-item
              v-for="bookList in bookLists"
              :key="bookList.id"
              :value="bookList._id"
              class="school-list-item mb-2"
              dark
              @click="$router.push({ name: 'bookList', params: { id: bookList._id } })"
            >
              {{ bookList.name }}
              <v-icon
                class="ml-auto"
                small
              >
                mdi-arrow-right 
              </v-icon>
            </v-list-item>
          </v-list-item-group>
          <v-list-item
            v-else
          >
            <v-list-item-title>{{ selectedSchoolId ? $t('bookListManager.noBookLists') : $t('schoolBookLists.selectSchool') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import BookListIcon from "@/components/icons/BookListIcon";

export default {
  name: "SchoolBookListList",
  components: {
    BookListIcon,
  },
  data() {
    return {
      selectedSchoolId: null,
    };
  },
  apollo: {
    schools: {
      query: gql`
        query {
          schools {
            id
            _id
            name
          }
        }
      `,
    },
    bookLists: {
      query: gql`
        query getBookLists($schoolId: ID!) {
          getPublicBookLists(forSchool: $schoolId) {
            id
            _id
            name
          }
        }
      `,
      update(data) {
        return data?.getPublicBookLists;
      },
      variables() {
        return {
          schoolId: this.selectedSchoolId,
        };
      },
      skip() {
        return !this.selectedSchoolId;
      },
    }
  },
  watch: {
    selectedBookListId(val) {
      this.$emit("bookListSelected", val);
    },
  },
};
</script>

<style scoped>
.v-list-item.school-list-item {
  background-color: var(--v-secondary-base);
}

.v-list-item--active.school-list-item {
  background-color: var(--v-lightestAccent-base);
}
</style>