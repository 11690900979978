import i18n from "@/i18n";

export default {
  namespaced: false,
  state: () =>  ({
    languageCode: process.env.VUE_APP_I18N_LOCALE || "eng",
    countryCode: process.env.VUE_APP_I18N_DEFAULT_REGION || "KEN",
  }),
  getters: {
    languageCode: (state) => state.languageCode,
    countryCode: (state) => state.countryCode,
  },
  mutations: {
    setLanguageCode: (state, newCode) => state.languageCode = newCode,
    setCountryCode: (state, newCode) => state.countryCode = newCode,
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale;
      commit('setLanguageCode', newLocale);
    },
    changeCountry({ commit }, newCountry) {
      commit('setCountryCode', newCountry);
    },
  },
}