var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:_vm.containerClasses},[_c('p',[_vm._v(_vm._s(_vm.$t("checkout.freeOrderText")))])]),_c('ApolloMutation',{staticClass:"d-flex flex-grow-1",attrs:{"mutation":(gql) => _vm.placeFreeOrderMutation,"variables":{
      orderId: _vm.orderId,
    },"refetch-queries":() => [
        {
          query: _vm.getBookshelfQuery,
        },
      ]},on:{"done":_vm.onDone,"error":() => { _vm.loading = false; }},scopedSlots:_vm._u([{key:"default",fn:function({ mutate }){return [_c('PrimaryButton',_vm._b({staticClass:"text-uppercase",attrs:{"id":"submit","block":"","disabled":_vm.loading,"loading":_vm.loading},on:{"click":() => {
          _vm.loading = true;
          mutate()
        }}},'PrimaryButton',_vm.$attrs,false),[_vm._v(" "+_vm._s(_vm.$t("checkout.placeFreeOrder"))+" "),_c('v-icon',{staticClass:"ml-2 has-glow",attrs:{"x-small":""}},[_vm._v(" $triangleRight ")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }