<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t(id ? 'bookListManager.update' : 'bookListManager.create') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <ApolloMutation
      :mutation="gql => id ? updateBookListMutation : createBookListMutation"
      :variables="{input: {
        ...(id ? { id: id } : {}),
        name: name,
        notes: notes,
        books: books,
        forSchool: iriSchoolId,
        isPublic: isPublic,
      }}"
      :update="onDone"
    >
      <template #default="{ mutate, loading, error }">
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <validation-observer
          ref="observer"
          v-slot="{ valid }"
        >
          <div class="d-flex align-end">
            <EkitabuSchoolSelect
              v-model="forSchoolId"
              class="fill-width flex-grow-1 mr-3"
              :disabled="loading || $apollo.loading"
            />
            <div>
              <v-switch
                v-model="isPublic"
                class="flex-shrink-1 my-3"
                :label="$t('bookListManager.isPublic')"
                :disabled="loading || $apollo.loading"
                hide-details
              />
            </div>
          </div>
          <EkitabuTextInput
            id="booklist-name"
            v-model="name"
            class="fill-width mt-3"
            :label="$t('bookListManager.name')"
            :placeholder="$t('bookListManager.name')"
            rules="required|min:3"
            required
            :disabled="loading || $apollo.loading"
          />
          <EkitabuTextArea
            id="bookList-notes"
            v-model="notes"
            class="fill-width mt-3"
            rules="min:5|max:500"
            :label="$t('bookListManager.notes')"
            :placeholder="$t('bookListManager.notes')"
            :disabled="loading || $apollo.loading"
          />
          <BookSearch
            id="coupon-books"
            v-model="books"
            class="mt-3"
            multiple
            required
            :label="$t('bookListManager.books')"
          />
          <v-btn
            class="mt-3"
            tile
            color="primary"
            elevation="0"
            :disabled="!valid || loading || $apollo.loading || !isChanged"
            :loading="loading || $apollo.loading"
            block
            @click="mutate"
          >
            {{ id ? $t('bookListManager.update') : $t('bookListManager.create') }}
          </v-btn>
        </validation-observer>
      </template>
    </ApolloMutation>
    <SuccessSnackbar
      :value="showSnackbar"
      :message="id ? $t('bookListManager.updated') : $t('bookListManager.created')"
    />
  </div>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import EkitabuTextArea from '@/components/EkitabuTextArea';
import { required, min, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate'
import i18n from '@/i18n';
import gql from 'graphql-tag';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import BookListQueries from '@/graphql/BookListQueries';
import SuccessSnackbar from '@/components/SuccessSnackbar.vue';
import BookSearch from '@/components/BookSearch.vue';
import EkitabuSchoolSelect from '@/components/inputs/EkitabuSchoolSelect.vue';
import iris from '@/iris';

setInteractionMode('eager');

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', min: '{min}'}),
})

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', max: '{max}'}),
})

function initialState() {
  return {
    forSchoolId: null,
    name: '',
    notes: '',
    books: [],
    isPublic: false,
    showSnackbar: false,
  };
}

export default {
  name: "ManageBookListForm",
  components: {
    EkitabuTextInput,
    EkitabuTextArea,
    ValidationObserver,
    ApolloErrorAlert,
    SuccessSnackbar,
    BookSearch,
    EkitabuSchoolSelect,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return initialState();
  },
  apollo: {
    bookList: {
      query: gql`
        query BookList($id: ID!) {
          bookList(id: $id) {
            id
            name
            isPublic
            notes
            forSchool {
              id
              _id
              name
            }
            bookListItems {
              id
              productId
              name
            }
            archivedAt
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      result({ data }) {
        this.name = data.bookList.name;
        this.forSchoolId = data.bookList?.forSchool?._id;
        this.notes = data.bookList.notes;
        this.books = data.bookList.bookListItems.map(item => item.productId);
        this.isPublic = data.bookList.isPublic;
      },
    },
  },
  computed: {
    iriSchoolId() {
      return iris.school+this.forSchoolId;
    },
    createBookListMutation() {
      return BookListQueries.createBookList;
    },
    updateBookListMutation() {
      return BookListQueries.updateBookList;
    },
    existingBookListItems() {
      return this.bookList?.bookListItems.map(item => item.productId);
    },
    booksChanged() {
      return JSON.stringify(this.existingBookListItems) !== JSON.stringify(this.books);
    },
    isChanged() {
      if (!this.bookList) {
        return true;
      }
      else {
        return(
          this.name !== this.bookList.name ||
          this.notes !== this.bookList.notes ||
          this.booksChanged ||
          this.forSchoolId !== this.bookList.forSchool?._id ||
          this.isPublic !== this.bookList.isPublic
        );
      }
    },
  },
  watch: {
    id(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
  methods: {
    resetForm() {
      Object.assign(this.$data, initialState());
    },
    onDone(cache, { data }) {
      if (data?.createBookList?.bookList) {
        cache.modify({
          fields: {
            bookLists(existingBookLists) {
              return [...existingBookLists, data.createBookList.bookList];
            },
            getSchoolBookLists(existingSchoolBookLists) {
              return [...existingSchoolBookLists, data.createBookList.bookList];
            },
          },
        });

        this.resetForm();
      }

      this.$refs.observer.reset();
      this.showSnackbar = true;
    },
  },
}
</script>