import { getInstance } from './index';
import { createProvider } from '@/apollo/vue-apollo';
import UserMutations from '@/graphql/UserMutations';
import { USER_ROLES } from '@/utils/Const';

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = async () => {
    // If the user is authenticated and verified, continue with the route
    // Else if logged in and not verified redirect to verification pending
    const claims = await authService.getIdTokenClaims();
    if (authService.isAuthenticated && claims.email_verified) {
      return next();
    } 
    else if (authService.isAuthenticated) {
      if (to.name != "userProfile" && to.name != "editUserProfile" && to.name != "verificationPending") {
        return next({ name: 'verificationPending' });
      } else {
        return next();
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};

export const authAdminRoleGuard = (to, from, next) => {
  const apolloProvider = createProvider();
  const authService = getInstance();

  const fn = async () => {
    if (authService.isAuthenticated && authService.user.email_verified) {
      const { data: { getWebServiceUser: { roles } } } = await apolloProvider.defaultClient.query({
        query: UserMutations.getUserRoles
      });

      if (roles.find((role) => role === USER_ROLES.ADMIN)) return next();

      return next({ name: 'home' });
    } 
    else if (authService.isAuthenticated) {
      if (to.name != "userProfile" && to.name != "editUserProfile") {
        return next({ name: 'verificationPending' });
      } else {
        return next();
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
};