<template>
  <div>
    <event-header
      :title="$route.params.orderId"
      :event-id="eventId"
      :has-date-filter="false"
      :to-previous="{ name: 'eventDashboard' }"
    />
    <v-skeleton-loader
      v-if="!event"
      type="image"
      width="100%"
      height="500px"
      tile
    />
    <orders-table
      v-else
      :event-id="eventId"
      :confirmed="true"
      :from-page="fromRoutes.event"
    />
  </div>
</template>

<script>
import iris from "@/iris";
import gql from "graphql-tag";
import { getProviderDetails } from "@/utils/PaymentProviderHelper";
import OrdersTable from "@/components/tables/OrdersTable.vue";
import EventHeader from "@/components/EventHeader.vue";
import { FROM_ROUTES } from "@/utils/Const";

export default {
  name: "EventOverview",
  components: {
    EventHeader,
    OrdersTable,
  },
  apollo: {
    event: {
      query: gql`
        query getOrder($eventId: ID!) {
          event(id: $eventId) {
            id
            _id
            name
            startDate
            endDate
            taxRate {
              id
              _id
              rate
            }
            orders {
              totalCount
            }
          }
        }
      `,
      variables() {
        return {
          eventId: this.eventId,
        };
      },
    },
  },
  computed: {
    fromRoutes() {
      return FROM_ROUTES;
    },
    eventId() {
      return iris.event+this.$route.params.id;
    },
    eventName() {
      return this.$route.params.eventName;
    }
  },
  methods: {
    getProviderDetails,
  },
};
</script>
