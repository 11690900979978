<template>
  <v-btn
    v-bind="$attrs"
    class="subtitle-2 font-weight-bold px-3"
    color="tertiary"
    text
    tile
    :height="height"
    @click="handleClick"
  >
    <v-icon
      size="14"
      class="gradient-text mr-1"
      style="transform: rotate(-90deg);"
    >
      mdi-triangle
    </v-icon>
    <span class="gradient-text">
      {{ text }}
    </span>
  </v-btn>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: "ContinueShopping",
  props: {
    handleClick: {
      type: Function,
      default: () => {
        this.$router.go(-1);
      },
    },
    height: {
      type: String,
      default: "30px",
    },
    text: {
      type: String,
      default: i18n.t("shop.continueShopping"),
    },
  },
}
</script>

<style scoped lang="scss">
.gradient-text {
  background: $tertiary-gradient;
}
</style>