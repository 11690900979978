<template>
  <ApolloMutation
    :mutation="(gql) => updateOrderFulfillmentMutation"
    :variables="{
      input: {
        id: id,
        fulfilledAt: new Date(fulfilledAt),
      },
    }"
    :update="updateCache"
  >
    <template #default="{ loading, mutate, error }">
      <h3>
        {{ $t('transactionReports.fulfillmentDetails') }}
      </h3>
      <apollo-error-alert
        v-if="error"
        :error="error"
      />
      <validation-observer
        ref="observer"
        v-slot="{ passed }"
      >
        <EkitabuTextInput
          id="fulfilled-at-date"
          v-model="fulfilledAt"
          class="fill-width"
          :label="$t('transactionReports.fulfillmentDate')"
          type="datetime-local"
          rules="required"
          :max="truncateToDateTime()"
          required
        />
        <h5>{{ $t('common.timeZone') }}: {{ currentTimeZone }}</h5>
        <v-btn
          class="mt-2"
          tile
          color="primary"
          elevation="0"
          :disabled="!passed || loading || !isChanged"
          :loading="loading"
          block
          @click="mutate"
        >
          {{ $t('common.update') }}
        </v-btn>
        <h4>{{ $t('transactionReports.updateFulfillmentText') }}</h4>
      </validation-observer>
    </template>
  </ApolloMutation>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'
import i18n from '@/i18n';
import gql from 'graphql-tag';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import DateTimeMixin from '@/mixins/DateTimeMixin';

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

export default {
  name: "UpdateOrderFulfillmentDate",
  components: {
    EkitabuTextInput,
    ValidationObserver,
    ApolloErrorAlert,
  },
  mixins: [DateTimeMixin],
  props: {
    id: {
      type: String,
      default: null,
    },
    currentFulfilledAt: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      fulfilledAt: this.currentFulfilledAt ? this.truncateToDateTime(this.currentFulfilledAt) : '',
    };
  },
  computed: {
    updateOrderFulfillmentMutation() {
      return gql`
        mutation updateOrderFulfillment($input: updateOrderFulfillmentInput!) {
          updateOrderFulfillment(input: $input) {
            orderFulfillment {
              id
              fulfilledAt
            }
          }
        }
      `;
    },
    isChanged() {
      return this.fulfilledAt !== this.truncateToDateTime(this.currentFulfilledAt);
    },
  },
  methods: {
    updateCache(cache, { data: { updateOrderFulfillment } }) {
      cache.modify({
        id: cache.identify(updateOrderFulfillment.orderFulfillment),
        fields: {
          fulfilledAt() {
            return updateOrderFulfillment.orderFulfillment.fulfilledAt;
          },
        },
      });
    },
  },
}
</script>