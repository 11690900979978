<template>
  <v-btn
    text
    depressed
    color="transparent"
    class="pa-0"
    @click="openCart"
  >
    <v-badge
      :content="cartItemCount"
      :value="orderId && cartItemCount > 0"
      color="primary"
      overlap
      offset-x="20"
    >
      <v-icon
        style="padding-right: 10px; padding-left: 10px"
        color="primaryText"
      >
        $cart
      </v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "CartBadge",
  props: {
    openCart: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      cartItemCount: 0,
    };
  },
  apollo: {
    order: {
      query: gql`
        query getCartItems($orderId: ID!) {
          order(id: $orderId) {
            id
            orderLines(first: 1000) {
              edges {
                node {
                  id
                }
              }
            }
            confirmed
          }
        }
      `,
      variables() {
        return {
          orderId: this.orderId,
        };
      },
      skip() {
        return !this.orderId;
      },
      update(data) {
        if (data.order) {
          this.cartItemCount = data.order.orderLines.edges.length;
        }

        return data;
      },
    },
  },
  computed: {
    orderId() {
      return this.$store.state.cart.currentCart;
    },
  },
};
</script>
