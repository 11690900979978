<template>
  <ApolloQuery
    :query="(gql) => searchBooksQuery"
    :variables="{
      page: 1,
      searchText: searchText,
    }"
    :skip="searchText.length < 3"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <apollo-error-alert
        :error="error"
      />
      <search-autocomplete
        :id="id"
        v-model="selected"
        :rules="rules"
        :items="data?.books?.collection ?? []"
        :label="label ?? ''"
        :placeholder="$t('couponManager.searchBooks')"
        item-value="_id"
        :select-text="selectText"
        :loading="!!isLoading"
        :filter="customFilter"
        :multiple="multiple"
        chips
        small-chips
        @searchChanged="onSearchChanged"
      />
      <div
        v-if="selected && selected?.length > 0"
      >
        <h4>{{ multiple ? $t('couponManager.selectedBooks') : $t('couponManager.currentBook') }}</h4>
        <span>
          {{ formattedSelected }}
        </span>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SearchAutocomplete from "@/components/SearchAutocomplete";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import gql from "graphql-tag";

export default {
  name: "BookSearch",
  components: {
    SearchAutocomplete,
    ApolloErrorAlert,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      searchText: "",
      selected: this.value,
    };
  },
  computed: {
    searchBooksQuery() {
      return gql` query searchProducts($page: Int! $searchText: String) {
        books(page: $page, search: $searchText) {
          collection {
            id
            _id
            title
          }
        }
      }`;
    },
    formattedSelected() {
      if (this.selected == null) return "";

      if (this.multiple) {
        return this.selected.join(", ");
      }

      return this.selected;
    },
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selected = val;
    },
  },
  methods: {
    onSearchChanged(searchText) {
      this.searchText = searchText;
    },
    customFilter (item, queryText, itemText) {
      if (this.searchText == null || this.searchText == "" || this.searchText.length < 3) return false;

      const text = item.title.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text.indexOf(searchText) > -1
    },
    selectText(item) {
      return item.title;
    }
  },
};
</script>