import "regenerator-runtime";
import '@/styles/app.scss';
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router';
import i18n from './i18n';
import store from '@/store';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { createProvider, onLogin, onLogout } from '@/apollo/vue-apollo';
import { Auth0Plugin, getInstance } from "./auth";
import 'vue-tel-input/dist/vue-tel-input.css';

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import '@/mixins/currencyMixins';

import { Loader } from "@googlemaps/js-api-loader";

// setup google maps api
const loader = new Loader({
  apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  version: "weekly",
});

loader.load().then(async () => {
  const { Map } = await google.maps.importLibrary("places");
});

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const audience = process.env.VUE_APP_AUTH0_AUDIENCE;

const apolloProvider = createProvider();

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  responseType: 'token id_token',
  audience: audience,
  scope: 'openid profile email',
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

const instance = getInstance();
instance.$watch("loading", async loading => {
  if (!loading && instance.isAuthenticated) {
    const token = await instance.getTokenSilently();
    onLogin(apolloProvider.defaultClient, token, instance.user.sub);
  } else {
    onLogout(apolloProvider.defaultClient);
  }
});

Vue.config.productionTip = false

export default new Vue({
  vuetify,
  router,
  i18n,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')
