<template>
  <div @click="login">
    <slot />
  </div>
</template>

<script>
export default {
  name: "LoginLink",
  props: {
    signUp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    redirectUrl() {
      if(this.signUp) {
        return '/verification-pending';
      }

      return this.$route.fullPath;
    },
  },
  methods: {
    async login() {
      await this.$auth.loginWithRedirect({
        screen_hint: this.signUp ? 'signup' : '',
        appState: {
          targetUrl: this.redirectUrl,
        },
      });
    },
  },
};
</script>