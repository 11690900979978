export function truncateText(text, occurrenceIndex) {
  var index, matches, _i, _len, char;
  matches = 0;
  index = 0;
  let charToMatch = " ";

  for (_i = 0, _len = text.length; _i < _len; _i++) {
    char = text[_i];
    if (char === charToMatch) {
      matches += 1;
      if (matches === occurrenceIndex) {
        return text.substring(0, index - 1);
      }
    }
    index += 1;
  }
  
  return null;
}

export function containsString(text, searchText) {
  return text.toLowerCase().includes(searchText.toLowerCase());
}