<template>
  <ApolloQuery
    :query="(gql) => bookListsQuery"
    :update="updateData"
  >
    <template #default="{ result: { error }, isLoading }">
      <validation-observer
        ref="observer"
        v-slot="{ passed }"
      >
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <ekitabu-select
          id="book-list-select"
          v-model="selectedBookList"
          :items="[emptyItem, ...bookLists]"
          item-text="name"
          item-value="id"
          :label="$t('bookList.newList')"
          class="book-list-select full-width mb-3"
          :loading="!!isLoading"
          :disabled="!!isLoading"
        />
        <div v-if="newList">
          <ekitabu-text-input
            id="list-name-Input"
            v-model="newListTitle"
            class="full-width"
            required
            :label="$t('bookList.newListName')"
            :placeholder="$t('bookList.newListName')"
            rules="required|max:50|min:3"
          />
        </div>
        <modify-book-list-button
          :new-list-title="newListTitle"
          :selected-book-list="selectedBookList"
          :is-new-list="newList"
          :book-id="bookId"
          :invalid="!passed"
          :book-lists="bookLists"
          @updateSelectedList="(event) => selectedBookList = event"
          @onCompleted="onCompleted"
        />
      </validation-observer>
    </template>
  </ApolloQuery>
</template>

<script>
import BookListQueries from "@/graphql/BookListQueries";
import ApolloErrorAlert from '@/components/ApolloErrorAlert';
import EkitabuTextInput from '@/components/EkitabuTextInput';
import ModifyBookListButton from '@/components/ModifyBookListButton';
import { required, max, min } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import i18n from '@/i18n';
import EkitabuSelect from '@/components/EkitabuSelect';

setInteractionMode('eager')

extend("required", {
  ...required,
  message: i18n.t("errors.required", { field: "{_field_}" }),
});

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', length: '{length}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', length: '{length}'}),
})

export default {
  name: "AddToBookListModal",
  components: { 
    ApolloErrorAlert,
    EkitabuTextInput,
    ValidationObserver,
    ModifyBookListButton,
    EkitabuSelect,
  },
  props: {
    bookId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selectedBookList: null,
      bookLists: [],
      newListTitle: null,
      emptyItem: {
        name: this.$t('bookList.newList'),
        id: null,
      },
    }
  },
  computed: {
    bookListsQuery() {
      return BookListQueries.getUserBookLists;
    },
    newList() {
      if (!this.selectedBookList) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  watch: {
    bookLists() {
      if (!this.selectedBookList && this.bookLists.length > 0) {
        this.selectedBookList = this.bookLists.find((list) => list)?.id;
      }
    },
  },
  methods: {
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    updateData(data) {
      if (data) {
        this.bookLists = data.getMyBookLists;
      }
      else {
        this.bookLists = [];
      }
      return data;
    },
    resetValidation() {
      this.newListTitle = "";
      this.$refs.observer.reset();
    },
    onCompleted(event) {
      this.resetValidation();
      this.$emit('onCompleted', event);
    }
  },
}
</script>

<style scoped>
.book-list-select.v-text-field {
  border-radius: 0px;
}

.full-width {
  max-width: 100%;
}
</style>