<template>
  <div>
    <data-table-header
      :title="$t('admin.eventDashboard')"
      :search-placeholder="$t('event.name')"
      :search-label="$t('event.searchName')"
      :date-label="$t('event.startDate')"
      :has-search="true"
      @startDate="($event) => (startDate = $event)"
      @endDate="($event) => (endDate = $event)"
      @searchString="($event) => (searchString = $event)"
    />
    <events-data-table
      :start-date="startDate"
      :end-date="endDate"
      :search-string="searchString"
    />
  </div>
</template>

<script>
import DataTableHeader from "@/components/tables/DataTableHeader.vue";
import EventsDataTable from "@/components/tables/EventsDataTable.vue";

export default {
  name: "EventDashboard",
  components: {
    DataTableHeader,
    EventsDataTable,
  },
  data() {
    return {
      startDate: "",
      endDate: "",
      searchString: "",
    };
  },
};
</script>
