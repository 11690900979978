<template>
  <div class="mb-3">
    <h4 class="mb-1">
      {{ $t('transactionReports.adminOrder') }}
    </h4>
    <table  
      class="admin-order-table"
    >
      <thead>
        <tr>
          <th>{{ $t('transactionReports.adminOrderId') }}</th>
          <th>{{ $t('transactionReports.forName') }}</th>
          <th>{{ $t('transactionReports.forPhone') }}</th>
          <th>{{ $t('transactionReports.forEmail') }}</th>
          <th>{{ $t('transactionReports.claimedAt') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ adminOrder?._id }}</td>
          <td>{{ adminOrder?.forUserFullName }}</td>
          <td>{{ adminOrder?.forUserPhone }}</td>
          <td>{{ adminOrder?.forUserEmail }}</td>
          <td>{{ adminOrder?.claimedAt ? formatDate(adminOrder?.claimedAt) : $t('transactionReports.notClaimed') }}</td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>{{ $t('transactionReports.adminId') }}</th>
          <th>{{ $t('transactionReports.adminName') }}</th>
          <th>{{ $t('transactionReports.adminEmail') }}</th>
          <th colspan="2">
            {{ $t('transactionReports.createdAt') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ adminOrder?.adminUserId }}</td>
          <td>{{ adminOrder?.adminFullName }}</td>
          <td>{{ adminOrder?.adminEmail }}</td>
          <td colspan="2">
            {{ formatDate(adminOrder?.createdAt) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import OrderQueries from '@/graphql/OrderQueries';
import { formatDate } from '@/helpers/generalFunctions.js';

export default {
  name: "TransactionReportAdminOrderDetails",
  props: {
    adminOrder: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      orderByDate: "DESC",
      confirmed: true,
    };
  },
  computed: {
    userOrderQuery() {
      return OrderQueries.getUserOrders;
    },
  },
  methods: {
    formatDate,
    updateData(data) {
      return data?.getUserOrders?.find((order) => order);
    }
  },
};
</script>