<template>
  <div class="footer-option-container">
    <span class="footer-option-label">{{ $t("common.language") }}:</span>
    <v-select
      v-model="lang"
      :items="languages"
      item-text="name"
      item-value="code"
      class="footer-option-select"
    />
  </div>
</template>

<script>
import { languages } from "@/utils/Const.js";

export default {
  data() {
    return {
      languages,
    };
  },
  computed: {
    lang: {
      get: function () {
        return this.$store.state.locale.languageCode;
      },
      set: function (newVal) {
        this.$store.dispatch("changeLocale", newVal);
      },
    },
  },
};
</script>
