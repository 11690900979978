<template>
  <v-dialog
    :value="dialog"
  >
    <v-card
      tile
    >
      <v-card-title>
        <h4>
          {{ $t('bookInfo.loginToView') }}
        </h4>
      </v-card-title>
      <v-card-text
        class="py-3"
      >
        {{ $t('bookInfo.loginPromptBody') }}
      </v-card-text>
      <v-card-actions>
        <login-link>
          <v-btn
            class="mr-3"
            color="primary"
            tile
            depressed
          >
            {{ $t("menu.login") }}
          </v-btn>
        </login-link>
        <v-btn
          color="secondary"
          tile
          depressed
          @click="closeDialog()"
        >
          {{ $t('button.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoginLink from '@/components/LoginLink';
export default {
  name: "LoginToViewModal",
  components: { 
    LoginLink,
  },
  props: {
    shouldShow: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      dialog: !this.$auth.isAuthenticated && this.shouldShow,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
:deep(.v-dialog.v-dialog--active) {
  border-radius: 0px;
  max-width: 320px;
}
</style>