import UserMutations from '@/graphql/UserMutations';
import { USER_ROLES } from '@/utils/Const';

export default {
  name: 'AdminLink',
  data() {
    return {
      roles: [],
    };
  },
  apollo: {
    getWebServiceUser: {
      query: UserMutations.getUserRoles,
      skip() {
        return !this.$auth.isAuthenticated || !this.getMe;
      },
      result({ data }) {
        if (data?.getWebServiceUser) {
          this.roles = data.getWebServiceUser.roles;
        }
      },
    },
  },
  computed: {
    getMe() {
      return this.$store.state.auth.getMe;
    },
    getUserRolesQuery() {
      return UserMutations.getUserRoles;
    },
    isAdmin() {
      if (!this.$apollo.queries.getWebServiceUser.loading &&
          this.roles &&
          this.roles.find((role) => role === USER_ROLES.ADMIN)
      ) {
        return true;
      }
      return false;
    },
    isChecker() {
      if (!this.$apollo.queries.getWebServiceUser.loading &&
          this.roles &&
          this.roles.find((role) => role === USER_ROLES.CHECKER)
      ) {
        return true;
      }
      return false;
    }
  },
}