<template>
  <v-snackbar
    v-model="snackbar"
    tile
    color="success"
    :timeout="3000"
    class="pa-15 font-weight-bold"
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-icon>mdi-check-circle-outline</v-icon>
      <div
        v-if="message"
        style="margin-left: 10px;"
      >
        {{ message }}
      </div>      
    </v-row>
  </v-snackbar>
</template>

<script>
  export default {
    props: {
      message: {
        type: String,
        default: "",
      },
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        snackbar: this.value,
      }
    },
    watch: {
      value(val) {
        this.snackbar = val;
      },
      snackbar(val) {
        this.$emit("input", val);
      },
    },
  }
</script>