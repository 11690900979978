<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="400px"
    >
      <template #activator="{ on }">
        <v-btn
          class="text px-4"
          color="warning"
          text
          tile
          height="30"
          :disabled="isDisabled"
          v-on="on"
        >
          <span class="gradient-text font-weight-bold">
            {{ $t("checkout.clearCart") }}
          </span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="headline">
          {{ $t("checkout.clearCart") }}
        </v-card-title>
        <v-card-text>
          <h3 class="mt-3">
            {{ $t("checkout.clearCartConfirmation") }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            tile
            @click="dialog = false"
          >
            {{ $t("button.cancel") }}
          </v-btn>
          <ApolloMutation
            :mutation="(gql) => removeAllItemsMutation"
            :variables="{
              id: orderId,
            }"
            :update="updateCache"
          >
            <template #default="{ mutate, loading }">
              <v-btn
                color="primary"
                tile
                class="ml-3"
                elevation="0"
                :loading="loading"
                :disabled="loading || isDisabled"
                @click="mutate"
              >
                {{ $t("button.accept") }}
              </v-btn>
            </template>
          </ApolloMutation>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrderQueries from '@/graphql/OrderQueries';

export default {
  name: "EmptyCartButton",
  components: {},
  props: {
    orderId: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    removeAllItemsMutation() {
      return OrderQueries.deleteAllOrderLines;
    },
  },
  methods: {
    updateCache(cache, result) {
      // remove orderlines in order from cache
      let data = cache.readQuery({
        query: OrderQueries.getOrder,
        variables: {orderId: this.orderId},
      });

      const newOrderLineEdges = { ...data.order.orderLines, edges: [] };

      data = {
        order: {
          ...data.order,
          orderLines: newOrderLineEdges,
        }
      }

      cache.writeQuery({
        query: OrderQueries.getOrder,
        variables: {orderId: this.orderId},
        data,
      })
      this.dialog = false;
      this.$emit("onRemoved", this.$t('checkout.cartCleared'));
    }
  },
};
</script>

<style scoped lang="scss">
.gradient-text {
  background: $warning-gradient;
  border-bottom: 1px solid var(--v-warning-base);
}
</style>