export default {
  namespaced: false,
  state: () =>  ({
    getMe: null,
  }),
  getters: {
    getMe: (state) => state.getMe,
  },
  mutations: {
    setGetMe: (state, newGetMe) => state.getMe = newGetMe,
  },
  actions: {
    onLogin({dispatch}, apolloClient) {
      dispatch("handleCartOnLogin", apolloClient);
    }
  }
}
