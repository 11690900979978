<template>
  <div>
    <div
      class="justify-content-between divider-heading edit-header-container mt-7 mb-2 pb-3"
    >
      <h1 class="edit-header">
        {{ $t("profile.changePassword") }}
      </h1>
    </div>
    <ApolloMutation
      v-if="!isSocialLoginUser"
      :mutation="(gql) => resetPasswordMutation"
      @done="onDone"
    >
      <template #default="{ mutate, loading, error }">
        <h4>{{ $t("profile.resetPasswordText") }}</h4>
        <v-btn
          color="primary"
          class="mt-3"
          tile
          :loading="loading"
          :disabled="loading"
          elevation="0"
          @click="mutate"
        >
          {{ $t("profile.resetPasswordButton") }}
        </v-btn>
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
      </template>
    </ApolloMutation>
    <h4 v-else>
      {{ $t("profile.socialLoginPassword") }}
    </h4>
  </div>
</template>

<script>
import UserMutations from "@/graphql/UserMutations";
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import { LOGIN_PROVIDERS } from "@/utils/Const";

export default {
  name: "ChangePasswordForm",
  components: {
    ApolloErrorAlert,
  },
  computed: {
    resetPasswordMutation() {
      return UserMutations.resetPassword;
    },
    isSocialLoginUser() {
      const id = this.$auth.user.sub.toString();
      let isSocialProvider = false;

      LOGIN_PROVIDERS.forEach((provider) => {
        if (id.includes(provider)) {
          isSocialProvider = true;
          return;
        }
      })

      return isSocialProvider;
    },
  },
  methods: {
    onDone({
      data: {
        resetPasswordRequestWebServiceUser: { webServiceUser },
      },
    }) {
      window.open(webServiceUser.resetPasswordLink);
    },
  },
};
</script>
