<template>
  <ApolloQuery
    :query="(gql) => searchUsersQuery"
    :variables="{ search: searchText }"
    :skip="searchText.length < 3"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <apollo-error-alert
        :error="error"
      />
      <search-autocomplete
        :id="id"
        v-model="selected"
        :rules="rules"
        :items="data?.searchWebServiceUsers?.collection ?? []"
        :label="$t('couponManager.forUser')"
        :placeholder="$t('admin.searchUsers')"
        :use-checkbox="true"
        item-value="userId"
        :select-text="selectText"
        :loading="!!isLoading"
        :filter="customFilter"
        @searchChanged="onSearchChanged"
      />
      <div
        v-if="selected"
      >
        <h4>{{ $t('couponManager.currentUser') }}</h4>
        <span>{{ selected }}</span>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SearchAutocomplete from "@/components/SearchAutocomplete";
import UserMutations from "@/graphql/UserMutations";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";

export default {
  name: "UserSearch",
  components: {
    SearchAutocomplete,
    ApolloErrorAlert,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
      selected: this.value,
    };
  },
  computed: {
    searchUsersQuery() {
      return UserMutations.searchUsers;
    },
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selected = val;
    },
  },
  methods: {
    onSearchChanged(searchText) {
      this.searchText = searchText;
    },
    customFilter (item, queryText, itemText) {
      if (this.searchText == null || this.searchText == "" || this.searchText.length < 3) return false;

      const textOne = item.givenName.toLowerCase();
      const textTwo = item.familyName.toLowerCase();
      const textThree = item.email.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1;
    },
    selectText(item) {
      return this.$t('common.fullName', { firstname: item.givenName, lastname: item.familyName }) + " (" + item.email + ")";
    }
  },
};
</script>