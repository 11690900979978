<template>
  <div>
    <v-alert
      color="accent"
      icon="mdi-alert"
      class="mt-6"
    >
      {{ $t('checkout.delivery.printBookOrder') }}
    </v-alert>
    <h4
      class="mt-4"
    >
      {{ $t('checkout.delivery.shippingDetails') }}
    </h4>
    <v-radio-group
      v-model="deliveryOption"
      class="align-start"
      :row="false"
      hide-details
    >
      <div>
        <v-radio
          class="mb-2"
          :label="$t('checkout.delivery.deliverToAddress')"
          :value="deliveryOptions.deliverToAddress"
        />
      </div>
      <div>
        <v-radio
          class="mb-2"
          :label="$t('checkout.delivery.deliverToSchool')"
          :value="deliveryOptions.deliverToSchool"
        />
      </div>
      <div>
        <v-radio
          :label="$t('checkout.delivery.collectFromOffice')"
          :value="deliveryOptions.collectFromOffice"
        />
      </div>
    </v-radio-group>
    <p class="mt-2">
      {{ $t('checkout.delivery.expected') }}
    </p>
    <div>
      <address-fields
        v-if="deliveryOption == deliveryOptions.deliverToAddress"
        :fields-required="true"
        @updateShippingDetails="setShippingInfo"
      />
      <deliver-to-school
        v-else-if="deliveryOption == deliveryOptions.deliverToSchool"
        @updateSchoolData="setSchoolInfo"
      />
      <pickup-from-office
        v-else-if="deliveryOption == deliveryOptions.collectFromOffice"
      />
    </div>
  </div>
</template>

<script>
import DeliverToSchool from "@/views/checkout/print/DeliverToSchool";
import PickupFromOffice from "@/views/checkout/print/PickupFromOffice";
import AddressFields from "@/views/checkout/print/AddressFields.vue";

const DELIVERY_OPTIONS = {
  deliverToAddress: 'deliverToAddress',
  deliverToSchool: 'deliverToSchool',
  collectFromOffice: 'collectFromOffice',
}

export default {
  name: "DeliveryOptions",
  components: {
    DeliverToSchool,
    PickupFromOffice,
    AddressFields,
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deliveryOption: DELIVERY_OPTIONS.deliverToAddress,
      shippingInfo: {
        placeId: null,
        addressDetails: null,
        altPhoneNumber: null,
      },
      schoolInfo: {
        schoolId: null,
        studentName: null,
        studentClass: null,
      },
    }
  },
  computed: {
    deliveryOptions() {
      return DELIVERY_OPTIONS;
    },
    isPickupOrder() {
      return this.deliveryOption == this.deliveryOptions.collectFromOffice;
    },
    validPlaceId() {
      if (this.deliveryOption == this.deliveryOptions.deliverToAddress) {
        return this.shippingInfo.placeId != (null && undefined && "");
      }
      else {
        return true;
      }
    }
  },
  watch: {
    deliveryOption() {
      this.shippingInfo = {
        placeId: null,
        addressDetails: null,
        altPhoneNumber: null,
      };
      this.schoolInfo = {
        schoolId: null,
        studentName: null,
        studentClass: null,
      };
    },
    shippingInfo() {
      this.$emit('updateShippingInfo', this.shippingInfo);
    },
    schoolInfo() {
      this.$emit('updateSchoolInfo', this.schoolInfo);
    },
    isPickupOrder() {
      this.$emit('updateIsPickupOrder', this.isPickupOrder);
    },
    validPlaceId() {
      this.$emit('updateIsValid', this.validPlaceId);
    }
  },
  methods: {
    setShippingInfo(data) {
      this.shippingInfo = data;
    },
    setSchoolInfo(data) {
      this.schoolInfo = data;
    },
  }
}
</script>