<template>
  <ApolloMutation
    :mutation="(gql) => addNoteToOrderFulfillmentMutation"
    :variables="{
      input: {
        id: id,
        note: newNote,
      },
    }"
    :update="updateCache"
    @done="onDone"
  >
    <template #default="{ loading, mutate, error }">
      <h3>
        {{ $t('transactionReports.notes.orderFulfillmentNotes') }}
      </h3>
      <v-list>
        <v-list-item
          v-for="(note, index) in currentNotes"
          :key="index"
          class="pa-1 mb-1 note-item"
        >
          {{ note }}
        </v-list-item>
        <v-list-item
          v-if="currentNotes?.length === 0 || !currentNotes"
          class="px-0"
        >
          {{ $t('transactionReports.notes.noNotes') }}
        </v-list-item>
      </v-list>
      <apollo-error-alert
        v-if="error"
        :error="error"
      />
      <validation-observer
        ref="observer"
        v-slot="{ passed }"
      >
        <ekitabu-text-area
          id="new-fulfillment-note"
          v-model="newNote"
          class="fill-width"
          :label="$t('transactionReports.notes.note')"
          :placeholder="$t('transactionReports.notes.addNote')"
          rules="required|min:3|max:200"
          required
        />
        <v-btn
          class="mt-2"
          tile
          color="primary"
          elevation="0"
          :disabled="!passed || loading"
          :loading="loading"
          block
          @click="mutate"
        >
          {{ $t('transactionReports.notes.addNote') }}
          <v-icon right>
            mdi-plus
          </v-icon>
        </v-btn>
      </validation-observer>
    </template>
  </ApolloMutation>
</template>

<script>
import EkitabuTextArea from '@/components/EkitabuTextArea';
import { max, min, required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver } from 'vee-validate'
import gql from 'graphql-tag';
import i18n from '@/i18n';
import ApolloErrorAlert from '@/components/ApolloErrorAlert';

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', length: '{length}'}),
})

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', length: '{length}'}),
})

extend('required', {
  ...required,
  message: i18n.t('errors.required', {field: '{_field_}'}),
})

export default {
  name: "AddOrderFulfillmentNoteForm",
  components: {
    EkitabuTextArea,
    ValidationObserver,
    ApolloErrorAlert,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    currentNotes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newNote: '',
    };
  },
  computed: {
    addNoteToOrderFulfillmentMutation() {
      return gql`
        mutation addNoteToOrderFulfillment($input: addNoteToOrderFulfillmentInput!) {
          addNoteToOrderFulfillment(input: $input) {
            orderFulfillment {
              id
              notes
            }
          }
        }
      `;
    },
  },
  methods: {
    updateCache(cache, { data: { addNoteToOrderFulfillment } }) {
      cache.modify({
        id: cache.identify(addNoteToOrderFulfillment.orderFulfillment),
        fields: {
          notes() {
            return addNoteToOrderFulfillment.orderFulfillment.notes;
          },
        },
      });
    },
    onDone() {
      this.newNote = '';
      this.$refs.observer.reset();
    },
  },
}
</script>

<style scoped>
.note-item {
  background-color: var(--v-lightestAccent-base);
  border-bottom: 1px solid var(--v-lightAccent-base);
  min-height: 0px;
}
</style>