<template>
  <div class="footer-option-container">
    <span class="footer-option-label">{{ $t("common.currency") }}:</span>
    <v-select
      v-model="currencySelected"
      :items="currencies"
      class="footer-option-select"
    />
  </div>
</template>

<script>
  import currencyMixins from "@/mixins/currencyMixins";

  export default {
    mixins: [currencyMixins],
    data() {
      return {
        currencies: this.currencyCodes,
      };
    },
    computed: {
      currencySelected: {
        get: function () {
          return this.$store.state.currency.currencyCode;
        },
        set: function (newVal) {
          this.$store.dispatch("changeCurrency", newVal);
        },
      },
    },
  };
</script>