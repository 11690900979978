<template>
  <div class="d-flex align-start align-sm-center flex-column flex-sm-row">
    <v-switch
      v-if="isOwner"
      v-model="listIsPublic"
      class=" pa-0 mr-2 mr-sm-2 my-2 my-sm-0"
      :label="$t('bookList.share')"
      :disabled="isLoading"
      :loading="isLoading"
      :small="$vuetify.breakpoint.xs"
      hide-details
      color="success"
      @change="togglePrivacy"
    />
    <div
      v-if="isOwner || listIsPublic"
      class="d-flex w-100 flex-wrap"
    >
      <copy-link-button
        :url="url"
        :disabled="!listIsPublic"
      />
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="rounded-0 share-button mx-2"
            color="secondary"
            elevation="0"
            :small="$vuetify.breakpoint.xs"
            :disabled="!listIsPublic"
            v-on="on"
            @click="openWhatsApp()"
          >
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('common.shareWhatsApp') }}</span>
      </v-tooltip>
      <v-tooltip top>
        <template #activator="{ on }">
          <v-btn
            class="rounded-0 share-button"
            color="secondary"
            elevation="0"
            :small="$vuetify.breakpoint.xs"
            :disabled="!listIsPublic"
            v-on="on"
            @click="openEmail()"
          >
            <v-icon>mdi-email-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('common.shareEmail') }}</span>
      </v-tooltip>
    </div>
    <success-snackbar
      v-model="snackbar"
      :message="listIsPublic ? $t('bookList.sharableText') : $t('bookList.privateList')"
    />
  </div>
</template>

<script>
  import BookListQueries from '@/graphql/BookListQueries';
  import iris from '@/iris';
  import SuccessSnackbar from '@/components/SuccessSnackbar';
  import CopyLinkButton from '@/components/CopyLinkButton';

  export default {
    components: {
      SuccessSnackbar,
      CopyLinkButton,
    },
    props: {
      isOwner: {
        type: Boolean,
        required: false,
      },
      bookListId: {
        type: String,
        required: true,
      },
      isPublic: {
        type: Boolean,
        required: true,
      },
      bookListName: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        listIsPublic: this.IsPublic,
        isLoading: false,
        snackbar: false,
        linkCopied: false,
      };
    },
    computed: {
      url() {
        return `${window.location.origin}/user-book-list/${this.bookListId}`;
      },
      subjectText() {
        return this.$t('bookList.shareSubject');
      },
      messageText() {
        return this.$t('bookList.shareBody', { url: this.url });
      },
    },
    watch: {
      isPublic: {
        handler() {
          this.listIsPublic = this.isPublic;
        },
        immediate: true,
      },
    },
    methods: {
      async togglePrivacy() {
        this.isLoading = true;
        const { data, errors } = await this.$apollo.mutate({
          mutation: BookListQueries.setPrivacyBookList,
          variables: {
            input: {
              id: iris.bookList+this.bookListId,
              isPublic: this.listIsPublic,
            },
          },
        });
        
        if (errors) {
          console.error(errors);
          this.isLoading = false;
          return;
        }

        if (data) {
          this.snackbar = true;
        }
        
        this.isLoading = false;
      },
      openWhatsApp() {
        window.open(`https://api.whatsapp.com/send?text=${this.messageText}`);
      },
      openEmail() {
        window.open(`mailto:?subject=${this.subjectText}&body=${this.messageText}`);
      },
    },
  };
</script>
