<template>
  <div class="rounded-lg">
    <div
      class="price-text mt-2 mb-1"
    >
      <Price
        v-if="!isOwned"
        :book-price="Number(price)"
        :base-currency="currencyId"
        include-tax
        :should-round="true"
        :is-owned="isOwned"
      />
    </div>
    <add-to-library-button
      v-if="price == 0 && !isOwned && showCartButton"
      :product-id="noIriId"
      :query-loading="isOwnedLoading"
      block
      :show-icon="false"
      :refetch-bookshelf="refetchBookshelf"
      short-text
    />
    <AddToCartButton
      v-else-if="!isOwned && showCartButton"
      :id="id"
      :simplified-id="noIriId"
      :query-loading="isOwnedLoading"
      block
    />
  </div>
</template>

<script>
import Price from "@/components/Price";
import AddToCartButton from "@/views/checkout/AddToCartButton.vue";
import AddToLibraryButton from "@/components/AddToLibraryButton.vue";

export default {
  components: {
    Price,
    AddToCartButton,
    AddToLibraryButton,
  },
  props: {
    price: {
      type: Number,
      default: null,
    },
    currencyId: {
      type: String,
      default: null,
    },
    isOwned: {
      type: Boolean,
      default: false,
    },
    isOwnedLoading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    noIriId: {
      type: String,
      default: null,
    },
    showCartButton: {
      type: Boolean,
      default: true,
    },
    refetchBookshelf: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
