<template>
  <div
    class="input-container"
  >
    <validation-provider
      v-slot="{ errors }"
      ref="validationProvider"
      :rules="rules"
      :name="label"
      :detect-input="false"
      mode="aggressive"
    >
      <label
        v-if="label"
        class="input-label"
        :for="id"
        :style="labelStyle"
      >
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
      </label>
      <vue-tel-input
        ref="phoneInput"
        v-mask="'### ### ###'"
        :value="currentValue"
        class="input-field"
        :auto-format="false"
        :dropdown-options="{
          showFlags: true,
          disabled: dropdownDisabled,
          showDialCodeInSelection: true,
        }"
        :input-options="{
          id: id,
          placeholder: placeholder,
          maxlength: 11,
        }"
        default-country="KE"
        :only-countries="['KE', 'RW', 'MW']"
        v-bind="$attrs"
        @input="inputChanged"
      />
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="error-text"
      >
        {{ error }}
      </span>
    </validation-provider>
  </div>
</template>

<script>
  import { ValidationProvider } from 'vee-validate';
  import { VueTelInput } from 'vue-tel-input';

  export default {
    name: "EkitabuMaskedPhoneInput",
    components: {
      ValidationProvider,
      VueTelInput,
    },
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: null,
      },
      labelStyle: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        default: null,
        required: true,
      },
      rules: {
        type: String,
        default: null,
      },
      mask: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      dropdownDisabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        currentValue: this.value.slice(3),
        number: "",
        dialCode: this.value.slice(0, 3),
      }
    },
    watch: {
      value(val) {
        this.currentValue = val.slice(3);
        this.$refs.phoneInput.emitInput(val.slice(3));
      },
    },
    methods: {
      inputChanged(phoneString, phoneObject) {
        let phoneNumber = "";

        phoneNumber = phoneString.replaceAll(" ", "");
        this.$refs.validationProvider.validate(phoneNumber ?? null);

        if (phoneObject.nationalNumber) {
          phoneNumber = phoneObject.countryCallingCode+phoneNumber;
        } else {
          phoneNumber = this.dialCode+phoneNumber;
        }

        this.$emit("formattedPhoneChanged", phoneNumber.length > 3 ? phoneNumber : "");
      },
    },
  };
</script>

<style scoped>
  .input-container {
    max-width: 300px;
  }

  .input-field {
    background-color: var(--v-background-base);
    color: var(--v-primaryText-base);
    height: 48px;
    width: 100%;
  }

  .input-field:focus-visible {
    outline: none;
  }

  .input-field::placeholder {
    color: var(--v-secondaryText-base);
  }
</style>