<template>
  <div>
    <v-dialog
      v-model="showOrderClaimedModal"
      max-width="1000px"
    >
      <v-card
        class="fill-height"
        tile
      >
        <v-card-title
          class="pa-3"
        >
          <h2>{{ $t("profile.itemsClaimed") }}</h2>
          <v-spacer />
          <v-btn
            icon
            color="textOnPrimary"
            @click="showOrderClaimedModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle
          class="px-3 mt-3"
        >
          <p class="subtitle mb-0">
            {{ $t("profile.itemsClaimedText") }}
          </p>
        </v-card-subtitle>
        <v-card-text class="recently-claimed-container pa-3">
          <h2 class="pb-1 mb-3 recently-claimed-header">
            {{ $t('profile.recentlyClaimedBooks') }}
          </h2>
          <v-row v-if="claimedItemsLoading">
            <v-col
              v-for="n in loadingItemsCount"
              :key="n"
              class="col-12 col-sm-6 col-md-4 py-2 py-sm-4"
            >
              <v-skeleton-loader
                type="image"
                height="100px"
              />
            </v-col>
          </v-row>
          <v-row v-else-if="claimedItems?.length > 0">
            <v-col
              v-for="orderLine in claimedItems"
              :key="orderLine.id"
              class="col-12 col-sm-6 col-md-4 py-2 py-sm-4 book-column"
            >
              <order-book-display-item
                :order-line="orderLine"
                :show-price="false"
              />
            </v-col>
          </v-row>
          <h3 v-else>
            {{ $t('shop.noBooksFound') }}
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            elevation="0"
            @click="navigateToBookshelf"
          >
            {{ $t("bookshelf.viewOnBookshelf") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <success-snackbar
      v-model="showOrderClaimedSnackbar"
      :message="$t('profile.claimOrderSuccess')"
    />
  </div>
</template>

<script>
import SuccessSnackbar from '@/components/SuccessSnackbar';
import ClaimOrderMixin from '@/mixins/ClaimOrderMixin';
import OrderBookDisplayItem from '@/components/OrderBookDisplayItem';

export default{
  name: "ClaimedOrderModal",
  components: {
    SuccessSnackbar,
    OrderBookDisplayItem,
  },
  mixins: [
    ClaimOrderMixin,
  ],
  methods: {
    navigateToBookshelf() {
      this.showOrderClaimedModal = false;
      this.$router.push({ name: 'bookshelf' });
    },
  }
}
</script>

<style scoped>
.subtitle {
  color: var(--v-primaryText-base);
  font-size: 1.25rem;
}

.recently-claimed-container {
  max-height: 500px;
  overflow-y: auto;
}

.recently-claimed-header {
  font-weight: 500;
  color: var(--v-primaryText-base);
  border-bottom: 1px solid var(--v-lightAccent-base);
}
</style>