<template>
  <v-container class="text-caption">
    <h2 class="text-subtitle-1 font-weight-medium mb-1">
      {{ $t("checkout.orderPending.orderStatus") }}
    </h2>
    <v-chip
      :class="`${chipColor}-gradient rounded-lg justify-center text-caption font-weight-medium mb-3 ekitabu-chip`"
      text-color="textOnPrimary"
    >
      {{ orderStatus }}
    </v-chip>
    <div v-if="orderStatus == STATUSES.FAILED">
      <p>{{ $t("checkout.orderPending.safaricomDeclined") }}:</p>
      <p class="font-weight-bold">
        {{ safaricomMpesaRequest?.resultDescription ?? momoMtnPayment?.resultDescription ?? $t("checkout.orderPending.unknownError") }}
      </p>
    </div>
  </v-container>
</template>

<script>
import BookQueries from "@/graphql/BookQueries";
import gql from "graphql-tag";
import iris from "@/iris";

export default {
  name: "OrderPending",
  data() {
    return {
      order: null,
      safaricomMpesaRequestId: null,
      momoMtnRequestId: null,
    };
  },
  computed: {
    orderStatus() {
      if (this.momoMtnRequestId) {
        if (this.momoMtnPayment?.resultCode == "0") {
          return this.STATUSES.CONFIRMED;
        } else if (this.momoMtnPayment?.resultCode == "1") {
          return this.STATUSES.FAILED;
        }
        return this.STATUSES.PENDING;
      }
      else if (this.safaricomMpesaRequestId) {
        if (this.safaricomMpesaRequest?.resultCode) {
          const resultCodeInteger = parseInt(
            this.safaricomMpesaRequest.resultCode
          );
          if (resultCodeInteger === 0) {
            return this.STATUSES.CONFIRMED;
          } else if (resultCodeInteger > 0) {
            return this.STATUSES.FAILED;
          }
        }
      }

      return this.STATUSES.PENDING;
    },
    safaricomMpesaRequest() {
      if (this.order && this.order.safaricomMpesaRequests.length == 1) {
        return this.order.safaricomMpesaRequests[0];
      } else if (this.order && this.order.safaricomMpesaRequests.length > 1) {
        throw new Error(
          "We weren't able to filter out to one safaricomMpesaRequest"
        );
      }
      return null;
    },
    STATUSES() {
      return {
        PENDING: this.$t("checkout.orderPending.statuses.pending")+'...',
        CONFIRMED: this.$t("checkout.orderPending.statuses.confirmed"),
        FAILED: this.$t("checkout.orderPending.statuses.failed"),
      };
    },
    chipColor() {
      if (this.orderStatus == this.STATUSES.PENDING) {
        return "unemphasized";
      } else if (this.orderStatus == this.STATUSES.CONFIRMED) {
        return "tertiary";
      } else if (this.orderStatus == this.STATUSES.FAILED) {
        return "warning";
      }
      return "";
    },
  },
  apollo: {
    // TODO: Get the mercure integration working in order to dynamically get updates on the order status
    //  https://github.com/ekitabu-LLC/shop-web/issues/53
    order: {
      query: gql`
        query getOrderStatus($id: ID!, $safaricomMpesaRequestId: String) {
          order(id: $id) {
            id
            confirmed
            safaricomMpesaRequests(id: $safaricomMpesaRequestId) {
              id
              resultCode
              resultDescription
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          safaricomMpesaRequestId: this.safaricomMpesaRequestId,
        };
      },
      async result({ data }) {
        if (data.order.confirmed) {
          this.navigateToConfirmation();
        }
      },
      pollInterval: 1500,
      skip() {
        return !this.safaricomMpesaRequestId;
      },
    },
    momoMtnPayment: {
      query: gql`
        query updateStatusMomoMtnPayment($id: ID!) {
          updateStatusMomoMtnPayment(id: $id) {
            id
            resultCode
            resultDescription
            transactionReference
            localOrder {
              id
              confirmed
            }
          }
        }
      `,
      variables() {
        return {
          id: this.momoMtnRequestId,
        };
      },
      update(data) {
        return data?.updateStatusMomoMtnPayment;
      },
      async result({ data }) {
        this.momoMtnStatus = data?.updateStatusMomoMtnPayment?.resultCode;
        this.momoMtnStatusDescription = data?.updateStatusMomoMtnPayment?.resultDescription;

        if (data?.updateStatusMomoMtnPayment?.localOrder?.confirmed) {
          this.navigateToConfirmation();
        }
      },
      pollInterval: 1500,
      skip() {
        return !this.momoMtnRequestId;
      },
    }
  },
  mounted() {
    // by default, the named route params are correct on navigating from checkout, but not if the url is reloaded
    // so checks are needed to make sure the params for mpesa/momo are handled correctly
    const paramSafaricomMpesaRequestId = this.$route.params?.safaricomMpesaRequestId;
    const paramMomoMtnRequestId = this.$route.params?.momoMtnRequestId;
    
    if (paramSafaricomMpesaRequestId?.includes(iris.safaricomMpesaRequests)) {
      this.safaricomMpesaRequestId = paramSafaricomMpesaRequestId;
    }
    else if (paramMomoMtnRequestId?.includes(iris.safaricomMpesaRequests)) {
      this.safaricomMpesaRequest = paramMomoMtnRequestId;
    }
    
    if (paramMomoMtnRequestId?.includes(iris.momoMtnPayments)) {
      this.momoMtnRequestId = paramMomoMtnRequestId;
    }
    else if (paramSafaricomMpesaRequestId?.includes(iris.momoMtnPayments)) {
      this.momoMtnRequestId = paramSafaricomMpesaRequestId;
    }
  },
  methods: {
    async navigateToConfirmation() {
      await this.$apollo.query({
        query: BookQueries.getUserBookshelf,
        fetchPolicy: "network-only",
      });
      this.$store.commit("setCurrentCart", null);
      this.$router.push({
        name: "confirmed",
        params: {
          id: this.$route.params.id,
        },
      });
    },
  }
};
</script>
