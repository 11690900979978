export default {
  computed: {
    featureToggles() {
      return {
        momoMtnPayment: process.env.VUE_APP_MOMO_MTN_PAYMENT,
      };
    },
  },
  methods: {
    isFeatureEnabled(featureName) {
      const value = this.featureToggles[featureName];
      return this.checkTrue(value);
    },
    checkTrue(value) {
      return value == true || value == 'true';
    },
  },
};