import {
  supportedCountries,
  DEFAULT_TAX_REGION,
  DEFAULT_CURRENCY, NOT_SUPPORTED_CURRENCY
} from "@/utils/Const";

export default {
  data() {
    return {
      coords: null,
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.locale.countryCode;
    },
  },
  watch: {
    coords(newCoords) {
      if (newCoords) {
        this.fetchGeocodeData(newCoords);
      }
    },
  },
  created() {
    this.getUserCoordinates();
  },
  methods: {
    getUserCoordinates() {
      navigator.geolocation.getCurrentPosition(
        (position) => this.coords = position.coords,
        (error) => {
          console.error("Unable to get user current location", error);
          this.fetchGeocodeData();
        }
      );
    },
    fetchGeocodeData(coords) {
      let url = process.env.VUE_APP_REVERSE_GEOCODE_BASE_URL;
      
      if (coords) {
        url += "?latitude=" + coords.latitude + "&longitude=" + coords.longitude;
      }

      fetch(url)
        .then(response => response.json())
        .then((data) => {
          const foundSupportedCountry = supportedCountries.find((country) => country.alpha2 === data.countryCode);

          if (data.countryCode === this.countryCode || foundSupportedCountry?.alpha3 === this.countryCode) {
            return;
          }

          if (foundSupportedCountry) {
            this.$store.dispatch("changeCountry", foundSupportedCountry.alpha3);
            this.$store.dispatch("changeCurrency", foundSupportedCountry.currencyCode);
          } else {
            this.$store.dispatch("changeCountry", data.countryCode);
            this.$store.dispatch("changeCurrency", NOT_SUPPORTED_CURRENCY);
          }
        })
        .catch(err => {
          console.error("Error in fetching coordinate location information", err);
          this.$store.dispatch("changeCountry", DEFAULT_TAX_REGION);
          this.$store.dispatch("changeCurrency", DEFAULT_CURRENCY);
        });
    },
  },
}