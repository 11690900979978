<template>
  <ApolloMutation
    :mutation="deleteBookListMutation"
    :variables="{
      id: bookListId,
    }"
    :update="updateCache"
  >
    <template #default="{ mutate, loading }">
      <v-btn
        icon
        color="secondary"
        :disabled="loading"
        :loading="loading"
        @click="setDialog(true)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-dialog
        v-model="dialog"
        max-width="400px"
      >
        <v-card tile>
          <v-card-title>{{ $t('bookshelf.deleteBookList') }}</v-card-title>
          <v-card-text
            style="color: var(--v-primarytext-base);"
            class="py-6"
          >
            {{ $t('bookshelf.deleteConfirmation', { name: bookListName }) }}
          </v-card-text>
          <v-card-actions>
            <v-btn
              tile
              depressed
              color="primary"
              @click="setDialog(false)"
            >
              {{ $t('button.cancel') }}
            </v-btn>
            <v-btn
              tile
              depressed
              :disabled="loading"
              :loading="loading"
              color="secondary"
              @click="mutate()"
            >
              {{ $t('button.delete') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </ApolloMutation>
</template>

<script>
import BookListQueries from "@/graphql/BookListQueries";

export default {
  name: "DeleteBookListButton",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bookListId: {
      type: String,
      required: true,
    },
    bookListName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      snackbar: this.value,
    };
  },
  computed: {
    deleteBookListMutation() {
      return BookListQueries.deleteBookList;
    },
    bookListsQuery() {
      return () => [{
        query: BookListQueries.getUserBookLists,
      }]
    },
  },
  watch: {
    snackbar(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    setDialog(show) {
      this.dialog = show;
    },
    updateCache(cache, { data: { deleteBookList } }) {
      const data = cache.readQuery({ query: BookListQueries.getUserBookLists });

      cache.modify({
        fields: {
          getMyBookLists(existingBookLists, { readField }) {
            return existingBookLists.filter((item) => readField('id', item) !== deleteBookList.bookList.id);
          },
        },
      });

      this.setDialog(false);
      this.snackbar = true;
    },
  },
};
</script>

<style scoped>
:deep(.v-dialog.v-dialog--active) {
  border-radius: 0px;
}
</style>