<template>
  <v-container class="header-border px-5 py-2">
    <div class="d-flex">
      <h3 class="text-subtitle-2 font-weight-bold">
        {{ title }}
      </h3>
      <v-spacer />
      <slot />
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ScreeViewHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
