<template>
  <div>
    <div class="header-border pb-1 mb-3">
      <h2>{{ $t('bookListManager.bookLists') }}</h2>
      <v-progress-linear
        v-if="$apollo.loading"
        color="primary"
        indeterminate
      />
    </div>
    <v-list
      class="school-booklists"
    >
      <v-list-item-group
        v-if="schoolBookLists?.length > 0"
        v-model="selectedBookListId"
      >
        <v-list-item
          v-for="bookList in schoolBookLists"
          :key="bookList.id"
          :value="bookList.id"
        >
          <v-list-item-title>{{ bookList.name }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <v-list-item
        v-else
      >
        <v-list-item-title>{{ $t('bookListManager.noBookLists') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SchoolBookListList",
  data() {
    return {
      selected: null,
      selectedBookListId: null,
    };
  },
  apollo: {
    schoolBookLists: {
      query: gql`
        query BookLists {
          getSchoolBookLists {
            id
            name
            isPublic
            bookListItems {
              id
              name
            }
            forSchool {
              id
              _id
              name
            }
          }
        }
      `,
      update: (result) => {
        return result?.getSchoolBookLists;
      },
    },
  },
  watch: {
    selectedBookListId(val) {
      this.$emit("bookListSelected", val);
    },
  },
};
</script>

<style scoped>
.school-booklists {
  max-height: 500px;
  overflow-y: auto;
}
</style>