var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"header-border pb-1 mb-3"},[_c('h2',[_vm._v(_vm._s(_vm.$t('eventManager.events')))]),(_vm.$apollo.loading)?_c('v-progress-linear',{attrs:{"color":"primary","indeterminate":""}}):_vm._e()],1),_c('v-list',{staticClass:"event-list"},[(_vm.events?.length > 0)?_c('v-list-item-group',{model:{value:(_vm.selectedEventId),callback:function ($$v) {_vm.selectedEventId=$$v},expression:"selectedEventId"}},_vm._l((_vm.events),function(event){return _c('v-list-item',{key:event.id,attrs:{"value":event.id}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(event.name)+" ")]),_c('ApolloMutation',{attrs:{"mutation":_vm.archiveEventMutation,"variables":{
            input: {
              id: event.id,
            },
          },"update":_vm.updateCache},scopedSlots:_vm._u([{key:"default",fn:function({ mutate, loading }){return [_c('DeleteConfirmationModal',{attrs:{"title":_vm.$t('eventManager.deleteEvent', { event: event.name }),"confirmation-message":_vm.$t('eventManager.deleteEventConfirmation'),"on-confirm":() => {
                _vm.selectedEventId = null;
                _vm.selected = null;
                mutate()
              },"loading":loading}})]}}],null,true)})],1)}),1):_c('v-list-item',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('eventManager.noEvents')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }