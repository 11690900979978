import BasketIcon from "@/components/icons/BasketIcon.vue";
import AudiobookIcon from "@/components/icons/AudiobookIcon.vue";
import TriangleRightIcon from "@/components/icons/TriangleRightIcon.vue";
import TriangleUpIcon from "@/components/icons/TriangleUpIcon.vue";
import HeadPhonesIcon from "@/components/icons/HeadPhonesIcon.vue";
import Cart from "@/components/icons/CartIcon.vue";
import Trash from "@/components/icons/TrashIcon.vue";
import BookListIcon from "@/components/icons/BookListIcon.vue";
import BookshelfIcon from "@/components/icons/BookshelfIcon.vue";

export const icons = {
  values: {
    basket: {
      component: BasketIcon,
    },
    audiobook: {
      component: AudiobookIcon,
    },
    triangleRight: {
      component: TriangleRightIcon,
    },
    triangleUp: {
      component: TriangleUpIcon,
    },
    headphones: {
      component: HeadPhonesIcon,
    },
    cart: {
      component: Cart
    },
    trash: {
      component: Trash
    },
    bookList: {
      component: BookListIcon
    },
    bookshelf: {
      component: BookshelfIcon
    }
  },
}