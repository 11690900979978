<template>
  <div>
    <ekitabu-select
      id="country-select"
      v-model="schoolId"
      :items="schools ?? []"
      item-text="name"
      item-value="_id"
      :label="$t('checkout.delivery.school')"
      :placeholder="$t('checkout.delivery.selectSchool')"
      class="mb-3"
      rules="required"
      required
    />
    <ekitabu-text-input
      id="autocomplete"
      v-model="studentClass"
      class="mb-3"
      :label="$t('checkout.delivery.studentClass')"
      :placeholder="$t('checkout.delivery.studentClass')"
      required
      rules="required|min:3"
    />
    <ekitabu-text-input
      id="autocomplete"
      v-model="studentName"
      class="mb-3"
      :label="$t('checkout.delivery.studentName')"
      :placeholder="$t('checkout.delivery.studentName')"
      required
      rules="required|min:3"
    />
  </div>
</template>

<script>
import EkitabuSelect from "@/components/EkitabuSelect";
import EkitabuTextInput from "@/components/EkitabuTextInput";
import gql from "graphql-tag";

export default {
  name: "DeliverToSchool",
  components: {
    EkitabuSelect,
    EkitabuTextInput,
  },
  data() {
    return {
      schoolId: null,
      studentName: null,
      studentClass: null,
    };
  },
  apollo: {
    schools: {
      query: gql`
        query {
          schools {
            id
            _id
            name
          }
        }
      `,
    },
  },
  watch: {
    schoolId() {
      this.updateSchoolData();
    },
    studentName() {
      this.updateSchoolData();
    },
    studentClass() {
      this.updateSchoolData();
    },
  },
  methods: {
    updateSchoolData() {
      this.$emit("updateSchoolData", {
        schoolId: this.schoolId,
        studentName: this.studentName,
        studentClass: this.studentClass,
      });
    },
  }
}
</script>