import { PAYMENT_PROVIDERS } from "@/utils/Const";
import i18n from "@/i18n";

export function getProviderDetails(order) {
  let foundTransaction = order?.cashPayments?.find((payment) => payment?.succeededAt != null);
  const providerDetails = { provider: null, transactionId: null, msidsn: null };
  if (foundTransaction) {
    providerDetails.transactionId = i18n.t("checkout.cashOrder");
    providerDetails.provider = PAYMENT_PROVIDERS.cash;
  }
  else {
    foundTransaction = order?.safaricomMpesaRequests.find((payment) => payment.resultCode == 0);
    if (foundTransaction) {
      providerDetails.transactionId = foundTransaction.merchantRequestId;
      providerDetails.provider = PAYMENT_PROVIDERS.safaricom;
      providerDetails.msidsn = foundTransaction.msidsn;
    }
    else {
      foundTransaction = order?.dpoPayments.find((payment) => payment.resultCode == 0);

      if (foundTransaction) {
        providerDetails.transactionId = foundTransaction.transactionReference;
        providerDetails.provider = PAYMENT_PROVIDERS.dpo;
      }
      else {
        foundTransaction = order?.momoMtnPayments.find((payment) => payment.resultCode == 0);

        if (foundTransaction) {
          providerDetails.transactionId = foundTransaction.transactionReference;
          providerDetails.provider = PAYMENT_PROVIDERS.mtn;
          providerDetails.msidsn = foundTransaction.msidsn;
        }
      }
    }
  }
  return providerDetails;
}