<template>
  <ApolloQuery
    :query="(gql) => tagsQuery"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <apollo-error-alert
        :error="error"
      />
      <search-autocomplete
        :id="id"
        v-model="selected"
        :rules="rules"
        :items="data?.tags ?? []"
        :label="$t('couponManager.forTag')"
        :placeholder="$t('couponManager.searchTags')"
        item-value="_id"
        :select-text="selectText"
        :loading="!!isLoading"
        :filter="customFilter"
        :required="required"
        @searchChanged="onSearchChanged"
      />
      <div
        v-if="selected"
        class="d-flex"
      >
        <h4>{{ $t('couponManager.currentTag') }}:</h4>
        <span class="ml-2">{{ selected }}</span>
      </div>
    </template>
  </ApolloQuery>
</template>

<script>
import SearchAutocomplete from "@/components/SearchAutocomplete";
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import gql from "graphql-tag";

export default {
  name: "TagSearch",
  components: {
    SearchAutocomplete,
    ApolloErrorAlert,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchText: "",
      selected: this.value,
    };
  },
  computed: {
    tagsQuery() {
      return gql `query {
          tags {
            id
            _id
            name
          }
        }
      `;
    },
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selected = val;
    },
  },
  methods: {
    onSearchChanged(searchText) {
      this.searchText = searchText;
    },
    customFilter (item, queryText, itemText) {
      if (this.searchText == null || this.searchText == "" || this.searchText.length < 3) return false;

      const text = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return text.indexOf(searchText) > -1;
    },
    selectText(item) {
      return item.name;
    }
  },
};
</script>