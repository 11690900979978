<template>
  <div>
    <ScreenViewHeader :title="$te('tags.' + tagId) ? $t('tags.' + tagId) : tagName" />
    <v-container>
      <div>
        <ApolloQuery
          :query="(gql) => getBooksByFilter"
          :variables="{
            tags: filterTags,
            page: 1,
          }"
          :update="(data) => data.books.collection"
        >
          <template #default="{ result: { error, data }, isLoading, query }">
            <book-display
              :data="data"
              :is-loading="!!isLoading"
              :error="error"
              :query="query"
              :loading-items="loadingItemsCount"
              :show-buy-now="showBuyNow"
            />
            <v-container v-if="(!data || data.length === 0) && !isLoading">
              <v-row justify="center">
                <v-col
                  class="col-12 col-sm-8 pa-16"
                >
                  <book-list-icon :size="72" />
                  <h1>{{ $t('shop.noBooksForTag') }}</h1>
                  <v-btn
                    tile
                    block
                    color="primary"
                    class="mt-4"
                    :to="{ name: 'home' }"
                  >
                    {{ $t('common.returnToShop') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </ApolloQuery>
      </div>
    </v-container>
  </div>
</template>

<script>
import BookListIcon from "@/components/icons/BookListIcon";
import BookFragments from "@/graphql/BookFragments";
import BookDisplay from "@/components/BookDisplay";
import gql from "graphql-tag";
import { BUY_NOW_IDS } from "@/utils/Const";
import ScreenViewHeader from "@/components/ScreenViewHeader.vue";

const LOADING_ITEM_COUNT = 12;

const BOOK_DETAILS_FRAGMENT = BookFragments.bookDetailsFragment;

export default {
  name: "FilteredBookList",
  components: {
    BookListIcon,
    BookDisplay,
    ScreenViewHeader,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loadingItemsCount: LOADING_ITEM_COUNT,
    };
  },
  computed: {
    tagId() {
      return parseInt(this.id ?? this.$route.params.id);
    },
    tagName() {
      return this.name ?? this.$route.params.tagName;
    },
    filterTags() {
      return [this.tagId];
    },
    showBuyNow() {
      return BUY_NOW_IDS.includes(this.tagId);
    },
    getBooksByFilter() {
      return gql`query filterTags($tags: [Int], $page: Int!) {
          books(tags_list: $tags, page: $page) {
            __typename
            collection {
              ...BookDetails
            }
          }
        }
        ${BOOK_DETAILS_FRAGMENT}
      `
    },
  },
};
</script>
