<template>
  <div>
    <ApolloQuery
      :query="gql => userOrderQuery"
      :variables="{
        userId: userId,
        orderByDate: orderByDate,
        confirmed: confirmed,
        limit: 1000,
      }"
      :update="updateData"
    >
      <template #default="{ result: { data, error }, isLoading }">
        <apollo-error-alert
          v-if="error"
          :error="error"
        />
        <div class="mb-3">
          <h4 class="mb-1">
            {{ $t('userDashboard.userOrders') }}
          </h4>
          <v-progress-linear
            v-if="isLoading"
            indeterminate
            color="primary"
          />
          <div v-if="!isLoading && data && data.length > 0">
            <table
              v-for="order in data"
              :key="order.id"
              class="admin-order-table mb-3"
            >
              <thead>
                <tr>
                  <th>{{ $t('transactionReports.orderId') }}</th>
                  <th>{{ $t('transactionReports.date') }}</th>
                  <th>{{ $t('transactionReports.currency') }}</th>
                  <th>{{ $t('transactionReports.pricePaid') }}</th>
                  <th>{{ $t('couponManager.discount') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ order?._id }}</td>
                  <td>{{ formatDate(order?.updatedAt) }}</td>
                  <td>{{ order?.currency._id }}</td>
                  <td>{{ order?.totalPriceSet ?? 0 }}</td>
                  <td>{{ order?.discountSet ?? 0 }}</td>
                </tr>
              </tbody>
              <thead>
                <tr>
                  <th
                    colspan="5"
                    class="sub-header"
                  >
                    {{ $t('userDashboard.transactionItems') }}
                  </th>
                </tr>
                <tr>
                  <th>{{ $t('transactionReports.transactionId') }}</th>
                  <th>{{ $t('transactionReports.name') }}</th>
                  <th>{{ $t('transactionReports.price') }}</th>
                  <th>{{ $t('transactionReports.currency') }}</th>
                  <th>{{ $t('couponManager.discount') }}</th>
                </tr>
              </thead>
              <tr
                v-for="{ node } in order?.orderLines?.edges"
                :key="node.id"
              >
                <td>{{ node._id }}</td>
                <td>{{ node.name }}</td>
                <td>{{ node.priceSet ?? 0 }}</td>
                <td>{{ node.baseCurrency._id }}</td>
                <td>{{ node.discountSet ?? 0 }}</td>
              </tr>
            </table>
          </div>
          <div v-else-if="!isLoading">
            {{ $t('profile.noOrders') }}
          </div>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>

<script>
import ApolloErrorAlert from '@/components/ApolloErrorAlert.vue';
import OrderQueries from '@/graphql/OrderQueries';
import { formatDate } from '@/helpers/generalFunctions.js';

export default {
  name: "UserLatestOrder",
  components: {
    ApolloErrorAlert,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      orderByDate: "DESC",
      confirmed: true,
    };
  },
  computed: {
    userOrderQuery() {
      return OrderQueries.getUserOrders;
    },
  },
  methods: {
    formatDate,
    updateData(data) {
      return data?.getUserOrders;
    }
  },
};
</script>

<style scoped>
.order-table {
  width: 100%;
  border-collapse: collapse;
}

.order-table thead {
  background-color: var(--v-lightestAccent-base);
  border: 1px solid #ddd;
}

.order-table th,
.order-table td {
  padding: 4px;
  text-align: left;
  font-size: 0.75rem;
}

.order-table td {
  border: 1px solid #ddd;
}

.order-table .sub-header {
  text-align: center;
}
</style>