import { onError } from '@apollo/client/link/error'

import store from '@/store';

const errorLink = onError(({ networkError, response }) => {

  if (response && response.errors) {
    for (let error of response.errors) {
      if (error.debugMessage || error.name) {
        store.commit('serverError/setErrorCode', error?.name);
        store.commit('serverError/setErrorMessage', error?.debugMessage);
      } else if (error.message) {
        store.commit('serverError/setErrorCode', error?.name);
        store.commit('serverError/setErrorMessage', error?.message);
      }
      else if (error.extensions.category == "graphql") {
        store.commit('serverError/setErrorCode', "GraphQlError");
        store.commit('serverError/setErrorMessage', error.message);
      }
      break;
    }
  }

  if (networkError && networkError.message.includes("Failed to fetch")) {
    store.commit('serverError/setErrorCode', "ServerUnavailable");
    return null;
  }
  else {
    return null
  }
})

export default errorLink;