import { AUDIO_APP_STORE_LINK, AUDIO_APP_LINK, AUDIO_APP_STORE_LINK_IOS } from "@/utils/Const";
import { isIosOrMac } from "@/helpers/devices";

export function openAudiobookApp() {
  const audioUrl = AUDIO_APP_LINK;
  let fallbackUrl = AUDIO_APP_STORE_LINK;

  if (isIosOrMac()) {
    fallbackUrl = AUDIO_APP_STORE_LINK_IOS;
  }

  const newWindow = window.open(audioUrl);

  setTimeout(() => {
    if (newWindow) {
      newWindow.location.href = fallbackUrl;
    } else {
      window.open(fallbackUrl);
    }
  }, 2000);
}