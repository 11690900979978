<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card
        class="pa-0"
        tile
      >
        <v-card-title>
          <span class="headline">
            {{ $t('transactionReports.updateFulfillmentStatus') }}
          </span>
          <v-spacer />
          <v-btn
            icon
            @click="dialog = false"
          >
            <v-icon color="background">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-3">
          <div v-if="$apollo.queries.orderFulfillment.loading">
            <v-progress-linear
              indeterminate
              color="primary"
            />
            <v-skeleton-loader
              type="image"
              class="mt-2"
            />
            <v-skeleton-loader
              type="list-item@3"
              class="mt-2"
            />
          </div>
          <div v-else-if="orderFulfillment">
            <AddOrderFulfillmentNote
              :id="orderFulfillment?.id"
              :current-notes="orderFulfillment?.notes"
              class="mb-6"
            />
            <update-order-fulfillment-date
              v-if="showUpdateDate"
              :id="orderFulfillment?.id"
              :current-fulfilled-at="orderFulfillment?.fulfilledAt"
            />
            <div v-else>
              <h4>{{ $t('transactionReports.fulfilledOn', { date: formatDate(orderFulfillment?.fulfilledAt) }) }}</h4>
              <h5>{{ $t('transactionReports.fulfilledAtSet') }}</h5>
              <v-btn
                color="secondary"
                tile
                elevation="0"
                @click="showUpdateDate = true"
              >
                {{ $t('transactionReports.changeDate') }}  
              </v-btn>
            </div>
          </div>
          <apollo-error-alert
            v-else-if="error"
            :error="$apollo.queries.orderFulfillment.error"
          />
          <h4 v-else>
            {{ $t('transactionReports.noFulfillmentDetails') }}
          </h4>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            tile
            elevation="0"
            @click="dialog = false"
          >
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import UpdateOrderFulfillmentDate from '@/components/forms/UpdateOrderFulfillmentDate';
import AddOrderFulfillmentNote from '@/components/forms/AddOrderFulfillmentNote';
import ApolloErrorAlert from '@/components/ApolloErrorAlert.vue';
import { formatDate } from '@/helpers/generalFunctions';

export default {
  name: "UpdateOrderFulfillmentModal",
  components: {
    UpdateOrderFulfillmentDate,
    AddOrderFulfillmentNote,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: this.value,
      error: null,
      showUpdateDate: false,
    };
  },
  computed: {
    isFulfilled() {
      return this.orderFulfillment?.fulfilledAt;
    },
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input', val);
    },
    isFulfilled(val) {
      this.showUpdateDate = !val;
    }
  },
  apollo: {
    orderFulfillment: {
      query: gql`
        query getOrderFulfillment($id: ID!) {
          orderFulfillment(id: $id) {
            id
            _id
            fulfilledAt
            notes
          }
        }
      `,
      variables() {
        return {
          id: this.id,
        };
      },
      skip() {
        return !this.id;
      },
      onError(error) {
        this.error = error;
      },
    },
  },
  methods: {
    formatDate,
  },
}
</script>