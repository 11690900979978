<template>
  <ApolloMutation
    ref="updateProfile"
    :mutation="(gql) => updateProfileMutation"
    :variables="{
      input: {
        phoneNumber: formattedPhone,
        alternativePhoneNumber: shippingData.altPhoneNumber,
        givenName: givenName,
        familyName: familyName,
        placeId: shippingData.placeId,
        addressDetails: shippingData.addressDetails,
      },
    }"
    :update="updateCache"
  >
    <template #default="{ mutate, loading }">
      <div v-if="!$apollo.queries.getWebServiceUser.loading">
        <div
          class="d-flex justify-space-between align-center edit-header-container pb-3 mb-6"
        >
          <h1 class="edit-header">
            {{ $t("profile.editUserInfo") }}
          </h1>
          <router-link
            class="back-link"
            :to="{ name: 'userProfile' }"
          >
            {{ $t("common.back") }}
          </router-link>
        </div>
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
        >
          <v-form
            ref="userInfoForm"
            class="default-form-width"
          >
            <ekitabu-text-input
              id="given-name-input"
              v-model="givenName"
              rules="max:50"
              :label="$t('profile.givenName')"
              :placeholder="$t('profile.givenName')"
            />
            <ekitabu-text-input
              id="family-name-input"
              v-model="familyName"
              class="mt-2"
              rules="max:50"
              :label="$t('profile.familyName')"
              :placeholder="$t('profile.familyName')"
            />
            <ekitabu-masked-phone-input
              :id="'phone-input'"
              :value="accountPhone"
              class="mt-2 fill-width"
              :label="$t('checkout.form.phoneNumber')"
              rules="max:9|min:9"
              placeholder="### ### ###"
              type="tel"
              @formattedPhoneChanged="setFormattedPhone"
            />
            <address-fields
              @updateShippingDetails="updateAddressDetails"
              @updateIsChanged="updateIsChanged"
            />
            <v-btn
              class="mt-4"
              tile
              :block="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.xs"
              elevation="0"
              :loading="loading"
              :disabled="invalid || !valuesChanged || loading"
              color="primary"
              @click="mutate"
            >
              {{ $t("profile.save") }}
            </v-btn>
          </v-form>
        </validation-observer>
      </div>
    </template>
  </ApolloMutation>
</template>

<script>
import EkitabuTextInput from '@/components/EkitabuTextInput';
import UserMutations from '@/graphql/UserMutations';
import EkitabuMaskedPhoneInput from '@/components/EkitabuMaskedPhoneInput';
import { max, min } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import i18n from '@/i18n';
import gql from 'graphql-tag';
import AddressFields from '@/views/checkout/print/AddressFields';
import AddressFragments from '@/graphql/AddressFragments';

const ADDRESS_DETAILS_FRAGMENT = AddressFragments.addressDetailsFragment;

const userQuery = gql`
  query getUserInfo {
    getWebServiceUser {
      id
      familyName
      givenName
      phoneNumber
      alternativePhoneNumber
    }
  }
`

setInteractionMode('eager')

extend('max', {
  ...max,
  message: i18n.t('errors.maxLength', {field: '{_field_}', length: '{length}'}),
})

extend('min', {
  ...min,
  message: i18n.t('errors.minLength', {field: '{_field_}', length: '{length}'}),
})

export default {
  name: 'ChangeProfileInfoForm',
  components: {
    EkitabuTextInput,
    ValidationObserver,
    EkitabuMaskedPhoneInput,
    AddressFields
  },
  data() {
    return {
      givenName: "",
      familyName: "",
      accountPhone: "",
      formattedPhone: "",
      shippingData: {
        placeId: null,
        shippingData: null,
        altPhoneNumber: null,
      },
      shippingDataChanged: false,
    };
  },
  apollo: {
    getWebServiceUser: {
      query: userQuery,
      update(data) {
        this.givenName = data.getWebServiceUser.givenName;
        this.familyName = data.getWebServiceUser.familyName;
        this.accountPhone = data.getWebServiceUser.phoneNumber;
        this.formattedPhone = data.getWebServiceUser.phoneNumber;
        this.shippingData.altPhoneNumber = data.getWebServiceUser.alternativePhoneNumber;

        return data.getWebServiceUser;
      },
    }
  },
  computed: {
    updateProfileMutation() {
      return UserMutations.updateProfileInfo;
    },
    valuesChanged() {
      if (this.getWebServiceUser) {
        const profileProperties = [
          'givenName', 'familyName'
        ];

        return profileProperties.some(prop => this[prop] !== this.getWebServiceUser[prop]) ||
          this.formattedPhone !== this.getWebServiceUser.phoneNumber ||
          this.shippingDataChanged;
      }

      return false;
    },
  },
  methods: {
    setFormattedPhone(formattedPhone) {
      this.formattedPhone = formattedPhone;
    },
    setAltFormattedPhone(formattedPhone) {
      this.altFormattedPhone = formattedPhone;
    },
    async updateCache(store, { data: { updateWebServiceUser: { webServiceUser } } }) {
      const getUser = store.readQuery({
        query: userQuery,
      });

      store.modify({
        id: store.identify(getUser.getWebServiceUser),
        fields: {
          givenName() {
            return webServiceUser.givenName;
          },
          familyName() {
            return webServiceUser.familyName;
          },
          phoneNumber() {
            return webServiceUser.phoneNumber;
          },
          alternativePhoneNumber() {
            return webServiceUser.alternativePhoneNumber;
          },
        },
      });

      // As place is created form the placeId we need to manually query the new address as it wont exist in cache
      if (this.shippingDataChanged) {
        await this.$apollo.query({
          query: gql`
            query getDefaultAddress {
              getDefaultAddress {
                ...AddressDetails
              }
            }
            ${ADDRESS_DETAILS_FRAGMENT}
          `,
          fetchPolicy: 'network-only',
        });
      }

      this.$refs.observer.reset();
      this.$router.push({ name: 'userProfile', params: { snackbar: true } });
    },
    updateAddressDetails(newAddressDetails) {
      this.shippingData = newAddressDetails;
    },
    updateIsChanged(isChanged) {
      this.shippingDataChanged = isChanged;
    },
  },
}
</script>