<template>
  <v-container>
    <v-skeleton-loader
      type="card"
      class="mb-4"
    />
    <v-skeleton-loader
      v-for="n in 4"
      :key="n"
      type="paragraph"
      class="mb-4"
    />
  </v-container>
</template>

<script>
export default {
  name: "AuthLoading",
}
</script>