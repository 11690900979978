<template>
  <v-row
    no-gutters
    class="mx-n1 mt-3"
  >
    <v-col
      v-for="tag in tags"
      :key="tag._id"
      class="col-12 col-sm-4 col-md-3 px-1 pb-1"
    >
      <router-link
        class="tag-link"
        :to="{ name: 'filteredBookList', params: { id: tag._id, tagName: tag.name }, }"
      >
        {{ tag.name }}
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TagDisplay",
  props: {
    tags: {
      type: Array,
      required: true
    },
  },
}
</script>

<style scoped>
.tag-link {
  font-size: 1rem;
  color: var(--v-primaryText-base);
  transition: color 0.2s;
}

.tag-link:hover {
  color: var(--v-primary-base);
}
</style>