<template>
  <ApolloQuery
    :query="(gql) => currenciesQuery"
    :update="(data) => data?.currencies?.edges?.map((edge) => edge.node)"
  >
    <template #default="{ result: { error, data }, isLoading }">
      <apollo-error-alert
        :error="error"
      />
      <ekitabu-select
        :id="id"
        v-model="selected"
        :rules="rules"
        :items="data ?? []"
        :label="$t('couponManager.currency')"
        :placeholder="$t('couponManager.selectCurrency')"
        item-value="id"
        item-text="_id"
        :loading="!!isLoading"
        :required="required"
      />
    </template>
  </ApolloQuery>
</template>

<script>
import ApolloErrorAlert from "@/components/ApolloErrorAlert";
import gql from "graphql-tag";
import EkitabuSelect from "@/components/EkitabuSelect";

export default {
  name: "CouponCurrencySelect",
  components: {
    ApolloErrorAlert,
    EkitabuSelect,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    currenciesQuery() {
      return gql `query {
        currencies {
          edges {
            node {
              id
              _id
            }
          }
        }
      }`;
    },
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.selected = val;
    },
  },
};
</script>