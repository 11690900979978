<template>
  <div class="input-container">
    <validation-provider
      v-slot="{ errors }"
      :rules="rules"
      :name="label"
      mode="aggressive"
    >
      <div class="d-flex">
        <label
          v-if="label"
          class="input-label"
          :for="id"
          :style="labelStyle"
        >
          {{ label }}
          <span
            v-if="required"
            class="required"
          >*</span>
        </label>
      </div>
      <span class="my-4" />
      <v-textarea
        :id="id"
        v-bind="$attrs"
        v-model="currentValue"
        hide-details
        class="input-field"
        solo
        :error="errors.length > 0"
        :dense="$vuetify.breakpoint.xs"
      />
      <span
        v-for="(error, index) in errors"
        :key="index"
        class="error-text"
      >
        {{ error }}
      </span>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: "EkitabuTextArea",
  components: {
    ValidationProvider,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    labelStyle: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  watch: {
    currentValue(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.currentValue = val;
    },
  },
};
</script>
