<template>
  <v-text-field
    v-bind="$attrs"
    v-model="currentValue"
    hide-details
    class="tag-search-field"
    solo
    prepend-inner-icon="search"
    block
    small
  />
</template>

<script>
  export default {
    name: "TagSearchBar",
    props: {
      value: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        default: null,
        required: true,
      },
    },
    data() {
      return {
        currentValue: '',
      }
    },
    watch: {
      currentValue(val) {
        this.$emit("input", val);
      },
      value(val) {
        this.currentValue = val;
      },
    },
  };
</script>

<style scoped>
  .tag-search-field {
    border-radius: 0px;
    max-width: 300px;
    border: 1px solid var(--v-lightAccent-base);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  }

  @media screen and (max-width: 899px) {
    .tag-search-field {
      font-size: 0.75rem;
    }
  }

  @media screen and (max-width: 599px) {
    .tag-search-field {
      font-size: 0.75rem;
      max-width: 100%;
    }
  }
</style>