<template>
  <svg
    :width="size"
    :height="size"
    class="custom-stroke-icon"
    viewBox="0 0 95 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.8">
      <path
        d="M3 3H91.7411V91.7411H3V3Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M91.7411 47.3711H3"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50.3286 14.833H65.1188V47.3714H50.3286V14.833Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.1188 23.707H79.909V47.3713H65.1188V23.707Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.7902 59.2031H32.5803V91.7415H17.7902V59.2031Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M32.5804 59.2031H47.3706V91.7415H32.5804V59.2031Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.2875 61.4346L67.0653 56.0565L78.8971 86.3675L65.1193 91.7456L53.2875 61.4346Z"
        :stroke="color"
        stroke-width="5.91607"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BookshelfIcon",
  props: {
    size: {
      type: Number,
      default: 32,
    },
    color: {
      type: String,
      default: "black",
    },
  },
};
</script>
