<template>
  <v-container v-if="$auth.isAuthenticated">
    <profile-user-info />
    <profile-previous-purchases />
    <v-container
      class="mt-6"
    >
      <v-row>
        <v-spacer />
        <v-btn
          tile
          :block="$vuetify.breakpoint.xs"
          :small="$vuetify.breakpoint.xs"
          elevation="0"
          color="primary"
          :loading="$auth.loading"
          :disabled="$auth.loading"
          @click="logout"
        >
          {{ $t("profile.logout") }}
        </v-btn>
      </v-row>
    </v-container>
    <success-snackbar
      v-model="snackbar"
      :message="$t('profile.userInformationUpdated')"
    />
  </v-container>
</template>

<script>
  import ProfileUserInfo from "./ProfileUserInfo";
  import ProfilePreviousPurchases from "./ProfilePreviousPurchases";
  import SuccessSnackbar from "@/components/SuccessSnackbar";

  export default {
    name: "ProfileView",
    components: {
      ProfileUserInfo,
      ProfilePreviousPurchases,
      SuccessSnackbar,
    },
    data() {
      return {
        snackbar: !!this.$route.params.snackbar,
      };
    },
    methods: {
      async logout() {
        await this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    }
  };
</script>
