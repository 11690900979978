<template>
  <div>
    <data-table-header
      :title="$t('admin.transactionReport')"
      :date-label="$t('admin.reportDate')"
      :toggle-label="$t('transactionReports.confirmedOrders')"
      has-toggle
      :toggle-value="confirmed"
      @startDate="$event => startDate = $event"
      @endDate="$event => endDate = $event"
      @toggle="$event => confirmed = $event"
    />
    <transactions-data-table
      :start-date="startDate"
      :end-date="endDate"
      :confirmed="confirmed"
    />
  </div>
</template>

<script>
import DataTableHeader from '@/components/tables/DataTableHeader.vue';
import TransactionsDataTable from '@/components/tables/TransactionsDataTable.vue';

export default {
  name: "TransactionReport",
  components: {
    DataTableHeader,
    TransactionsDataTable,
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      confirmed: true,
    };
  },
}
</script>