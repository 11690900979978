import gql from "graphql-tag";
import store from "@/store";

export default {
  namespaced: false,
  state: () =>  ({
    currentCart: null,
    cartOwner: null,
  }),
  getters: {
    currentCart: (state) => state.CurrentCart,
    cartOwner: (state) => state.cartOwner,
  },
  mutations: {
    setCurrentCart: (state, newCart) => state.currentCart = newCart,
    setCartOwner: (state, newCartOwner) => state.cartOwner = newCartOwner,
  },
  actions: {
    async handleCartOnLogin({dispatch}, apolloClient) {
      if (store.state.cart.currentCart && !store.state.cart.cartOwner) {
        dispatch("claimOrder", apolloClient);
      }
      else if (store.state.auth.getMe && !store.state.cart.cartOwner) {
        dispatch("getOpenCart", apolloClient);
      }
    },
    async claimOrder({ commit }, apolloClient) {
      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation ClaimOrder($orderId: ID!) {
              claimOrder(input: { id: $orderId }) {
                order {
                  id
                  customerId
                  orderLines(first: 1000) {
                    edges {
                      node {
                        id
                      }
                    }
                  }
                }
              }
            }
          `,
          variables: {
            orderId: store.state.cart.currentCart,
          }
        });
    
        if (response.data) {
          commit('setCartOwner', response.data.claimOrder.order.customerId);
        }
      }
      catch (e) {
        console.error('%cError in claiming order (login)', 'color: red;', e.message)
        commit('setCurrentCart', null)
      }
    },
    async getOpenCart({ commit }, apolloClient) {
      try {
        const response = await apolloClient.query({
          query: gql`
            query GetMyOrders {
              getMyOrders(confirmed: false) {
                id
              }
            }
          `,
        });
    
        if (response.data) {
          const firstOrder = response.data.getMyOrders.find((order) => order.id);
          if (firstOrder) {
            commit("setCurrentCart", firstOrder.id);
            commit('setCartOwner', store.state.auth.getMe);
          }
        }
      }
      catch (e) {
        console.error('%cError in getting open order (login)', 'color: red;', e.message)
        commit('setCurrentCart', null)
      }
    }
  },
}