<template>
  <div>
    <DataTableHeaderContainer>
      <h1>
        {{ $t('eventManager.header') }}
      </h1>
    </DataTableHeaderContainer>
    <v-container class="event-manager-container mt-6 pa-3">
      <v-row>
        <v-col>
          <event-list @eventSelected="selectedEventId = $event" />
        </v-col>
        <v-col>
          <manage-event-form :id="selectedEventId" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ManageEventForm from '@/components/forms/ManageEventForm.vue';
import EventList from '@/components/lists/EventList';
import DataTableHeaderContainer from '@/components/tables/DataTableHeaderContainer.vue';

export default {
  name: "EventManager",
  components: {
    ManageEventForm,
    EventList,
    DataTableHeaderContainer,
  },
  data() {
    return {
      selectedEventId: null,
    };
  },
}
</script>

<style scoped>
.event-manager-header {
  margin: 0;
  background-color: var(--v-lightestAccent-base);
}

.event-manager-container {
  background-color: var(--v-lightestAccent-base);
}
</style>