export default {
  namespaced: true,
  state: () =>  ({
    showMobileNav: true,
  }),
  getters: {
    showMobileNav: (state) => state.showMobileNav,
  },
  mutations: {
    setShowMobileNav: (state, newValue) => state.showMobileNav = newValue,
  },
  actions: {
    changeShowMobileNav({ commit }, newValue) {
      commit('setShowMobileNav', newValue);
    }
  },
}