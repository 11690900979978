<template>
  <v-card
    elevation="5"
    class="flex-grow-1 px-2 py-1 rounded-md border border--default"
  >
    <div class="d-flex">
      <order-book-display-item
        class="flex-grow-1"
        :order-line="orderLine"
        :order-currency="orderCurrency"
        :show-quantity-input="showQuantityInput"
      />
      <remove-from-cart-button
        :id="orderLine.id"
        :order-id="orderId"
        @updateLoading="(event) => (removeLoading = event)"
        @onRemoved="(event) => $emit('onRemoved', event)"
      />
    </div>
    <div
      v-if="removeLoading"
      class="d-flex justify-center align-center loading-overlay"
    >
      <v-progress-circular
        color="primary"
        indeterminate
        size="30"
      />
    </div>
    <div
      v-if="orderLine?.format == formats.print"
      class="d-flex align-center justify-space-between mt-1"
    >
      <v-chip
        class="rounded-0 mr-1"
        color="primary"
        label
        small
      >
        <v-icon
          class="mr-lg-1"
          small
          style="margin-bottom: 2px;"
        >
          mdi-book-open-page-variant
        </v-icon>
        <h3 class="d-none d-lg-block">
          {{ $t('formats.'+orderLine.format) }}
        </h3>
      </v-chip>
      <order-line-quantity-input
        v-if="showQuantityInput"
        :order-line-id="orderLine.id"
        :quantity="orderLine.quantity"
        small
      />
    </div>
  </v-card>
</template>

<script>
import RemoveFromCartButton from "./RemoveFromCartButton";
import currencyMixins from "@/mixins/currencyMixins";
import OrderBookDisplayItem from "@/components/OrderBookDisplayItem";
import OrderLineQuantityInput from "@/components/inputs/OrderLineQuantityInput.vue";
import { FORMATS } from "@/utils/Const";

export default {
  name: "CartListItem",
  components: {
    RemoveFromCartButton,
    OrderBookDisplayItem,
    OrderLineQuantityInput,
  },
  mixins: [currencyMixins],
  props: {
    orderLine: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    orderCurrency: {
      type: Object,
      required: true,
    },
    showQuantityInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      removeLoading: false,
    };
  },
  computed: {
    formats() {
      return FORMATS;
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(222, 222, 222, 1);
  opacity: 0.3;
}
</style>
