export default {
  namespaced: false,
  state: () =>  ({
    currencyCode: "KES",
  }),
  getters: {
    currencyCode: (state) => state.currencyCode,
  },
  mutations: {
    setCurrencyCode: (state, newCurrencyCode) => state.currencyCode = newCurrencyCode,
  },
  actions: {
    changeCurrency({ commit }, newCurrency) {
      commit('setCurrencyCode', newCurrency);
    },
  },
}