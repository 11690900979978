<template>
  <v-btn
    tile
    outlined
    elevation="2"
    class="ml-2 ml-sm-3 outline-button"
    :small="$vuetify.breakpoint.xs"
    @click="toggleOrderAscending()"
  >
    {{ label }}
    <v-icon
      class="sort-button ml-3"
      :class="!orderAscending ? 'rotate' : ''"
      small
    >
      mdi-arrow-up
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SortButton",
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    sort: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      orderAscending: this.value,
      label: this.sort ? this.sort : this.$t('aToZ'),
    };
  },
  watch: {
    orderAscending() {
      this.$emit("input", this.orderAscending);
    },
    value() {
      this.orderAscending = this.value;
    },
  },
  methods: {
    toggleOrderAscending() {
      this.orderAscending = !this.orderAscending;
    },
  },
};
</script>

<style>
.sort-button {
  transform: rotate(0deg);
  transition: transform .3s ease-in-out !important;
}

.rotate {
  transform: rotate(180deg);
}
</style>