export function decodeHtml(html){
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function formatDate(date, options) {
  let publicationDate = new Date(date);

  if (!options) {
    options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
  }

  return publicationDate.toLocaleDateString('en-us', options);
}

export function cleanFormatType(type) {
  return type.replace("application/", "")
}