<template>
  <ekitabu-select
    id="event-select"
    v-model="currentValue"
    v-bind="$attrs"
    :loading="$apollo.queries.events.loading"
    :disabled="$apollo.queries.events.loading"
    class="mt-2"
    :label="$t('eventManager.event')"
    :placeholder="$t('eventManager.selectEvent')"
    :items="events ?? []"
    item-text="name"
    item-value="id"
    clearable
  />
</template>

<script>
import gql from "graphql-tag";
import EkitabuSelect from "@/components/EkitabuSelect";

export default {
  name: "EkitabuEventSelect",
  components: {
    EkitabuSelect,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  apollo: {
    events: {
      query: gql`
        query Events {
          events {
            edges {
              node {
                id
                _id
                name
              }
            }
          }
        }
      `,
      update: (result) => {
        return result?.events?.edges.map((edge) => edge.node);
      },
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue(val) {
      this.$emit("input", val);
    },
  },
}
</script>
