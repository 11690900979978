<template>
  <v-col :class="columnClasses">
    <v-card
      :class="'pa-2 fill-height d-flex flex-column border '+cardClass"
      :elevation="elevation"
      rounded="md"
      @click="$router.push({ name: 'book', params: { id: id } })"
    >
      <v-card-text class="d-flex flex-column flex-grow-1 pa-0">
        <div class="cover-image mb-2">
          <v-img
            contain
            height="100%"
            max-height="100%"
            :src="thumbnailUrl"
          >
            <template #placeholder>
              <image-loading />
            </template>
            <v-tooltip
              v-if="isOwned"
              top
            >
              <template #activator="{ on }">
                <v-icon
                  class="owned-icon pa-1 ma-0"
                  color="background"
                  v-on="on"
                >
                  mdi-bookshelf
                </v-icon>
              </template>
              <span>{{ $t("bookshelf.inBookshelf") }}</span>
            </v-tooltip>
          </v-img>
        </div>
        <div class="d-flex flex-column flex-grow-1 justify-space-between">
          <h5 class="text-caption font-weight-bold black--text">
            {{ decodeHtml(title) }}
          </h5>
          <div>
            <PriceCartPrompt
              :id="id"
              :price="price"
              :currency-id="currency?._id"
              :is-owned="isOwned"
              :is-owned-loading="bookshelfLoading"
              :no-iri-id="noIriId"
              :show-cart-button="showCartButton"
              :refetch-bookshelf="refetchBookshelf"
            />
            <add-to-cart-button
              v-if="!isOwned && showBuyNow"
              :id="id"
              button-classes="mt-2"
              :simplified-id="noIriId"
              :query-loading="bookshelfLoading"
              block
              proceed-to-checkout
              :add-to-cart-text="$t('shop.buyNow')"
            />
            <open-book-button
              v-else-if="isOwned && showCartButton"
              :read-url="readUrl"
              :format="format"
              block
              :audio-text="$t('bookInfo.listenShort')"
            />
          </div>
        </div>
      </v-card-text>
      <slot />
    </v-card>
  </v-col>
</template>

<script>
import { decodeHtml } from "@/helpers/generalFunctions";
import ImageLoading from "@/components/ImageLoading";
import AddToCartButton from "@/views/checkout/AddToCartButton.vue";
import { removeIriId } from "@/iris";
import OpenBookButton from "@/components/OpenBookButton";
import PriceCartPrompt from "@/components/PriceCartPrompt.vue";
import BookshelfMixin from "@/mixins/BookshelfMixin";

var images = require.context("@/assets/img/", false, /\.jpeg$/);

export default {
  components: {
    ImageLoading,
    AddToCartButton,
    OpenBookButton,
    PriceCartPrompt,
  },
  mixins: [BookshelfMixin],
  props: {
    price: {
      type: [String, Number],
      required: false,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    currency: {
      type: Object,
      required: false,
      default: null,
      validator: (value) => {
        return value && value._id;
      },
    },
    columnClasses: {
      type: String,
      default: "col-6 col-sm-3 col-lg-2 pa-1 pa-md-2",
    },
    showBuyNow: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCartButton: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      required: false,
      default: null,
    },
    elevation: {
      type: String,
      default: "16",
    },
    cardClass: {
      type: String,
      default: "border--default",
    },
  },
  computed: {
    noIriId() {
      return removeIriId(this.id);
    },
    ownedBook() {
      return this.checkInBookshelf(this.id);
    },
    isOwned() {
      return !!this.ownedBook;
    },
    readUrl() {
      return this.ownedBook?.readUrl ?? null;
    },
  },
  methods: {
    imgUrl: function (path) {
      return images("./" + path);
    },
    decodeHtml(text) {
      return decodeHtml(text);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";

.card-container {
  position: relative;
}

.book-card {
  max-width: 250px;
  margin: 0 auto;
}

.cover-image {
  width: 100%;
  aspect-ratio: 0.625;
  background-color: var(--v-lightestAccent-base);
}

.book-title {
  font-size: 0.875rem;
  font-weight: 500;
}

.book-price {
  font-size: 0.75rem;
}

.owned-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: var(--v-secondary-base);
}

.actions-container {
  height: 88px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .actions-container {
    height: 72px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .book-title {
    font-size: 0.75rem;
  }

  .book-price {
    font-size: 0.625rem;
  }

  .actions-container {
    height: 56px;
  }
}
</style>
