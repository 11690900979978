<template>
  <v-container class="previous-orders-container mt-3 mt-sm-5 mt-md-6">
    <div class="order-header-container d-flex justify-space-between align-center pb-3 mb-6">
      <h1 class="previous-orders-header">
        {{ $t("profile.previousPurchases") }}
      </h1>
    </div>
    <ApolloQuery
      :query="(gql) => gql`
        query {
          getMyOrders(confirmed: true) {
            id
            _id
            updatedAt
            orderFulfillment {
              id
              fulfilledAt
            }
          }
        }
      `"
      :update="(data) => data.getMyOrders"
    >
      <template #default="{ result: { error, data }, isLoading }">
        <div v-if="error">
          <apollo-error-alert :error="error" />
        </div>
        <v-container v-else-if="isLoading">
          <v-skeleton-loader
            v-for="n in loadingCount"
            :key="n"
            class="my-3"
            type="text"
          />
        </v-container>
        <div v-else-if="data && data.length > 0">
          <div
            v-for="order in data"
            
            :key="order.id"
            class="my-2"
          >
            <div class="order-number">
              {{ $t("checkout.orderNumber", { number: order._id }) }}
            </div>
            <div class="d-flex flex-column flex-md-row justify-space-between">
              <div class="order-date">
                {{ formatDate(order.updatedAt) }}
              </div>
              <div class="d-flex flex-column flex-md-row">
                <v-chip
                  v-if="order?.orderFulfillment"
                  class="mr-md-3"
                  label
                  :color="order.orderFulfillment.fulfilledAt ? 'success' : 'secondary'"
                >
                  <div class="status-chip-content">
                    <v-icon
                      v-if="!$vuetify.breakpoint.xs"
                      class="mb-1 mr-3"
                    >
                      mdi-book-open-page-variant
                    </v-icon>
                    {{
                      order.orderFulfillment?.fulfilledAt ?
                        $t("checkout.orderComplete.deliveryComplete", { date: formatDate(order?.orderFulfillment?.fulfilledAt) }) :
                        $t("checkout.orderComplete.deliveryPending")
                    }}
                  </div>
                </v-chip>
                <router-link
                  class="view-order-link my-2 mx-auto"
                  :to="{ name: 'confirmed', params: {id: order.id} }"
                >
                  {{ $t('common.view') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <h2 v-else>
          {{ $t('profile.noOrders') }}
        </h2>
      </template>
    </ApolloQuery>
  </v-container>
</template>

<script>
import ApolloErrorAlert from "@/components/ApolloErrorAlert.vue";
import { RouterLink } from "vue-router";

export default {
  name: 'ProfilePreviousPurchases',
  components: {
    ApolloErrorAlert,
    RouterLink,
},
  data() {
    return {
      loadingCount: 5,
    }
  },
  methods: {
    formatDate(date) {
      let publicationDate = new Date(date);
      var options = {year: 'numeric', month: 'long', day: 'numeric'};
      return publicationDate.toLocaleDateString('en-us', options);
    }
  },
}
</script>

<style scoped>
  .previous-orders-container {
    background-color: var(--v-lightestAccent-base);
  }

  .order-header-container {
    border-bottom: 1px solid var(--v-lightAccent-base);
  }

  .previous-orders-header {
    font-size: 1.25rem;
  }

  .order-number {
    font-size: 0.875rem;
    color: var(--v-secondaryText-base);
  }

  .order-date {
    font-size: 1rem;
    color: var(--v-primaryText-base);
    font-weight: normal;
  }

  .status-chip-content {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 599px) {
    .previous-orders-header {
      font-size: 1rem;
    }

    .view-order-link {
      font-size: 0.688rem;
    }

    .order-number {
      font-size: 0.688rem;
    }

    .order-date {
      font-size: 0.75rem;
    }
  }
</style>