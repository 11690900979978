<template>
  <v-app-bar
    app
    color="background"
    :extension-height="40"
    clipped-right
    elevation="0"
    class="pa-0"
    height="58"
  >
    <div
      class="main-nav-bar px-3 d-flex flex-grow-1 align-center justify-space-between elevation-6"
    >
      <router-link
        class="d-none d-md-block mr-2"
        :to="{ name: 'home' }"
      >
        <v-img
          class="logo"
          :contain="true"
          :src="logo"
        >
          <template #placeholder>
            <image-loading />
          </template>
        </v-img>
      </router-link>
      <router-link
        style="height: 34px; width: fit-content"
        class="d-block d-md-none"
        :to="{ name: 'home' }"
      >
        <img
          class="logo"
          :src="icon"
        >
      </router-link>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="underline-link-container"
      >
        <router-link :to="{ name: 'home' }">
          {{ $t("menu.shop") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated && $auth.user.email_verified"
          :to="{ name: 'bookshelf' }"
        >
          {{ $t("menu.bookshelf") }}
        </router-link>
        <router-link :to="{ name: 'schoolBookLists' }">
          {{ $t("menu.schoolBookLists") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated"
          :to="{ name: 'userProfile' }"
        >
          {{ $t("menu.profile") }}
        </router-link>
        <router-link
          v-if="$auth.isAuthenticated && isAdmin"
          :to="{ name: 'adminDashboard' }"
        >
          {{ $t("menu.admin") }}
        </router-link>
      </div>
      <v-spacer />
      <search-field class="search-field" />
      <cart-badge :open-cart="openCart" />
      <div
        v-if="!$auth.loading && !$auth.isAuthenticated"
        class="d-none d-md-flex"
      >
        <login-link
          class="underline-link-container"
          sign-up
        >
          <a class="mr-0">{{ $t("menu.register") }}</a>
        </login-link>
        <login-link class="underline-link-container ml-4">
          <a class="mr-0">{{ $t("menu.login") }}</a>
        </login-link>
      </div>
    </div>
    <template
      v-if="showReturnBar"
      #extension
    >
      <div class="d-flex flex-grow-1 flex-column">
        <ContinueShopping
          class="justify-start px-5 header-border text-left"
          height="40px"
          :handle-click="navigateAway"
          :text="hasHistory ? $t('shop.continueShopping') : $t($route.meta.returnBarTextLoc)"
        />
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import SearchField from "@/components/SearchField";
import CartBadge from "@/components/navigation/CartBadge";
import LoginLink from "@/components/LoginLink.vue";
import ImageLoading from "@/components/ImageLoading.vue";
import AdminCheckMixin from "@/mixins/AdminCheckMixin";
import ContinueShopping from "@/components/ContinueShopping.vue";

const logoPath = require("@/assets/img/ekitabuLogo.png");
const iconPath = require("@/assets/img/eKitabu-bug.png");
const arrowIconPath = require("@/assets/img/left-arrow.svg");

export default {
  name: "MainNav",
  components: {
    SearchField,
    CartBadge,
    LoginLink,
    ImageLoading,
    ContinueShopping,
  },
  mixins: [AdminCheckMixin],
  props: {
    openCart: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      logo: logoPath,
      icon: iconPath,
      arrowIcon: arrowIconPath,
    };
  },
  computed: {
    showReturnBar() {
      return this.$route.meta.showReturnBar;
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
  methods: {
    navigateAway() {
      if (this.hasHistory) {
        this.$router.go(-1);
      } else {
        this.$router.push({ name: this.$route.meta.returnBarToRouteName });
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-toolbar__content {
  padding: 0px;
}

::v-deep(.v-toolbar__extension) {
  margin: 0;
  padding: 0;
}

.main-nav-bar {
  height: 100%;
}

.main-nav-bar .underline-link-container a {
  color: var(--v-primaryText-base);
  text-decoration: none;
  margin: 0px 12px;
  padding: 5px;
  position: relative;
  width: 100%;
}
.main-nav-bar .underline-link-container a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 100%;
  left: 0;
  background: var(--v-primary-base);
  transition: transform 0.2s;
  transform: scaleX(0);
  transform-origin: left;
}
.main-nav-bar .underline-link-container a.router-link-exact-active {
  color: var(--v-primaryText-base);
}

.main-nav-bar .underline-link-container a.router-link-exact-active svg {
  fill: var(--v-primaryText-base);
}

.main-nav-bar .underline-link-container a.router-link-exact-active::after {
  transform: scaleX(1);
}
.main-nav-bar .underline-link-container a:hover::after {
  transform: scaleX(1);
}
.main-nav-bar .logo {
  height: 100%;
  max-width: 178px;
  margin-right: 0px;
}

.back-extension {
  display: flex;
  height: 48px;
  align-items: center;
  background-color: var(--v-primary-base);
  color: var(--v-textOnPrimary-base);
  color: var(--v-textOnPrimary-base);
  text-decoration: none;
  cursor: pointer;
}

@media #{map-get($display-breakpoints, 'md-and-down')} {
  .main-nav-bar .underline-link-container a {
    margin: 0px 2px;
  }

  .search-field {
    max-width: 230px;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')}  {
  .main-nav-bar .logo {
    margin-right: 22px;
  }

  .main-nav-bar .underline-link-container a {
    font-size: 14px;
  }

  .main-nav-bar {
    margin: 0px !important;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')}  {
  .main-nav-bar .logo {
    height: 100%;
    max-width: 100px;
    width: auto;
    margin: 0 6px;
  }
}
</style>
