<template>
  <p class="primaryText--text text-subtitle-2 text-md-subtitle-1 mb-0">
    <span v-if="!readMoreActivated && truncated">
      {{ $t("bookInfo.truncatedText", { text: decodeHtml(truncated) }) }}
    </span>
    <span v-else>
      {{ decodeHtml(text) }}
    </span>
    <span>
      <a
        v-if="!readMoreActivated && truncated"
        class="gradient-text"
        href="#"
        @click="activateReadMore"
      >
        {{ $t("bookInfo.readMore") }}
      </a>
    </span>
  </p>
</template>

<script>
import { truncateText } from "@/utils/GeneralFunctions";
import { decodeHtml } from "@/helpers/generalFunctions";

const READ_MORE_THRESHOLD_MOBILE = 40;
const READ_MORE_THRESHOLD_DESKTOP = 60;

export default {
  name: "ReadMore",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      readMoreActivated: false,
    };
  },
  computed: {
    truncateThreshold() {
      return this.$vuetify.breakpoint.smAndDown
        ? READ_MORE_THRESHOLD_MOBILE
        : READ_MORE_THRESHOLD_DESKTOP;
    },
    truncated() {
      return truncateText(this.text, this.truncateThreshold);
    },
  },
  methods: {
    activateReadMore() {
      this.readMoreActivated = true;
    },
    decodeHtml(html) {
      return decodeHtml(html);
    },
  },
};
</script>

<style scoped lang="scss">
.gradient-text {
  background: $warning-gradient;
  border-bottom: 1px solid var(--v-warning-base);
}
</style>
